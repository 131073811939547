$dialog-padding: 20px;

$dialog-width-xs: 30%;
$dialog-width-sm: 50%;
$dialog-width-md: 65%;
$dialog-width-lg: 85%;
$dialog-width-fw: 99%;

$dialog-footer-height: 40px;
$dialog-footer-top-padding: 12px;
$dialog-footer-top-border: 3px;
$dialog-footer-btn-padding: 6px 50px;

$dialog-header-height: 104px;
$dialog-footer-real-height: 70px;

$dialog-close-button-distance-from-edge: 25px;
