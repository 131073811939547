.button-monsido-icon {
    .page-count {
        color: $link-color;
        font-size: 1.3em;
        font-weight: 300;
    }

    .page-text {
        text-transform: uppercase;
        font-size: 0.9em;
    }

    &.text-right {
        text-align: right;
    }

    .page-count,
    .page-text {
        line-height: 16px;
    }
}
