.policy-domain-select {
    .selector-container.multiple {
        .selector-values {
            & > li {
                background-color: rgb(67, 133, 245);
                font-weight: 400;
                color: rgb(255, 255, 255);
                border-radius: 20px;
                padding: 5px 10px;
                box-shadow: none;
                text-shadow: none;
                line-height: 1.2em;
                padding-right: 20px !important;
            }
        }
    }
}
