mon-schedule-report-form {
    .panel-monsido-card  {
        ngb-accordion {
            .accordion-body {
                padding: 0;
            }
        }
    }
}

.label.font-size-1-1 {
    font-size: 13px;
}

mon-schedule-report-admin {
    ngb-accordion {
        .accordion-body {
            padding-top: 0 !important;
        }
    }
}

mon-send-me-report-form {
    ngb-accordion {
        .accordion-body {
            padding: 0 !important;
        }
        .accordion-header {
            padding: 10px 9px;
        }
    }
}

mon-schedule-report-form .accordion-header,
mon-schedule-report-admin .accordion-header {
    font-size: 14px;
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

/* mon-schedule-report-form .accordion-body, */
mon-schedule-report-admin .accordion-body {
    padding: 0 15px 30px 15px;
}