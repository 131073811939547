.product-updates {
  .filters-headline {
    margin-top: 100px;
  }

  .modules-headline,
  .categories-headline {
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .checkbox,
  .radio {
    margin: 10px 0;
  }

  .product-update {
    .card-body {
      .product-update-content {
        max-height: 250px;
        overflow: hidden;
        position: relative;

        .gradient-overlay {
          position: absolute;
          top: 140px;
          left: 0;
          right: 0;
          height: 110px;

          /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#7db9e8+0,ffffff+100&0+60,1+100 */
          /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+42 */
          background: -moz-linear-gradient(
            top,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 42%,
            rgba(255, 255, 255, 1) 100%
          ); /* FF3.6-15 */
          background: -webkit-linear-gradient(
            top,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 42%,
            rgba(255, 255, 255, 1) 100%
          ); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 42%,
            rgba(255, 255, 255, 1) 100%
          ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
        }
      }
    }

    .fa-stack-2x {
      &.green {
        color: #c8de72;
      }
      &.purple {
        color: #eebfeb;
      }
      &.orange {
        color: #f7c07f;
      }
      &.yellow {
        color: #ffe89c;
      }
      &.blue {
        color: #b7e1f1;
      }
      &.pink {
        color: #f844b3;
      }
      &.red {
        color: #ff6767;
      }
      &.grey {
        color: #cdcdc8;
      }
    }

    .fa-stack-1x {
      color: #03333c;
      &.text-white {
        color: #ffffff;
      }
    }

    .module-icon {
      .fa-stack {
        margin-right: 8px;
      }
    }

    .product-update-infobar {
      margin: 10px 0;

      li:first-child {
        padding-left: 0;
      }

      .product-update-category {
        font-size: 1.2em;
      }
    }
  }
}
