p {
    margin-bottom: 8.5px;
}

textarea {
	overflow: auto; // Remove the default vertical scrollbar in IE.
	// Textareas should really only resize vertically so they don't break their (horizontal) containers.
	resize: vertical;
}

fieldset {
	// Browsers set a default `min-width: min-content;` on fieldsets,
	// unlike e.g. `<div>`s, which have `min-width: 0;` by default.
	// So we reset that to ensure fieldsets behave more like a standard block element.
	// See https://github.com/twbs/bootstrap/issues/12359
	// and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
	min-width: 0;
	// Reset the default outline behavior of fieldsets so they don't affect page layout.
	padding: 0;
	margin: 0;
	border: 0;
}

// 1. Correct the text wrapping in Edge and IE.
// 2. Correct the color inheritance from `fieldset` elements in IE.
legend {
	display: block;
    float: none;
	width: 100%;
	max-width: 100%; // 1
	padding: 0;
	margin-bottom: 5px;
	font-size: 15px;
	line-height: inherit;
	color: inherit; // 2
	white-space: normal; // 1
    width: auto;
}