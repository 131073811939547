.table-container-pager {
    min-height: 200px;

    .pager li > a, .pager li > span {
        border-radius: 4px;
    }

    .table-spinner{
        text-align: center;
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .no-result-message-container {
        p {
            font-size: 21px;
        }
    }
}
