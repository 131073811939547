.accessibility-dashboard-doughnut-progressbar {
  padding-left: 20px;
  .progress {
    height: 7px;
    margin-bottom: 7px;
    @include set-border-radius(2px);
  }
  .progress-bar-success {
    background-color: #57b2b7;
  }
}
