.text-turqoise {
    color: #4dc0bd;
}

.text-light-danger {
    color: #f5b0b0;
}

.text-orange {
    color: $btn-orange-color;
}

.text-purple {
    color: #b055de;
}

.text-wcag-a {
    color: $wcag-a;
}

.text-wcag-aa {
    color: $wcag-aa;
}

.text-wcag-aaa {
    color: $wcag-aaa;
}

.text-light-green {
    color: $light-green;
}

.text-light-red {
    color: $light-red;
}

.text-monsido-green {
    color: $monsido-green;
}

.text-green {
    color: $color-green;
}

.text-multiline {
    -ms-word-wrap: break-word;
    word-wrap: break-word;
}

.text-yellow {
    color: #d9c800;
}

.text-purple {
    color: #5a40b4;
}

.text-light-green-blue {
    color: #1ba8ad;
}

.text-link {
    color: $link-color !important;
}
.text-primary {
    color: #337ab7;
}
.text-danger {
    color: #a94442;
}
.text-success {
    color: #3c763d;
}
.text-info {
    color: #31708f;
}
.inline-block {
    display: inline-block;
}