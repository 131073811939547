.accessibility-mode { 
    div.mon-dialog.document-details-app {
        .page-details-section {
            .page-details-sitemenu {
                ul.sidebar-nav {
                    & > li:not(.separator) {
                        & > a:hover,
                        & > a.active,
                        &.open {
                            cursor: pointer;
                            background-color: $accessibility-grey-color;
                            color: #ffffff;
                            .text-danger {
                                color: #ff8787;
                            }
                            .text-confirmed-misspellings-color {
                                color: #ffcc00;
                            }
                            .text-potential-misspelling-color {
                                color: #00ffff;
                            }
                            .text-success {
                                color: $secondary-16;
                            }
                        }
                    }
                }
            }
        }
    }

}
