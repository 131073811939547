.page-fix-form-bold {
    .list-blue-disc {
        list-style: none;
        padding-left: 14px;

        li::before {
            content: "\25CF";
            color: $link-color;
            font-weight: bold;
            display: inline-block;
            width: 14px;
            margin-left: -14px;
            font-size: 20px;
        }
    }
}
