.changes-history-dashboard {
    .statistics-summary {
        margin-top: -15px;
        margin-bottom: 70px;

        div.statistics-card {
            min-height: 105px;
            border-color: $grey-color-3;
            border-style: solid;
            border-width: 1px;
            border-top-color: transparent;
            border-right-color: transparent;

            &:first-child {
                border-left-color: transparent;
                border-left-style: none;
                border-left-width: 0;
            }
        }

        @media print {
            margin-bottom: 0;
        }
    }

    .comparisonToAverage {
        .positive {
            color: $secondary-16;
        }
        .negative {
            color: $secondary-9;
        }
    }
    chart-line .row {
        width: calc(100vw - 75px);
    }

    @media print {
        .row.break-after.outer-t-xlarge {
            margin-top: 0;
        }
    }
}
