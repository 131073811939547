.vertical-ruler {
  position: absolute;
  width: 5px;
  top: 0;
  bottom: 0;
}

.vertical-ruler-right {
  right: 0;
}

.vertical-ruler-left {
  left: 0;
}

.vertical-ruler-1 {
  width: 1px;
}

.vertical-ruler-3 {
  width: 3px;
}

.ruler-primary {
  background-color: #57BCDB;
}

.ruler-success {
  background-color: #57B754;
}

.ruler-warning {
  background-color: #EAA646;
}

.ruler-info {
  background-color: #9500AC;
}

.ruler-danger {
  background-color: #D24747;
}

.ruler-light-grey {
  background-color: $light-grey;
}

.ruler-dark-grey {
  background-color: $dark-grey;
}