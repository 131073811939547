$accessibility-submenu-color: $accessibility-link-color;

.accessibility-mode {
    #submenu.navbar.navbar-inverse {
        color: $accessibility-submenu-color;

        .container-fluid .nav > li a {
            color: $accessibility-submenu-color;

            &.active {
                border-bottom-color: $accessibility-submenu-color;
            }
        }
    }
}