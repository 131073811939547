.traffic-sources {
  height: 500px;
}
.h-100 {
  height: 100%;
}
.header-block {
  font-weight: 300;
  font-size: 19px;
  line-height: 1.1;
}
.summary-distribution .panel {
  height: 100%;
}
.block-referring-information .card-body {
  padding-bottom: 0px !important;
}
