$datepicker-color: #333333;

.uib-datepicker-popup {

  .glyphicon {
    font: normal normal normal 14px/1 FontAwesome;

    &.glyphicon-chevron-left:before {
      content: "\f053";
    }

    &.glyphicon-chevron-right:before {
      content: "\f054";
    }

  }
  .btn.btn-secondary {
    color: $datepicker-color;
  }
}