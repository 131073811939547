.policy-form-rules {
    input[type="radio"]:checked {
        background-color: $main-1;
    }

    .scroll-y {
        overflow-y: auto;
    }

    .min-height {
        height: auto;
        min-height: 100%;
    }

    ul.drop-zone {
        background-color: $grey-bg-color;
        min-height: 30px;

        .dndPlaceholder {
            height: 60px;
            line-height: 60px;
            background-color: $secondary-1;
            color: $main-1;
            border-radius: 4px;
            margin-bottom: 20px;
            margin-top: 20px;
            border: 2px dashed $grey-text;
            transition: all 0.3s ease-out;
        }

        .drop-hover {
            height: 70px;
            line-height: 70px;
            transition: all 0.3s ease-in;
        }

        .rules-fixed-height-container {
            height: 80px;

            .rule-content {
                width: 100%;
                height: 70px;;
                background-color: $color-white;
                color: $main-1;
                border-color: $secondary-1;
                display: flex;
                align-items: center;
                overflow: hidden;

                .icon-container {
                    width: 70px;
                }

                .fa-2x {
                    font-size: 2em;
                }

                .fa {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }

            .rule-delete {
                padding: 20px 0px 20px 10px;
            }
        }
    }
    .policy-rules-menu-anchor {
        position: absolute;
    }

    .dndRuleCandidate {
        border: 2px dashed $grey-text;
        margin-top: 30px;

        .dndInfoText {
            color: $secondary-4;
            font-weight: 300;
        }
    }

    .mon-scrollable-dropdown-menu-wrapper {
        .mon-scrollable-dropdown-menu {
            li {
                line-height: initial;
            }
        }
    }

    .form-check {
        .form-check-label {
            vertical-align: sub;
        }
    }
}
