.color-picker-container {
  .input-group-append {
    .input-group-text {
      &.active {
        background-color: #c5c5c5;
      }
    }

    .color-preview {
      width: 17px;
      height: 17px;

      &:before {
        content: " ";
      }
    }

    .color-preset-container {
      position: fixed;
      z-index: 10003;
      height: 25px;
      width: 282px;
      display: inline-block;
      margin-top: 40px;
      margin-left: -30px;

      .color-preset-column {
        display: inline-block;
        width: calc(100% / 9);
        height: 15px;
        padding-left: 5px;
        padding-right: 5px;

        .color-preset {
          display: inline-block;
          height: 100%;
          width: 100%;
          border-radius: 3px;
        }
      }
    }

    .angular-color-picker {
      position: fixed;
      z-index: 10002;
      padding-top: 50px;
      margin-top: 28px;
      margin-left: -30px;
    }

    .color-picker-overlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10001;
    }
  }
}
