.nav-tabs {
  border-bottom: 0;
  > li.active {
    padding: 10px 14px;
    > a {
      display:inline-block;
      padding: 1px 2px 3px 2px;
      border-width: 0 0 2px 0;
      border-color: #229bf9;
      color: #229bf9;

      &:hover, &:focus {
        color: #229bf9;
        border-width: 0 0 2px 0;
        border-color: #229bf9;
      }
    }
  }
}
