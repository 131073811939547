.icon-button {
    button:disabled {
        cursor: pointer;
    }
    .fa.fa-lg.fa-circle {
        font-size: 1em;
    }

    .inner-r-l-small {
        padding-left: 12px;
        padding-right: 12px;
    }
}
