.opportunities-by-impact-container {
    .opportunities-by-impact-label {
        font-size: 1.3em;
        margin-bottom: 15px;
    }
    .opportunities-by-impact-bar {
        width: 100%;
        .progress {
            background-color: #65cccf;
            box-shadow: none;
            height: 30px;
            margin-bottom: 5px;
            border-radius: 0;
            overflow: visible;
        }
        .progress-bar {
            float: right;
            box-shadow: none;
            transition: transform 0.3s ease-out;
            &.progress-bar-high-impact {
                background-color: $secondary-7;
            }
            &.progress-bar-medium-impact {
                background-color: $secondary-8;
            }
            &.progress-bar-low-impact {
                background-color: $blue-color-1;
            }
            &.progress-bar-passed {
                background-color: $secondary-16;
            }
        }
    }
    .opportunities-by-impact-bar-text {
        width: 100%;
        padding: 5px 0;
    }
    .opportunities-by-impact-bar-legend {
        .impact-pills-container {
            margin-top: 10px;
            .impact-pill {
                border-radius: 5px;
                padding: 6px 16px;
                font-weight: 600;
                font-size: 0.9em;
                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }
    }

    @mixin impact-color($color, $bg){
        color: $color;
        background-color: $bg;
    }
    .high-impact {
        @include impact-color(#a94442, #f2c5c4);
    }
    .medium-impact {
        @include impact-color(#8a6d3b, #faedd4);
    }
    .low-impact {
        @include impact-color(#31708f, #bee1fd);
    }
    .none-impact {
        @include impact-color(#ffffff, #65cccf);
    }
}
