.readability-info__container {
    padding: 15px;

    .readability-score {
        border: 1px solid #ccc;
        padding: 0 3px;
        border-radius: 5px;
        margin-right: 10px;
    }
}
