.table-date {
    text-align: center;
    padding-top: 2px;
    font-size: 11px;
    .month {
        text-transform: uppercase;
        display: block;
        font-size: 1.1em;
    }

    .day {
        font-weight: 300;
        font-size: 1.8em;
        display: block;
        margin-top: -4px;
    }

    .year {
        display: block;
        margin-top: -4px;
    }
}
