@mixin merge-container($name, $size){
  .merge-#{$name} {
    margin: -$size;
  }

  .merge-l-#{$name} {
    margin-left: -$size;
  }

  .merge-r-#{$name} {
    margin-right: -$size;
  }

  .merge-t-#{$name} {
    margin-top: -$size;
  }

  .merge-b-#{$name} {
    margin-bottom: -$size;
  }
}
