.bubble {
  @include set-border-radius(50%);
  background-color: $wcag-a;
  padding: 6px 0;
  display: inline-block;
  color: #fff;
  font-size: 0.8em;
  font-weight: 400;
  width: 26px;
  text-align: center;

  &.wcag-a {
    background-color: $wcag-a;
  }

  &.wcag-aa {
    background-color: $wcag-aa;
  }

  &.wcag-aaa {
    background-color: $wcag-aaa;
  }
}
