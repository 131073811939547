.seo-issues-btn-group {
  width: 100%;
  display: block;
  .btn-group {
    min-width: 50px !important;
    display: flex;

    .dropdown-menu.pull-right {
      margin-right: 40px;
    }

    .btn {
      border-color: transparent;
      flex-grow: 1;
      &:hover,
      &:focus {
        color: #fff;
      }
    }
  }
}
