/* XLarge */
.outer-t-xlarge{
  margin-top: 45px;
}

.outer-b-xlarge{
  margin-bottom: 45px;
}

.outer-r-xlarge{
  margin-right: 45px;
}

/* Large */
.outer-t-large{
  margin-top: 30px;
}

.outer-b-large{
  margin-bottom: 30px;
}

.outer-r-large{
  margin-right: 30px;
}

/* Normal */
.outer-t{
  margin-top: 20px;
}

.outer-all{
  margin: 20px;
}

.outer-b{
  margin-bottom: 20px;
}

.outer-r{
  margin-right: 20px;
}

.outer-l{
  margin-left: 20px;
}

/* Medium */

.outer-t-medium{
  margin-top: 15px;
}

.outer-r-medium{
  margin-right: 15px;
}

.outer-l-medium{
  margin-left: 15px;
}

.outer-b-medium {
    margin-bottom: 15px;
}

/* Small */

.outer-t-small {
    margin-top: 10px;
}

.outer-l-small{
  margin-left: 10px;
}

.outer-b-small{
  margin-bottom: 10px;
}

.outer-r-small{
  margin-right: 10px;
}

/* Mini */

.outer-r-mini{
  margin-right: 5px;
}

.outer-l-mini{
  margin-left: 5px;
}

.outer-t-mini{
  margin-top: 5px;
}

.outer-b-mini{
  margin-bottom: 5px;
}

/* Smurf */

.outer-r-smurf{
  margin-right: 3px;
}

.outer-l-smurf{
  margin-left: 3px;
}

.outer-t-smurf{
  margin-top: 3px;
}

.outer-b-smurf{
  margin-bottom: 3px;
}

/* None */

.outer-none {
  margin: 0;
}

.outer-r-none {
  margin-right: 0;
}

.outer-l-none {
  margin-left: 0;
}

.outer-b-none {
  margin-bottom: 0;
}

.outer-t-none {
  margin-top: 0;
}
