uptime-show {
    .notification:hover {
        background-color: transparent;
    }

    page-header {
        a.btn-marine-blue {
            color: #fff !important;
        }
    }
}