.page-performance-score-indicators {
    padding: 25px 0;

    .score-color-indicator {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
    }
}
