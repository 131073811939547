$accessibility-policy-exchange-center-template-bg: $accessibility-theme-base-color;
$accessibility-policy-exchange-center-template-hover-bg: $accessibility-theme-base-color;
.accessibility-mode { 
    .policy-exchange-center {
        .exchange-center-search {
            .input-group {
                .input-group-prepend {
                    .input-group-text {
                        color: $accessibility-grey-color;
                    }
                }
                input[type="search"].form-control {
                    color: $accessibility-grey-color;
                }
            }
        }
        div {
            mon-dropdown {
                div {
                    div {
                        .mon-dropdown {
                            color: $accessibility-grey-color;
                        }
                    }
                }
            }
        }
    }
    
    .policy-exchange-center-template {
        .bg-monsido-green {
            background: $accessibility-policy-exchange-center-template-bg;
        }
    
        &:hover {
            .bg-monsido-green {
                background: darken($accessibility-policy-exchange-center-template-bg, 5%);
            }
        }
    }

}
