.visible-print-block {
    display: none !important;

    @media print {
      display: block !important;
    }
}

.hidden-print {
    @media print {
        display: none !important;
    }
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}