.accessibility-mode {
    .btn-orange {
        @include button-variant(#fff, $accessibility-btn-orange-color, transparent);
    }

    .btn-marine-blue {
        @include button-variant(#fff, $accessibility-link-color, transparent);
    }

    .btn-grey {
        @include button-variant($accessibility-grey-color, transparent, $accessibility-grey-color);
    }

    .btn-baby-blue {
        @include button-variant(#000, transparent, #d7d7d7);
        &.active {
            //Overwrite the darker color scheme it sets
            background-color: #d5effb;
            &:hover {
                background-color: #d5effb;
            }
        }
    }

    .emphasised-btn {
        @include button-variant(#fff, $accessibility-link-color, transparent);

        &:hover,
        &:focus {
            color: #fff;
        }
    }

    .btn-secondary {
        @include button-variant($accessibility-link-color, #ffffff, $accessibility-btn-secondary-border);

        &:active,
        &:hover,
        &:focus {
            color: $accessibility-link-color;
            background-color: #d4d4d4;
            border-color: #a4a4a4;
        }

    }

    .btn-link {
        @include button-variant($accessibility-link-color, transparent, transparent);

        &:hover,
        &:focus {
            color: $accessibility-link-color;
        }
    }

    .btn-transparent {
        @include button-variant($accessibility-theme-base-color, #ffffff, transparent);
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        &:hover {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }
    }

    .btn-secondary:not(.btn-default) {
        --bs-btn-color: #004ac1;
        --bs-btn-bg: #ffffff;
        --bs-btn-border-color: #e4e4e4;
        --bs-btn-hover-color: #{$main-1};
        --bs-btn-hover-bg: #e6e6e6;
        --bs-btn-hover-border-color: #c5c5c5;

        --bs-btn-active-color: #004ac1;
        --bs-btn-active-bg: #e6e6e6;
        --bs-btn-active-border-color: #c5c5c5;

        --bs-btn-disabled-color: #004ac1;
        --bs-btn-disabled-bg: #ffffff;
        --bs-btn-disabled-border-color: #e4e4e4;
    }

    .btn-link, .btn-link:active {
        color: #004ac1;
        background-color: initial;
        border-color: transparent;
    }

    .btn-marine-blue {
        --bs-btn-color: #ffffff;
        --bs-btn-bg: #004ac1;
        --bs-btn-border-color: transparent;
        --bs-btn-hover-color: #ffffff;
        --bs-btn-hover-bg: #00368e;
        --bs-btn-hover-border-color: transparent;

        --bs-btn-active-color: #ffffff;
        --bs-btn-active-bg: #00368e;
        --bs-btn-active-border-color: transparent;

        --bs-btn-disabled-color: #ffffff;
        --bs-btn-disabled-bg: #00368e;
        --bs-btn-disabled-border-color: transparent;
    }

    a.btn-marine-blue {
        color: #fff !important;
    }

}
