admin-labels {
    .label-text {
        padding: 4px 8px;
        font-size: 1.2em;
    }
}

.label-dialog {
    min-height: 320px;
    top: 50% !important;
    margin-top: -160px;
}