@import '@monsido/colors/dist/colors.scss';

.pull-right {
    float: right !important;
}

.page-header {
    padding-bottom: 7.5px;
    margin: 34px 0 17px;
}

h1,
.h1 {
    font-size: 31px;
}
h2,
.h2 {
    font-size: 25px;
}
h3,
.h3 {
    font-size: 21px;
}
h4,
.h4 {
    font-size: 15px;
}
h5,
.h5 {
    font-size: 12px;
}
h6,
.h6 {
    font-size: 11px;
}

.container-fluid {
    padding-right: 15px;
    padding-left: 15px;

}

.nav.nav-tabs > li.active > a,
.nav.nav-tabs > li.active > a:hover,
.nav.nav-tabs > li.active > a:focus {
  border-width: 0;
  border-bottom-width: 2px;
  border-color: $main-1;
  border-style: solid;
  color: $main-1;
  cursor: pointer;
  background-color: transparent;
}

.nav > li > a {
    position: relative;
    display: block;
    padding: 10px 15px;
  }
  .nav > li > a:hover,
  .nav > li > a:focus {
    text-decoration: none;
    background-color: #eeeeee;
  }

.nav.nav-tabs > li.active {
    padding: 10px 14px 0;
}

.nav.nav-tabs.table-nav-tabs>li>a {
  display: inline-block;
  padding: 1px 2px 3px;
  line-height: 1.2em;
  border-width: 0 0 2px;
  border-radius: 0;
  margin: 10px 14px;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

[uib-tooltip-popup], [uib-tooltip-html-popup] {
    position: absolute;
    .fade:not(.show) {
        opacity: 1;
    }
}
