mon-spinner-snake {
    min-width: 30px;
    min-height: 30px;

    width: 100%;
    height: 100%;
    display: inline-block;

    .mon-spinner-snake-svg {
        width: 100%;
        height: 100%;
    }
}