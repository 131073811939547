.mon-table-cell {
  display: table-cell;
}

.table {
  .vertical-align-middle {
    vertical-align: middle;

    .progress {
        margin-bottom: 0 !important;      
    }
  }

  tbody > tr.selected {
    background-color: #F3F5F7;
  }

  &.remove-first-row-border > tbody {
    tr:first-child td {
      border-top: none;
    }
  }

  tr {
    &.disabled{
      td{
        color: #afafaf;
        *, button-monsido-icon {
          color: #afafaf;
        }
      }
    }
    &.table-row-load {
      background: #ffffee;
      span.spin {
        -webkit-animation-name: spin;
        -webkit-animation-duration: 1800ms;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;

        -moz-animation-name: spin;
        -moz-animation-duration: 1800ms;
        -moz-animation-iteration-count: infinite;
        -moz-animation-timing-function: linear;

        animation-name: spin;
        animation-duration: 1800ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        @-moz-keyframes spin {
          from {
            -moz-transform: rotate(0deg);
          }
          to {
            -moz-transform: rotate(360deg);
          }
        }
        @-webkit-keyframes spin {
          from {
            -webkit-transform: rotate(0deg);
          }
          to {
            -webkit-transform: rotate(360deg);
          }
        }
        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
    }
  }

  &.table-vertial-align {
    td {
      vertical-align: middle;
    }
  }

  &.table-fixed {
    table-layout: fixed;
  }
}
