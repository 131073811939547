$graph-overlay-text-background-color: #e8e8e8;
$graph-overlay-text-color: #000;
$graph-overlay-visibility: 0.8;
$graph-vertical-line-tooltip-background-color: #555557;
$graph-vertical-line-tooltip-color: #ffffff;
.charts-overlay {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;

    .charts-overlay-text {
        @include opacity($graph-overlay-visibility);
        background-color: $graph-overlay-text-background-color;
        padding: 5px;
        border-radius: 5px;
        color: $graph-overlay-text-color;
        text-align: center;
    }
}

.mon-chartjs-vertical-line-tooltip {
  position: fixed;
  color: $graph-vertical-line-tooltip-color;
  background-color: $graph-vertical-line-tooltip-background-color;
  text-align: center;
  padding: 6px 10px;
  border-radius: 3px;
  z-index: 2147483647;
  visibility: hidden;

  &.active {
    visibility: visible;
  }

  &::after {
    position: absolute;
    content: "";
    display: block;
    width: 10px;
    height: 4px;
    z-index: 2147483647;
    clip-path: polygon(0 0, 50% 100%, 100% 0);
    background-color: $graph-vertical-line-tooltip-background-color;
    top: calc(100% - 1px);
    left: calc(50% - 5px);
  }
}