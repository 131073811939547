@mixin table-search-variant($background-color, $border-color, $text-color, $text-placeholder-color){
  .input-group {
    .input-group-prepend .input-group-text {
      background-color: $background-color;
      color: $text-color;
      border-left-color: $border-color;
      border-bottom-color: $border-color;
      border-top-color: $border-color;
    }

    .input-group-append .input-group-text {
      background-color: $background-color;
      color: $text-color;
      border-right-color: $border-color;
      border-bottom-color: $border-color;
      border-top-color: $border-color;
    }

    input.form-control[type="search"] {
      background-color: $background-color;
      color: $text-color;
      border-bottom-color: $border-color;
      border-top-color: $border-color;

      &::-webkit-input-placeholder {
        color: $text-placeholder-color;
      }

      &:-moz-placeholder { /* Firefox 18- */
        color: $text-placeholder-color;
      }

      &::-moz-placeholder { /* Firefox 19+ */
        color: $text-placeholder-color;
      }

      &:-ms-input-placeholder {
        color: $text-placeholder-color;
      }
    }
  }
}
