@import "@monsido/colors/dist/colors";
$color-black-transparency-1: #00000029;
$color-white: #ffffff;


:root {
    --opt-chat-bot-main-text: rgba(24, 33, 89, 1);
    --opt-chat-bot-main-1: rgba(60, 76, 170, 1);
    --opt-chat-bot-main-2: rgba(47, 61, 142, 1);
    --opt-chat-bot-main-3: rgba(43, 54, 59, 1);
    --opt-chat-bot-main-4: rgba(27, 143, 197, 1);
    --opt-chat-bot-main-5: rgba(233, 239, 241, 1);
    --opt-chat-bot-main-6: rgba(189, 206, 214, 1);
    --opt-chat-bot-main-7: rgba(229, 233, 255, 1);

    --opt-navy-100: #E9EFF1;
    --opt-navy-200: #BFC8FD;
    --opt-navy-300: #9EACFA;
    --opt-navy-400: #8394F1;
    --opt-navy-1000: #182159;
    --opt-brand-800: #0B72A2;
}
