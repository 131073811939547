.accessibility-dialogs-help-center {
    .col-48 > {
        .accessibility-suggestion {
            ul.msg li {
                > span {
                    display: inline-block;
                    width: 100%;
                }

                .padding_left {
                    padding-left: 10px;
                }
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                font-weight: bold;
            }

            h2 {
                font-size: 110%;
            }

            h3 {
                font-size: 105%;
            }
        }
    }

    .help-center-header {
        h2 {
            line-height: 1.5;
        }
    }
}
