.fast-track-information {
    .html-snippet {
        @include set-border-radius(5px);
        padding: 10px;
        background-color: #fcf2f2;
        color: #ce2e54;
        border: 1px solid $grey-color-3;
        overflow: hidden;
        word-wrap: break-word;

        &.info {
            background-color: #d9edf7;
            color: #31708f;
        }
    }

    .doughnut-pages {
        width: 40px;
        height: 40px;
        display: inline-block;
        vertical-align: top;

        canvas {
            height: 40px !important;
        }
    }
}
