@import '@monsido/colors/dist/colors.scss';

.btn {
    --bs-btn-color: #{$main-1};
    --bs-border-width: 1px;
}

.btn-primary {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;

    --bs-btn-hover-bg: #286090;

    &:hover {
        background-color: #286090;
        border-color: #204d74;
    }

    &[disabled] {
        --bs-btn-disabled-bg: #337ab7;
    }
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: .65;
    box-shadow: none;
}

.btn-orange {
    @include button-variant(#fff, $btn-orange-color, transparent);
}

.btn-marine-blue {
    @include button-variant(#fff, $btn-secondary-color, transparent);

    --bs-btn-color: #fff;
    --bs-btn-bg: #229BF9;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0682e2;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-focus-shadow-rgb: 34, 155, 249;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0682e2;
    --bs-btn-active-border-color: transparent;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #229bf9;
    --bs-btn-disabled-border-color: transparent;

}

.btn-grey {
    @include button-variant(#898989, transparent, #d7d7d7);

    --bs-btn-color: #898989;
    --bs-btn-bg: initial;
    --bs-btn-border-color: #d7d7d7;
    --bs-btn-hover-color: #898989;
    --bs-btn-hover-bg: initial;
    --bs-btn-hover-border-color: #b8b8b8;

    --bs-btn-active-color: #898989;
    --bs-btn-active-bg: initial;
    --bs-btn-active-border-color: #d7d7d7;

    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #898989;
    --bs-btn-disabled-border-color: transparent;
}

.btn-baby-blue {
    @include button-variant(#000, transparent, #d7d7d7);

    &.active {
        //Overwrite the darker color scheme it sets
        background-color: #d5effb;

        &:hover {
            background-color: #d5effb;
        }
    }
}

.emphasised-btn {
    @include button-variant(#fff, $link-color, transparent);

    &:hover,
    &:focus {
        color: #fff;
    }
}

.btn-no-opacity.btn[disabled] {
    opacity: 1;
}

.btn-blue {
    @include button-variant($link-color, transparent, $link-color);
}

.btn-fuchsia-blue {
    @include button-variant(white, $fuchsia-blue, $fuchsia-blue);
}

.btn-cornflower-blue {
    @include button-variant(white, $cornflower-blue, $cornflower-blue);
}

.btn-orange {
    color: #fff !important;
    background-color: #f49956;
}

.btn-orange.focus,
.btn-orange:focus,
.btn-orange:hover {
    color: #fff;
    background-color: #f17c26;
}

.btn-success {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;

    &:hover {
        background-color: #449d44;
        border-color: #398439;
    }
}

.btn-warning {
    color: #fff;
    background-color: #f0ad4e;
    border-color: #eea236;

    &:hover {
        background-color: #ec971f;
        border-color: #d58512;
    }
}

.btn-danger {
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a;

    &:hover {
        background-color: #c9302c;
        border-color: #ac2925;
    }
}

.btn-info {
    color: #fff;
    background-color: #5bc0de;
    border-color: #46b8da;

    &:hover {
        background-color: #31b0d5;
        border-color: #269abc;
    }
}

.btn-default {
    color: #229bf9;
    background-color: #fff;
    border-color: #e4e4e4;

    &:hover,
    &:active,
    &:focus {
        color: #229bf9;
        background-color: #e6e6e6;
        border-color: #c5c5c5;
    }
}

.btn-secondary:not(.btn-default) {

    --bs-btn-color: #{$main-1};
    --bs-btn-bg: #ffffff;
    --bs-btn-border-color: #{$secondary-6};
    --bs-btn-hover-color: #{$main-1};
    --bs-btn-hover-bg: #e4e4e4;
    --bs-btn-hover-border-color: #{$secondary-6};

    --bs-btn-active-color: #{$main-1};
    --bs-btn-active-bg: #e6e6e6;
    --bs-btn-active-border-color: #c5c5c5;

    --bs-btn-disabled-color: #{$main-1};
    --bs-btn-disabled-bg: #ffffff;
    --bs-btn-disabled-border-color: #{$secondary-6};

    font-size: 12px;
    padding: 6px 12px;
}

.btn-transparent {
    @include button-variant(#7b7b7b, transparent, transparent);
    @include set-border-radius(24px);
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149);

    &:hover,
    &:focus {
        background-color: #f8f9fa;
        box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.302), 0 4px 8px 3px rgba(60, 64, 67, 0.149);
    }
}

.btn-group.open .dropdown-toggle {
    &.btn-transparent {
        box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149);
    }
}

.btn-block {
    display: block;
    width: 100%;
}

.label-table .btn-label-edit:hover {
    color: #647288;
    background-color: #cdcfd9;
    border-color: #c7cad5;
}

.emphasised-btn {
    color: #fff;
    background-color: #229bf9;
    border-color: transparent;
}

.emphasised-btn.focus,
.emphasised-btn:focus,
.emphasised-btn:hover {
    background-color: #0682e2;
    border-color: transparent;
}