.img-thumbnail {
    padding: 4px;
    line-height: 1.428571429;
    background-color: #f3f5f7;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: all .2s ease-in-out;
    display: inline-block;
    max-width: 100%;
    height: auto;
}

hr {
    border: 0;
    border-top: 1px solid #eee;
    opacity: 1;
}
