.mon-intercom {
    display: block;
    clear: both;

    a.intercom-txt {
        width: auto !important;
        height: auto !important;
        background-color: transparent !important;
        margin-right: 10px;

        &:hover {
            background-color: transparent !important;
        }
    }

    .mon-intercom-container {
        position: relative;
        display: block;

        .intercom-counter {
            display: block;
            position: absolute;
            left: 17px;
            top: 18px;
            background-color: #fc1127;
            border-radius: 50%;
            color: white;
            width: 1.8em;
            height: 1.8em;
            border-color: transparent;
            text-align: center;
            padding-top: 0.3em;
            font-size: 8px;
            box-shadow: none;
        }
    }

    .intercom-text {
        padding-top: 16px;
        font-weight: 500;
    }
}
