/**
 * Bootstrap overrides
 */

/**
 * Columns
 */

 $grid-columns: 48;

 /**
  * Typography
  */
 $font-family-sans-serif: 'Roboto','Arial Narrow',sans-serif;
 $font-family-base: $font-family-sans-serif;
 $font-size-base: 12px;


 /**
  * Scaffolding
  */
 $body-bg: #f3f5f7;
 $link-color: #229bf9;
 $text-color: #555557;

 /**
  * Panels
  */
 $panel-default-border: #e6e6e6;

 /**
 * Buttons
 */
 //$btn-secondary-bg: #fff;
 $btn-secondary-color: #229BF9;
 $btn-secondary-border: #e4e4e4;
 $btn-transition: none;


 /**
 * Brands
 */

 //$brand-primary: none;


 /**
 * Dropdown
 */
 $dropdown-link-color: $btn-secondary-color;
 $dropdown-link-hover-color: $btn-secondary-color;
 //$dropdown-link-hover-bg: transparent;

$nav-link-padding-y: 5px;
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;
$screen-sm: $screen-sm-min;
$screen-xs-max: $screen-sm-min - 1;
$screen-sm-max: $screen-md-min - 1;

$gray-lighter: #eee;
$link-hover-color: rgb(5, 116, 201);
$gray-dark: #333;

$card-body-padding: 15px;

$brand-warning: #f0ad4e;
$brand-danger: #d9534f;
$brand-primary: #337ab7;