color-contrast {
    .angular-color-picker {
        border: 0;
        padding: 0;
        display: block;

        ._hues {
            margin-left: 210px;
            ._cursor {
                width: 42px;
            }
        }
    }

    .rgb,
    .hex-box {
        padding: 4px 6px;
    }

    .hex-box {
        width: 6em;
        display: inline-block;
    }

    .rgb {
        width: 53px;
        display: inline-block;
    }

    .example-box {
        display: inline-block;
        border-radius: 2px;
        border-width: 1px;
        border-color: lightgray;
        border-style: solid;
        height: 6em;
        width: 450px;

        p {
            text-align: center;
            font-size: 18px;

            text-overflow: clip;
            overflow: hidden;
            white-space: nowrap;

            input.form-control {
                width: 10em;
                display: inline-block;
                padding-top: 1px;
                padding-bottom: 0;
                transition: none;
            }
        }
    }

    .check-box {
        float: left;
        width: 10em;
    }

    .check-pass,
    .check-fail {
        font-size: 15px;
        border-radius: 4px;
        width: 45px;
        text-align: center;
        float: right;
        margin-top: -3px;
    }

    .check-pass {
        color: #000000;
        background-color: #00f000;
    }

    .check-fail {
        color: #ffffff;
        background-color: #f00000;
    }
}
