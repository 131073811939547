.select-grey {
    .ngx-select__toggle {
        display: block;
        margin: 0;
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        cursor: pointer;
        border: 1px solid $secondary-5;
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 1px 0 hsla(0, 0%, 100%, 0.8);
        box-sizing: border-box;
        border-radius: 3px;
        font-weight: inherit;
        background-color: $secondary-1;
        background-image: linear-gradient(#fafafa, #eee);
    }
}

.form-group.search-input {
    margin-left: 0;
    margin-right: 0;
}

label {
    font-weight: 400;
}