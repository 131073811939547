.profile-selector-container {
    &.profile-item {
        padding: 10px 8.5px;
    }
    &.active-profile-item {
        background-color: #9ac6df;
    }

    & > .profile-measure-from-to-the-right {
        padding-left: 17px;
    }

    & > .profile-item-to-the-right {
        padding-left: 18px;
    }

    .hidden-it-on-smaller-screen {
        @media screen and (max-width: 990px) {
            display: none !important;
        }
    }
    .hidden-it-on-medium-to-smaller-screen {
        @media screen and (max-width: 1215px) {
            display: none !important;
        }
    }

    .hidden-it-on-larger-to-medium-screen {
        @media screen and (max-width: 1500px) {
            display: none !important;
        }
    }
}
