@import "@monsido/colors/dist/colors";

//Top Menu Search START
.navbar-search-form {
    background: $menu-search-background;
    height: $topmenu-navbar-height;
    width: $sidebar-width;
    border-right-color: #dfdfe0;
    border-right-width: 1px;
    border-right-style: solid;
    display: inline-block;

    .input-group-prepend .input-group-text {
        background: transparent;
        border: none;
        border-radius: 0;
        color: $menu-search-text-color;
        padding-left: 12px;
        padding-right: 12px;
    }

    .fav-icon-background {
        background-color: #fff;
        border-radius: 50%;
        padding: 4px;
        line-height: 0;
    }

    .input-group input[type="search"] {
        color: $menu-search-text-color;
        background-color: transparent;
        border: none;
        height: $topmenu-navbar-height;
        box-shadow: none;
        padding: 0 15px 0 0;

        &::-webkit-input-placeholder {
            color: $menu-search-text-color;
        }

        &:-moz-placeholder {
            /* Firefox 18- */
            color: $menu-search-text-color;
        }

        &::-moz-placeholder {
            /* Firefox 19+ */
            color: $menu-search-text-color;
        }

        &:-ms-input-placeholder {
            color: $menu-search-text-color;
        }
    }

    .accessibility-mode & {
        background: $accessibility-menu-search-form-background;
        .input-group-prepend .input-group-text {
            color: $accessibility-menu-search-form-text-color;
        }

        input[type="search"] {
            color: $accessibility-menu-search-form-text-color;
            &::-webkit-input-placeholder {
                color: $accessibility-menu-search-form-text-color;
            }

            &:-moz-placeholder {
                /* Firefox 18- */
                color: $accessibility-menu-search-form-text-color;
            }

            &::-moz-placeholder {
                /* Firefox 19+ */
                color: $accessibility-menu-search-form-text-color;
            }

            &:-ms-input-placeholder {
                color: $accessibility-menu-search-form-text-color;
            }
        }
    }
}
//Top Menu Search END

//Domain Picker START
#main-project-search {
    &.project-search {
        position: fixed;
        top: $topmenu-navbar-height;
        bottom: 0;
        width: $sidebar-width;
        overflow-y: scroll;
        display: block;
        z-index: 1030;
        -webkit-box-shadow: 3px 8px 13px 0 #afafaf;
        -moz-box-shadow: 3px 8px 13px 0 #afafaf;
        box-shadow: 3px 8px 13px 0 #afafaf;

        .project-search-items {
            width: 100%;

            &:after {
                content: "After";
            }
        }
    }

    &.project-search-left {
        left: 0;
        &.ng-hide {
            left: -$sidebar-width;
        }
    }

    &.project-search-right {
        right: 0;
        &.ng-hide {
            right: -$sidebar-width;
        }
    }

    &.project-search-left,
    &.project-search-right {
        transition: all linear 0.25s;
    }

    &.project-search {
        display: none;
    }

    @media (min-width: $screen-md-min) {
        &.project-search {
            display: block;
        }
    }
}

//Domain Picker END
