@media (min-width: 0) {
    .flex-row {
      flex-direction: row !important;
    }
    .flex-column {
      flex-direction: column !important;
    }
    .flex-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-wrap {
      flex-wrap: wrap !important;
    }
    .flex-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .flex-fill {
      flex: 1 1 auto !important;
    }
    .flex-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-shrink-1 {
      flex-shrink: 1 !important;
    }
    .justify-content-start {
      justify-content: flex-start !important;
    }
    .justify-content-end {
      justify-content: flex-end !important;
    }
    .justify-content-center {
      justify-content: center !important;
    }
    .justify-content-between {
      justify-content: space-between !important;
    }
    .justify-content-around {
      justify-content: space-around !important;
    }
    .align-items-start {
      align-items: flex-start !important;
    }
    .align-items-end {
      align-items: flex-end !important;
    }
    .align-items-center {
      align-items: center !important;
    }
    .align-items-baseline {
      align-items: baseline !important;
    }
    .align-items-stretch {
      align-items: stretch !important;
    }
    .align-content-start {
      align-content: flex-start !important;
    }
    .align-content-end {
      align-content: flex-end !important;
    }
    .align-content-center {
      align-content: center !important;
    }
    .align-content-between {
      align-content: space-between !important;
    }
    .align-content-around {
      align-content: space-around !important;
    }
    .align-content-stretch {
      align-content: stretch !important;
    }
    .align-self-auto {
      align-self: auto !important;
    }
    .align-self-start {
      align-self: flex-start !important;
    }
    .align-self-end {
      align-self: flex-end !important;
    }
    .align-self-center {
      align-self: center !important;
    }
    .align-self-baseline {
      align-self: baseline !important;
    }
    .align-self-stretch {
      align-self: stretch !important;
    }
    .d-none {
      display: none !important;
    }
    .d-inline {
      display: inline !important;
    }
    .d-inline-block {
      display: inline-block !important;
    }
    .d-block {
      display: block !important;
    }
    .d-table {
      display: table !important;
    }
    .d-table-row {
      display: table-row !important;
    }
    .d-table-cell {
      display: table-cell !important;
    }
    .d-flex {
      display: flex !important;
    }
    .d-inline-flex {
      display: inline-flex !important;
    }
    .order-48 {
      order: 48;
    }
    .order-47 {
      order: 47;
    }
    .order-46 {
      order: 46;
    }
    .order-45 {
      order: 45;
    }
    .order-44 {
      order: 44;
    }
    .order-43 {
      order: 43;
    }
    .order-42 {
      order: 42;
    }
    .order-41 {
      order: 41;
    }
    .order-40 {
      order: 40;
    }
    .order-39 {
      order: 39;
    }
    .order-38 {
      order: 38;
    }
    .order-37 {
      order: 37;
    }
    .order-36 {
      order: 36;
    }
    .order-35 {
      order: 35;
    }
    .order-34 {
      order: 34;
    }
    .order-33 {
      order: 33;
    }
    .order-32 {
      order: 32;
    }
    .order-31 {
      order: 31;
    }
    .order-30 {
      order: 30;
    }
    .order-29 {
      order: 29;
    }
    .order-28 {
      order: 28;
    }
    .order-27 {
      order: 27;
    }
    .order-26 {
      order: 26;
    }
    .order-25 {
      order: 25;
    }
    .order-24 {
      order: 24;
    }
    .order-23 {
      order: 23;
    }
    .order-22 {
      order: 22;
    }
    .order-21 {
      order: 21;
    }
    .order-20 {
      order: 20;
    }
    .order-19 {
      order: 19;
    }
    .order-18 {
      order: 18;
    }
    .order-17 {
      order: 17;
    }
    .order-16 {
      order: 16;
    }
    .order-15 {
      order: 15;
    }
    .order-14 {
      order: 14;
    }
    .order-13 {
      order: 13;
    }
    .order-12 {
      order: 12;
    }
    .order-11 {
      order: 11;
    }
    .order-10 {
      order: 10;
    }
    .order-9 {
      order: 9;
    }
    .order-8 {
      order: 8;
    }
    .order-7 {
      order: 7;
    }
    .order-6 {
      order: 6;
    }
    .order-5 {
      order: 5;
    }
    .order-4 {
      order: 4;
    }
    .order-3 {
      order: 3;
    }
    .order-2 {
      order: 2;
    }
    .order-1 {
      order: 1;
    }
    .order-0 {
      order: 0;
    }
  }
  @media (min-width: 720px) {
    .flex-sm-row {
      flex-direction: row !important;
    }
    .flex-sm-column {
      flex-direction: column !important;
    }
    .flex-sm-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-sm-wrap {
      flex-wrap: wrap !important;
    }
    .flex-sm-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .flex-sm-fill {
      flex: 1 1 auto !important;
    }
    .flex-sm-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-sm-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-sm-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-sm-shrink-1 {
      flex-shrink: 1 !important;
    }
    .justify-content-sm-start {
      justify-content: flex-start !important;
    }
    .justify-content-sm-end {
      justify-content: flex-end !important;
    }
    .justify-content-sm-center {
      justify-content: center !important;
    }
    .justify-content-sm-between {
      justify-content: space-between !important;
    }
    .justify-content-sm-around {
      justify-content: space-around !important;
    }
    .align-items-sm-start {
      align-items: flex-start !important;
    }
    .align-items-sm-end {
      align-items: flex-end !important;
    }
    .align-items-sm-center {
      align-items: center !important;
    }
    .align-items-sm-baseline {
      align-items: baseline !important;
    }
    .align-items-sm-stretch {
      align-items: stretch !important;
    }
    .align-content-sm-start {
      align-content: flex-start !important;
    }
    .align-content-sm-end {
      align-content: flex-end !important;
    }
    .align-content-sm-center {
      align-content: center !important;
    }
    .align-content-sm-between {
      align-content: space-between !important;
    }
    .align-content-sm-around {
      align-content: space-around !important;
    }
    .align-content-sm-stretch {
      align-content: stretch !important;
    }
    .align-self-sm-auto {
      align-self: auto !important;
    }
    .align-self-sm-start {
      align-self: flex-start !important;
    }
    .align-self-sm-end {
      align-self: flex-end !important;
    }
    .align-self-sm-center {
      align-self: center !important;
    }
    .align-self-sm-baseline {
      align-self: baseline !important;
    }
    .align-self-sm-stretch {
      align-self: stretch !important;
    }
    .d-sm-none {
      display: none !important;
    }
    .d-sm-inline {
      display: inline !important;
    }
    .d-sm-inline-block {
      display: inline-block !important;
    }
    .d-sm-block {
      display: block !important;
    }
    .d-sm-table {
      display: table !important;
    }
    .d-sm-table-row {
      display: table-row !important;
    }
    .d-sm-table-cell {
      display: table-cell !important;
    }
    .d-sm-flex {
      display: flex !important;
    }
    .d-sm-inline-flex {
      display: inline-flex !important;
    }
    .order-sm-48 {
      order: 48;
    }
    .order-sm-47 {
      order: 47;
    }
    .order-sm-46 {
      order: 46;
    }
    .order-sm-45 {
      order: 45;
    }
    .order-sm-44 {
      order: 44;
    }
    .order-sm-43 {
      order: 43;
    }
    .order-sm-42 {
      order: 42;
    }
    .order-sm-41 {
      order: 41;
    }
    .order-sm-40 {
      order: 40;
    }
    .order-sm-39 {
      order: 39;
    }
    .order-sm-38 {
      order: 38;
    }
    .order-sm-37 {
      order: 37;
    }
    .order-sm-36 {
      order: 36;
    }
    .order-sm-35 {
      order: 35;
    }
    .order-sm-34 {
      order: 34;
    }
    .order-sm-33 {
      order: 33;
    }
    .order-sm-32 {
      order: 32;
    }
    .order-sm-31 {
      order: 31;
    }
    .order-sm-30 {
      order: 30;
    }
    .order-sm-29 {
      order: 29;
    }
    .order-sm-28 {
      order: 28;
    }
    .order-sm-27 {
      order: 27;
    }
    .order-sm-26 {
      order: 26;
    }
    .order-sm-25 {
      order: 25;
    }
    .order-sm-24 {
      order: 24;
    }
    .order-sm-23 {
      order: 23;
    }
    .order-sm-22 {
      order: 22;
    }
    .order-sm-21 {
      order: 21;
    }
    .order-sm-20 {
      order: 20;
    }
    .order-sm-19 {
      order: 19;
    }
    .order-sm-18 {
      order: 18;
    }
    .order-sm-17 {
      order: 17;
    }
    .order-sm-16 {
      order: 16;
    }
    .order-sm-15 {
      order: 15;
    }
    .order-sm-14 {
      order: 14;
    }
    .order-sm-13 {
      order: 13;
    }
    .order-sm-12 {
      order: 12;
    }
    .order-sm-11 {
      order: 11;
    }
    .order-sm-10 {
      order: 10;
    }
    .order-sm-9 {
      order: 9;
    }
    .order-sm-8 {
      order: 8;
    }
    .order-sm-7 {
      order: 7;
    }
    .order-sm-6 {
      order: 6;
    }
    .order-sm-5 {
      order: 5;
    }
    .order-sm-4 {
      order: 4;
    }
    .order-sm-3 {
      order: 3;
    }
    .order-sm-2 {
      order: 2;
    }
    .order-sm-1 {
      order: 1;
    }
    .order-sm-0 {
      order: 0;
    }
  }
  @media (min-width: 940px) {
    .flex-md-row {
      flex-direction: row !important;
    }
    .flex-md-column {
      flex-direction: column !important;
    }
    .flex-md-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-md-wrap {
      flex-wrap: wrap !important;
    }
    .flex-md-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .flex-md-fill {
      flex: 1 1 auto !important;
    }
    .flex-md-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-md-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-md-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-md-shrink-1 {
      flex-shrink: 1 !important;
    }
    .justify-content-md-start {
      justify-content: flex-start !important;
    }
    .justify-content-md-end {
      justify-content: flex-end !important;
    }
    .justify-content-md-center {
      justify-content: center !important;
    }
    .justify-content-md-between {
      justify-content: space-between !important;
    }
    .justify-content-md-around {
      justify-content: space-around !important;
    }
    .align-items-md-start {
      align-items: flex-start !important;
    }
    .align-items-md-end {
      align-items: flex-end !important;
    }
    .align-items-md-center {
      align-items: center !important;
    }
    .align-items-md-baseline {
      align-items: baseline !important;
    }
    .align-items-md-stretch {
      align-items: stretch !important;
    }
    .align-content-md-start {
      align-content: flex-start !important;
    }
    .align-content-md-end {
      align-content: flex-end !important;
    }
    .align-content-md-center {
      align-content: center !important;
    }
    .align-content-md-between {
      align-content: space-between !important;
    }
    .align-content-md-around {
      align-content: space-around !important;
    }
    .align-content-md-stretch {
      align-content: stretch !important;
    }
    .align-self-md-auto {
      align-self: auto !important;
    }
    .align-self-md-start {
      align-self: flex-start !important;
    }
    .align-self-md-end {
      align-self: flex-end !important;
    }
    .align-self-md-center {
      align-self: center !important;
    }
    .align-self-md-baseline {
      align-self: baseline !important;
    }
    .align-self-md-stretch {
      align-self: stretch !important;
    }
    .d-md-none {
      display: none !important;
    }
    .d-md-inline {
      display: inline !important;
    }
    .d-md-inline-block {
      display: inline-block !important;
    }
    .d-md-block {
      display: block !important;
    }
    .d-md-table {
      display: table !important;
    }
    .d-md-table-row {
      display: table-row !important;
    }
    .d-md-table-cell {
      display: table-cell !important;
    }
    .d-md-flex {
      display: flex !important;
    }
    .d-md-inline-flex {
      display: inline-flex !important;
    }
    .order-md-48 {
      order: 48;
    }
    .order-md-47 {
      order: 47;
    }
    .order-md-46 {
      order: 46;
    }
    .order-md-45 {
      order: 45;
    }
    .order-md-44 {
      order: 44;
    }
    .order-md-43 {
      order: 43;
    }
    .order-md-42 {
      order: 42;
    }
    .order-md-41 {
      order: 41;
    }
    .order-md-40 {
      order: 40;
    }
    .order-md-39 {
      order: 39;
    }
    .order-md-38 {
      order: 38;
    }
    .order-md-37 {
      order: 37;
    }
    .order-md-36 {
      order: 36;
    }
    .order-md-35 {
      order: 35;
    }
    .order-md-34 {
      order: 34;
    }
    .order-md-33 {
      order: 33;
    }
    .order-md-32 {
      order: 32;
    }
    .order-md-31 {
      order: 31;
    }
    .order-md-30 {
      order: 30;
    }
    .order-md-29 {
      order: 29;
    }
    .order-md-28 {
      order: 28;
    }
    .order-md-27 {
      order: 27;
    }
    .order-md-26 {
      order: 26;
    }
    .order-md-25 {
      order: 25;
    }
    .order-md-24 {
      order: 24;
    }
    .order-md-23 {
      order: 23;
    }
    .order-md-22 {
      order: 22;
    }
    .order-md-21 {
      order: 21;
    }
    .order-md-20 {
      order: 20;
    }
    .order-md-19 {
      order: 19;
    }
    .order-md-18 {
      order: 18;
    }
    .order-md-17 {
      order: 17;
    }
    .order-md-16 {
      order: 16;
    }
    .order-md-15 {
      order: 15;
    }
    .order-md-14 {
      order: 14;
    }
    .order-md-13 {
      order: 13;
    }
    .order-md-12 {
      order: 12;
    }
    .order-md-11 {
      order: 11;
    }
    .order-md-10 {
      order: 10;
    }
    .order-md-9 {
      order: 9;
    }
    .order-md-8 {
      order: 8;
    }
    .order-md-7 {
      order: 7;
    }
    .order-md-6 {
      order: 6;
    }
    .order-md-5 {
      order: 5;
    }
    .order-md-4 {
      order: 4;
    }
    .order-md-3 {
      order: 3;
    }
    .order-md-2 {
      order: 2;
    }
    .order-md-1 {
      order: 1;
    }
    .order-md-0 {
      order: 0;
    }
  }
  @media (min-width: 1140px) {
    .flex-lg-row {
      flex-direction: row !important;
    }
    .flex-lg-column {
      flex-direction: column !important;
    }
    .flex-lg-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-lg-wrap {
      flex-wrap: wrap !important;
    }
    .flex-lg-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .flex-lg-fill {
      flex: 1 1 auto !important;
    }
    .flex-lg-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-lg-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-lg-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-lg-shrink-1 {
      flex-shrink: 1 !important;
    }
    .justify-content-lg-start {
      justify-content: flex-start !important;
    }
    .justify-content-lg-end {
      justify-content: flex-end !important;
    }
    .justify-content-lg-center {
      justify-content: center !important;
    }
    .justify-content-lg-between {
      justify-content: space-between !important;
    }
    .justify-content-lg-around {
      justify-content: space-around !important;
    }
    .align-items-lg-start {
      align-items: flex-start !important;
    }
    .align-items-lg-end {
      align-items: flex-end !important;
    }
    .align-items-lg-center {
      align-items: center !important;
    }
    .align-items-lg-baseline {
      align-items: baseline !important;
    }
    .align-items-lg-stretch {
      align-items: stretch !important;
    }
    .align-content-lg-start {
      align-content: flex-start !important;
    }
    .align-content-lg-end {
      align-content: flex-end !important;
    }
    .align-content-lg-center {
      align-content: center !important;
    }
    .align-content-lg-between {
      align-content: space-between !important;
    }
    .align-content-lg-around {
      align-content: space-around !important;
    }
    .align-content-lg-stretch {
      align-content: stretch !important;
    }
    .align-self-lg-auto {
      align-self: auto !important;
    }
    .align-self-lg-start {
      align-self: flex-start !important;
    }
    .align-self-lg-end {
      align-self: flex-end !important;
    }
    .align-self-lg-center {
      align-self: center !important;
    }
    .align-self-lg-baseline {
      align-self: baseline !important;
    }
    .align-self-lg-stretch {
      align-self: stretch !important;
    }
    .d-lg-none {
      display: none !important;
    }
    .d-lg-inline {
      display: inline !important;
    }
    .d-lg-inline-block {
      display: inline-block !important;
    }
    .d-lg-block {
      display: block !important;
    }
    .d-lg-table {
      display: table !important;
    }
    .d-lg-table-row {
      display: table-row !important;
    }
    .d-lg-table-cell {
      display: table-cell !important;
    }
    .d-lg-flex {
      display: flex !important;
    }
    .d-lg-inline-flex {
      display: inline-flex !important;
    }
    .order-lg-48 {
      order: 48;
    }
    .order-lg-47 {
      order: 47;
    }
    .order-lg-46 {
      order: 46;
    }
    .order-lg-45 {
      order: 45;
    }
    .order-lg-44 {
      order: 44;
    }
    .order-lg-43 {
      order: 43;
    }
    .order-lg-42 {
      order: 42;
    }
    .order-lg-41 {
      order: 41;
    }
    .order-lg-40 {
      order: 40;
    }
    .order-lg-39 {
      order: 39;
    }
    .order-lg-38 {
      order: 38;
    }
    .order-lg-37 {
      order: 37;
    }
    .order-lg-36 {
      order: 36;
    }
    .order-lg-35 {
      order: 35;
    }
    .order-lg-34 {
      order: 34;
    }
    .order-lg-33 {
      order: 33;
    }
    .order-lg-32 {
      order: 32;
    }
    .order-lg-31 {
      order: 31;
    }
    .order-lg-30 {
      order: 30;
    }
    .order-lg-29 {
      order: 29;
    }
    .order-lg-28 {
      order: 28;
    }
    .order-lg-27 {
      order: 27;
    }
    .order-lg-26 {
      order: 26;
    }
    .order-lg-25 {
      order: 25;
    }
    .order-lg-24 {
      order: 24;
    }
    .order-lg-23 {
      order: 23;
    }
    .order-lg-22 {
      order: 22;
    }
    .order-lg-21 {
      order: 21;
    }
    .order-lg-20 {
      order: 20;
    }
    .order-lg-19 {
      order: 19;
    }
    .order-lg-18 {
      order: 18;
    }
    .order-lg-17 {
      order: 17;
    }
    .order-lg-16 {
      order: 16;
    }
    .order-lg-15 {
      order: 15;
    }
    .order-lg-14 {
      order: 14;
    }
    .order-lg-13 {
      order: 13;
    }
    .order-lg-12 {
      order: 12;
    }
    .order-lg-11 {
      order: 11;
    }
    .order-lg-10 {
      order: 10;
    }
    .order-lg-9 {
      order: 9;
    }
    .order-lg-8 {
      order: 8;
    }
    .order-lg-7 {
      order: 7;
    }
    .order-lg-6 {
      order: 6;
    }
    .order-lg-5 {
      order: 5;
    }
    .order-lg-4 {
      order: 4;
    }
    .order-lg-3 {
      order: 3;
    }
    .order-lg-2 {
      order: 2;
    }
    .order-lg-1 {
      order: 1;
    }
    .order-lg-0 {
      order: 0;
    }
  }
  @media (min-width: 1920px) {
    .flex-xl-row {
      flex-direction: row !important;
    }
    .flex-xl-column {
      flex-direction: column !important;
    }
    .flex-xl-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-xl-wrap {
      flex-wrap: wrap !important;
    }
    .flex-xl-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .flex-xl-fill {
      flex: 1 1 auto !important;
    }
    .flex-xl-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-xl-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-xl-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-xl-shrink-1 {
      flex-shrink: 1 !important;
    }
    .justify-content-xl-start {
      justify-content: flex-start !important;
    }
    .justify-content-xl-end {
      justify-content: flex-end !important;
    }
    .justify-content-xl-center {
      justify-content: center !important;
    }
    .justify-content-xl-between {
      justify-content: space-between !important;
    }
    .justify-content-xl-around {
      justify-content: space-around !important;
    }
    .align-items-xl-start {
      align-items: flex-start !important;
    }
    .align-items-xl-end {
      align-items: flex-end !important;
    }
    .align-items-xl-center {
      align-items: center !important;
    }
    .align-items-xl-baseline {
      align-items: baseline !important;
    }
    .align-items-xl-stretch {
      align-items: stretch !important;
    }
    .align-content-xl-start {
      align-content: flex-start !important;
    }
    .align-content-xl-end {
      align-content: flex-end !important;
    }
    .align-content-xl-center {
      align-content: center !important;
    }
    .align-content-xl-between {
      align-content: space-between !important;
    }
    .align-content-xl-around {
      align-content: space-around !important;
    }
    .align-content-xl-stretch {
      align-content: stretch !important;
    }
    .align-self-xl-auto {
      align-self: auto !important;
    }
    .align-self-xl-start {
      align-self: flex-start !important;
    }
    .align-self-xl-end {
      align-self: flex-end !important;
    }
    .align-self-xl-center {
      align-self: center !important;
    }
    .align-self-xl-baseline {
      align-self: baseline !important;
    }
    .align-self-xl-stretch {
      align-self: stretch !important;
    }
    .d-xl-none {
      display: none !important;
    }
    .d-xl-inline {
      display: inline !important;
    }
    .d-xl-inline-block {
      display: inline-block !important;
    }
    .d-xl-block {
      display: block !important;
    }
    .d-xl-table {
      display: table !important;
    }
    .d-xl-table-row {
      display: table-row !important;
    }
    .d-xl-table-cell {
      display: table-cell !important;
    }
    .d-xl-flex {
      display: flex !important;
    }
    .d-xl-inline-flex {
      display: inline-flex !important;
    }
    .order-xl-48 {
      order: 48;
    }
    .order-xl-47 {
      order: 47;
    }
    .order-xl-46 {
      order: 46;
    }
    .order-xl-45 {
      order: 45;
    }
    .order-xl-44 {
      order: 44;
    }
    .order-xl-43 {
      order: 43;
    }
    .order-xl-42 {
      order: 42;
    }
    .order-xl-41 {
      order: 41;
    }
    .order-xl-40 {
      order: 40;
    }
    .order-xl-39 {
      order: 39;
    }
    .order-xl-38 {
      order: 38;
    }
    .order-xl-37 {
      order: 37;
    }
    .order-xl-36 {
      order: 36;
    }
    .order-xl-35 {
      order: 35;
    }
    .order-xl-34 {
      order: 34;
    }
    .order-xl-33 {
      order: 33;
    }
    .order-xl-32 {
      order: 32;
    }
    .order-xl-31 {
      order: 31;
    }
    .order-xl-30 {
      order: 30;
    }
    .order-xl-29 {
      order: 29;
    }
    .order-xl-28 {
      order: 28;
    }
    .order-xl-27 {
      order: 27;
    }
    .order-xl-26 {
      order: 26;
    }
    .order-xl-25 {
      order: 25;
    }
    .order-xl-24 {
      order: 24;
    }
    .order-xl-23 {
      order: 23;
    }
    .order-xl-22 {
      order: 22;
    }
    .order-xl-21 {
      order: 21;
    }
    .order-xl-20 {
      order: 20;
    }
    .order-xl-19 {
      order: 19;
    }
    .order-xl-18 {
      order: 18;
    }
    .order-xl-17 {
      order: 17;
    }
    .order-xl-16 {
      order: 16;
    }
    .order-xl-15 {
      order: 15;
    }
    .order-xl-14 {
      order: 14;
    }
    .order-xl-13 {
      order: 13;
    }
    .order-xl-12 {
      order: 12;
    }
    .order-xl-11 {
      order: 11;
    }
    .order-xl-10 {
      order: 10;
    }
    .order-xl-9 {
      order: 9;
    }
    .order-xl-8 {
      order: 8;
    }
    .order-xl-7 {
      order: 7;
    }
    .order-xl-6 {
      order: 6;
    }
    .order-xl-5 {
      order: 5;
    }
    .order-xl-4 {
      order: 4;
    }
    .order-xl-3 {
      order: 3;
    }
    .order-xl-2 {
      order: 2;
    }
    .order-xl-1 {
      order: 1;
    }
    .order-xl-0 {
      order: 0;
    }
  }