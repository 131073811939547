.inventory-dashboard {
    inventory-links-count {
        notification {
            display: block;
            padding-left: 20%;

            @media screen and (min-width: 1201px) and (max-width: 1300px) {
                padding-left: 10%;
            }

            @media screen and (min-width: 1000px) and (max-width: 1200px) {
                padding-left: 5%;
            }
        }
    }
}
