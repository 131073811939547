.table-container {
    ul.pagination {
        margin: 0 0 0 20px;
        li {
            a {
                cursor: pointer;

                &:focus-visible {
                    outline: 5px auto $secondary-14;
                    outline-offset: -1px;
                    box-shadow: 0 0 0 1px $secondary-14;
                }
            }
        }
    }

    .pagination-wrapper {
        display: inline-block;
    }

    .pagination-info {
        padding: 8px 0;
    }

    .table-spinner{
        text-align: center;
        padding: 70px 0;
    }

    .no-result-message-container {
        p {
            font-size: 21px;
        }
    }
}
