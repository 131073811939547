.domain-script-guide {
    .card-body {
        padding-left: 20px;
        padding-right: 20px;
    }
}
.mon-script-wrapper {
    position: relative;

    mon-copy {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .can-not-copy {
        color: $grey-color-2;
    }

    .demo-script {
        &.nowrap {
            white-space: nowrap;
        }
        .blurred-line {
            display: inline-block;
            width: 20%;
            height: 11px;
            background-color: $text-grey;
            &.width-one {
                width: 30%;
            }
            &.width-two {
                width: 40%;
            }
            &.indent-one {
                margin-left: 30px;
            }
            &.indent-two {
                margin-left: 60px;
            }
        }
    }
}
