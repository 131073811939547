.admin-auditlog {
    .selector-dropdown {
        .selector-option {
            span {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
            }
        }
    }
    .datepicker-container {
        .disable-datepicker {
            opacity: 0.5;
            position: relative;
            cursor: not-allowed;

            .disable-datepicker-mask {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
            }
        }

        .form-check > .form-check-input {
            margin-top: 2px !important;
        }
    }
}
