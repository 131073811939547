accessibility-pdf-internal-clarity {
    .dropdown-menu {
        width: auto;

        li:hover {
            background-color: #f5f5f5;
        }

        acc-doc-clarity-multiple-remediations-btn {
            font-weight: normal;
            & > a {
                padding: 3px 20px;
            }
        }
    }
}

.accessibility-pdf {
    .table-top-section {
        table-dropdown {
            .btn-group.dropdown {
                vertical-align: top;
                button {
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
            }
        }

        mon-action-menu {
            vertical-align: top;
            --mon-action-menu-vertical-padding: 3.5px;
        }
    }
}
