@import './ng-mon-dialog/styles/dialog.scss';

.mon-dialog {
    &.mon-dialog-size-page-details {
        @include set-dialog-size(90%);
        @include set-dialog-middle(90%);
    }

    &.mon-dialog-label-blocked-by-domain-groups {
        top: 15%;
        bottom: 15%;
        width: 30%;
        right: 35%;
    }
}

// TODO: Remove css classes after we upgraded to Bootstrap 5. https://optimere.atlassian.net/browse/MON-2391
.modal-backdrop.fade {
    opacity: 0.5;
}

.modal-open .modal {
    opacity: 1;
}

.modal-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: hsl(0deg 0% 100%);
    background-clip: padding-box;
    border: 1px solid hsl(0deg 0% 0% / 20%);
    border-radius: 0.3rem;
    outline: 0;

    h4.modal-title {
        font-size: 12px;
    }
}