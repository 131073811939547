@use "sass:math";

@import "../../../../src/styles/variables.scss"; /* local */
@import "dialog-variables.scss";

@-webkit-keyframes sidein {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes sidein {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@-webkit-keyframes sideout {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes sideout {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.ngdialog-overlay {
  background: rgba(0, 0, 0, 0.8) none repeat scroll 0 0;
}

.ngdialog-open {
  height: auto !important;
}

.ngdialog {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  &.ngdialog-theme-plain {
    &.fixed {
      .ngdialog-close {
        position: fixed;
        -webkit-transform: translateZ(0);
        left: calc(35% + #{$dialog-close-button-distance-from-edge});
        top: 32px;
      }
      .dialog-content-header {
        position: fixed;
        -webkit-transform: translateZ(0);
        top: 0;
        left: 35%;
        right: 0;
        height: $dialog-header-height;
        background-color: #fff;
        .page-content-header {
          border-bottom: none !important;
        }

        .page-header {
          padding-top: 20px !important;
        }
      }
      .ngdialog-content {
        $dialog-footer-space: $dialog-footer-real-height - 20px;
        overflow-y: scroll;
        margin-top: $dialog-header-height !important;
        margin-bottom: $dialog-footer-space !important;
        height: calc(100% - (#{$dialog-header-height} + #{$dialog-footer-space})) !important;
        padding-top: 0 !important;

        .dialog-component {
          padding: 15px;
          background-color: #fff;
        }
      }

      .dialog-content-footer {
        padding-top: 23px !important;
        background-color: #fff;
        left: 35% !important;
        height: $dialog-footer-real-height !important;
        position: fixed !important;
        -webkit-transform: translateZ(0);
        right: 0 !important;
        padding-right: 23px !important;
      }
    }

    padding: 0 !important;
    margin: 0;
    text-align: right;

    .ngdialog-content {
      padding: $dialog-padding 0 !important;
      display: inline-block;
      overflow-y: scroll;
      animation-duration: 0.2s;
      //-webkit-animation: sidein ease .5s;
      //animation: sidein ease .5s;

      .dialog-content-content {
        padding: $dialog-padding $dialog-padding ($dialog-footer-height + $dialog-padding);
      }

      .page-header {
        margin: 7px 0 10px 0;
        padding: 0 0 $inner-small 63px;
        border-bottom: 1px solid $panel-default-border !important;
      }

      .dialog-content-header {
        .page-header {
          padding: 0 $dialog-padding 8px 63px;
        }
      }

      .dialog-content-footer {
        padding-top: $dialog-footer-top-padding;
        height: $dialog-footer-height;
        bottom: 0;
        left: 0;
        right: math.div($dialog-padding, 2);
        border-top: $dialog-footer-top-border solid $panel-default-border;
        position: absolute;

        .btn {
          margin-top: -6px;
          padding: $dialog-footer-btn-padding;
        }
      }

      & > div:first-child {
        min-height: 100%;
        overflow: hidden;
        position: relative;
        text-align: left;
      }
    }

    .ngdialog-close {
      right: auto;
      left: 24px;
      top: 32px;
      margin: 0;
      padding: 0;
      width: 26px;
      height: 26px;

      &::before {
        top: 25px !important;
        right: $dialog-padding !important;
        color: #6a6a6a;
      }
    }
  }

  .ngdialog-content {
    width: $dialog-width-md !important;
    height: 100% !important;
  }

  @media (min-width: $screen-sm-min) {
    &.ngdialog-theme-thinner {
      width: 50%;
    }
  }

  /* Large devices (large desktops, 1200px and up) */
  @media (min-width: $screen-lg-min) {
    &.ngdialog-theme-thinner {
      width: 50%;
      margin: auto;
    }
  }

  @media (min-width: $screen-md-min) {
    &.ngdialog-theme-thinner {
      width: 50%;
      margin: auto;
    }
  }

  &.normal {
    padding-bottom: 160px;
    padding-top: 160px;

    .ngdialog-content {
      width: 450px !important;
      height: auto !important;
    }
  }

  &.ngdialog-closing {
    .dialog-content-footer {
      position: fixed !important;
      -webkit-transform: translateZ(0);
      bottom: 0 !important;
    }
    .ngdialog-content {
      //-webkit-animation: sideout ease .5s;
      //animation: sideout ease .5s;
      -webkit-animation: ngdialog-fadeout 0.2s;
      animation: ngdialog-fadeout 0.2s;
    }
  }

  .form-horizontal {
    height: 100%;
  }
}

.ngdialog.ngdialog-xs {
  .ngdialog-content {
    width: $dialog-width-xs !important;
  }
  &.fixed {
    .ngdialog-close {
      left: calc(70% + #{$dialog-close-button-distance-from-edge});
    }
    .dialog-content-header {
      left: 70%;
    }

    .dialog-content-footer {
      left: 70% !important;
    }
  }
}

.ngdialog.ngdialog-sm {
  .ngdialog-content {
    width: $dialog-width-sm !important;
  }

  &.fixed {
    .ngdialog-close {
      left: calc(50% + #{$dialog-close-button-distance-from-edge});
    }
    .dialog-content-header {
      left: 50%;
    }

    .dialog-content-footer {
      left: 50% !important;
    }
  }
}

.ngdialog.ngdialog-lg {
  .ngdialog-content {
    width: $dialog-width-lg !important;
  }

  &.fixed {
    .ngdialog-close {
      left: calc(15% + #{$dialog-close-button-distance-from-edge});
    }
    .dialog-content-header {
      left: 15%;
    }

    .dialog-content-footer {
      left: 15% !important;
    }
  }
}

.ngdialog.ngdialog-fw {
  .ngdialog-content {
    width: $dialog-width-fw !important;
  }

  &.fixed {
    .ngdialog-close {
      left: calc(1% + #{$dialog-close-button-distance-from-edge});
    }
    .dialog-content-header {
      left: 1%;
    }

    .dialog-content-footer {
      left: 1% !important;
    }
  }
}

.ngdialog.ngdialog-theme-plain.ngdialog-sm-center {
  //animation: none;
}

.ngdialog.ngdialog-sm-center .ngdialog-content {
  width: 50% !important;
  height: auto !important;
  top: 25%;
  right: 25%;
  overflow-y: auto;
}

.ngdialog.ngdialog-lg-center .ngdialog-content {
  width: $dialog-width-lg !important;
  height: 90% !important;
  top: 5%;
  right: 7.5%;
  overflow-y: auto;
}

.ngdialog.fade-animation {
  &.ngdialog-closing {
    .ngdialog-content {
      -webkit-animation: fadeout ease .5s !important;
      animation: fadeout ease .5s !important;
      //animation-delay: 2s;
    }
  }
  .ngdialog-content {
    -webkit-animation: fadein ease .5s !important;
    animation: fadein ease .5s !important;
    //animation-delay: 2s;
  }
}

.ngdialog.rounded-border .ngdialog-content {
  border-radius: 5px;
}
