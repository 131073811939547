.page-performance-pages {
    .page-performance-header-label {
        font-size: 12px;
    }

    .powered-by {
        font-weight: 700;
        padding: 3px 8px;
        display: inline;
    }

    .screenshot-box {
        height: 50px;
        width: 80px;
        border-radius: 5px;
    }
    .checkpoint-container {
        min-width: 215px;
        position: relative;
        padding-left: 15px;
        .fmp-checkpoint-bg {
            background-color: #6122ee;
        }
        .fcp-checkpoint-bg {
            background-color: $fcp-color;
        }
        .lcp-checkpoint-bg {
            background-color: $lcp-color;
        }
        .tti-checkpoint-bg {
            background-color: $tti-color;
        }
        .si-checkpoint-bg {
            background-color: $si-color;
        }
        .tbt-checkpoint-bg {
            background-color: $tbt-color;
        }
        .cls-checkpoint-bg {
            background-color: $cls-color;
        }
    }

    .vertical-ruler-performance {
        position: absolute;
        width: 5px;
        top: 15px;
        bottom: 30px;
    }

    .measurement-settings-row {
        width: 100%;
        background-color: $secondary-1;
        .measurement-settings-container {
            .measurement-settings-item {
                margin-right: 50px;
                .measurement-settings-value {
                    display: inline-block;
                    @media (max-width: 1200px) {
                        display: block;
                    }
                }
                @media (max-width: 1300px) {
                    margin-right: 30px;
                }
                @media (max-width: 1200px) {
                    margin-right: 15px;
                }
            }
        }
    }

    .lighthouse-illustration {
        max-width: 470px;
    }

    .line-height-16 {
        line-height: 16px;
    }

    .line-height-32 {
        line-height: 32px;
    }

    .no-pages-wrapper {
        min-height: calc(100vh - 140px);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media screen and (max-width: $screen-sm) {
        .no-pages-wrapper {
            min-height: auto;
            display: block;
        }
    }
}

.spinner-wrapper {
    margin-top: 100px;
}
