ngx-select {
    ul {
        left: 0;
    }

    .ngx-select_multiple {
        display: block;
        cursor: text;
        width: 100%;
        position: relative;
        min-height: unset;

        input {
            min-height: unset;
            display: inline;
            width: unset;
        }

        .ngx-select__selected-plural {
            font-size: unset;
            background-color: $main-1;
            padding: 2px 5px 2px 7px;
            border-color: transparent;
            border-radius: 50px;
            font-weight: 300;
            color: white;

            .ngx-select__clear {
                opacity: 1;
                background-color: white;
                border-radius: 50px;

                i {
                    &:after, &:before {
                        border-top-style: solid;
                        border-color: $main-1;
                        top: 50%;
                    }
                }
            }

            &:focus {
                box-shadow: none;
            }

        }

        .ngx-select__selected {
            float: none;
            display: inline;

            span {
                white-space: nowrap;
            }

            .color-box {
                display: none;
            }
            a.ngx-select__clear.btn.btn-link {
                opacity: 1;
            }
        }
    }

    .ngx-select__selected-plural {
        font-size: 12px;

        .ngx-select__clear {
            height: 13px;
            width: 13px;

            i {
                height: 14px;
                width: 8px;

                &:after, &:before {
                    border-width: 1px;
                }
            }
        }
    }

}