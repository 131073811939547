.text-overflow-container {
  width: 100%;
  display: table;
  table-layout: fixed;

  .text-overflow-item {
    display: table-cell;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    word-wrap: break-word;

    * {
      display: inline;
    }
  }
}
