.border-right {
    border-right: 1px solid $border-grey-color;
}

#page-details-seo,
#page-details-policies,
#page-details-accessibility,
#pages-with-duplicate-title,
#page-details-inventory,
#page-details-page-performance,
#page-details-data-policy {
    height: 100%;

    .page-details-section-header {
        height: $page-details-section-header-height;

        .performance-header .page-header{
            padding-top: 30px;
        }

        page-header .page-header {
            margin-top: 0;
            padding-top: 34px;
            height: $page-details-section-header-height;
        }
    }

    .page-details-section-body {
        height: calc(100% - #{$page-details-section-header-height} - 1px); //Height of container plus border height
        .side-panel-header {
            margin-top: 8.5px;
            margin-bottom: 8.5px;
            font-size: 15px;
            font-weight: 300;
            line-height: 1.1;
        }
    }

    .information-shadow {
        -webkit-box-shadow: inset 5px 5px 5px -5px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: inset 5px 5px 5px -5px rgba(0, 0, 0, 0.3);
        box-shadow: inset 5px 5px 5px -5px rgba(0, 0, 0, 0.3);
    }

    .outer-small-minus {
        margin-top: -10px;
    }

    .page-header,
    .minimize-compliance-txt {
        .button-monsido-icon {
            .page-count {
                font-size: 1.6em;
                line-height: 1.1em;
            }

            .page-text {
                font-size: 0.8em;
                text-transform: none;
                color: $grey-text;
            }
        }
    }

    .table {
        &.seo-issues {
            tr {
                &.selected {
                    border-right: 0;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    box-shadow: none;

                    td {
                        background-color: #f6f9fb !important;
                    }
                }
            }
        }
    }

    .scroll {
        overflow-y: auto;
        overflow-x: hidden;
    }

    .card-header {
        hr {
            margin-left: -15px;
            margin-right: -15px;
        }
    }

    .alert {
        margin-bottom: 0;
        border-radius: 0;
    }
}
