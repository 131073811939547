.form-page-performance-confirm-archive {
    .notice-danger {
        background-color: fade($color-high-red, 10%);
    }

    .archive-profile {
        padding: 10px;
        th,
        td {
            padding: 10px;
        }
    }
}
