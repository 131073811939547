.load-sequence-container {
    padding-bottom: 40px;
    .screenshots-container {
        width: 100%;
        .screenshot {
            width: 9%;
            img {
                border: 1px solid $grey-color-3;
                width: 100%;
                max-width: 155px;
                max-height: 196px;
            }
        }
    }
    .load-sequence-bar-container {
        width: 100%;
        height: 40px;
        padding-top: 25px;
        position: relative;
        .load-sequence-bar {
            height: 100%;
            .load-sequence {
                height: 5px;
            }
        }
        .metric-indicator-container {
            position: absolute;
            top: 25%;

            .metric-indicator {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                color: #fff;
                transition: transform 0.3s;

                &:hover {
                    transform: scale(1.2, 1.2);
                }
            }

            .metric-indicator-text {
                margin-bottom: 40px;
                margin-top: 10px;
                color: $grey-color-1;
            }
        }

        .load-sequence-low {
            background-color: $blue-color-1;
        }
        .load-sequence-medium {
            background-color: $secondary-8;
        }
        .load-sequence-high {
            background-color: $secondary-7;
        }
    }
    .load-sequence-legend-container {
        .load-sequence-legend-item {
            display: inline-block;
            margin-right: 30px;
            .load-sequence-indicator {
                display: inline-block;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                &.load-sequence-indicator-short {
                    background-color: $blue-color-1;
                }
                &.load-sequence-indicator-medium {
                    background-color: $secondary-8;
                }
                &.load-sequence-indicator-long {
                    background-color: $secondary-7;
                }
            }
        }
    }
}
