@-webkit-keyframes selector-rotate {
    0% {
        -webkit-transform: rotateZ(-359deg);
    }

    100% {
        -webkit-transform: rotateZ(0deg);
    }
}

@-moz-keyframes selector-rotate {
    0% {
        -moz-transform: rotateZ(-359deg);
    }

    100% {
        -moz-transform: rotateZ(0deg);
    }
}

@-o-keyframes selector-rotate {
    0% {
        -o-transform: rotateZ(-359deg);
    }

    100% {
        -o-transform: rotateZ(0deg);
    }
}

@keyframes selector-rotate {
    0% {
        transform: rotateZ(-359deg);
    }

    100% {
        transform: rotateZ(0deg);
    }
}

/* Selector */
[selector],
.selector {
    display: none;
}

.selector-container {
    display: block;
    position: relative;
}

.selector-container,
.selector-container input {
    font-size: 1em;
    line-height: normal;
    color: #495c68;
    text-shadow: 0px 1px 0px rgba(255, 255, 255, .5);
}

.selector-input {
    display: block;
    margin: 0;
    position: relative;
    width: 100%;
    padding: .8em 2.6em .7em .7em;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #bbb;
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, .05), inset 0 1px 0 rgba(255, 255, 255, .8);
    box-shadow: 0 1px 0 rgba(0, 0, 0, .05), inset 0 1px 0 rgba(255, 255, 255, .8);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    font-weight: inherit;
    background-color: #f9f9f9;
    background-image: -webkit-linear-gradient(#fafafa, #eee);
    background-image: -moz-linear-gradient(#fafafa, #eee);
    background-image: -ms-linear-gradient(#fafafa, #eee);
    background-image: -o-linear-gradient(#fafafa, #eee);
    background-image: linear-gradient(#fafafa, #eee);
}

.selector-container.rtl .selector-input {
    padding-right: .7em;
    padding-left: 2.6em;
}

.selector-container .selector-values {
    list-style: none;
}

.selector-container input {
    outline: none;
    background: none !important;
    border-color: transparent !important;
    width: 2px;
    display: inline-block;
    cursor: pointer;
}

.selector-container.open input,
.selector-container.has-value input {
    cursor: text;
}

.selector-container.disabled {
    opacity: .6;
}

/* Single */
.selector-container.remove-button.has-value .selector-input {
    padding-right: 3.5em
}

.selector-container.remove-button.has-value.rtl .selector-input {
    padding-right: .7em;
    padding-left: 3.5em
}

.selector-container.open .selector-input {
    background: none;
    border-bottom-color: #f0f0f0;
    padding-right: 2.6em;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
}

.selector-container.open.rtl .selector-input {
    padding-right: .7em;
    padding-left: 2.6em;
}

.selector-container.open.empty .selector-input {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border-bottom-color: #bbb;
}

.selector-container.multiple input,
.selector-container.multiple .selector-values {
    float: left;
}

.selector-container.multiple.rtl input,
.selector-container.multiple.rtl .selector-values {
    float: right;
}

.selector-container input,
.selector-container .selector-values,
.selector-container .selector-values>li,
.selector-container .selector-values>li>div {
    padding: 0;
    margin: 0;
    border: 0;
    display: inline;
}

/* Multiple */
.selector-container.multiple .selector-input {
    padding: .5em .55em .2em .55em !important;
    cursor: text;
    background: #fff;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .1);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .1);
}

.selector-container.multiple.has-value .selector-input {
    padding-left: .35em !important;
    padding-right: .35em !important;
}

.selector-container.multiple .selector-values {
    float: none;
}

.selector-container.multiple input,
.selector-container.multiple .selector-values>li {
    padding: .2em .6em;
    margin: 0 .15em .25em;
    border-width: 1px;
    border-style: solid;
    float: left;
    line-height: normal;
}

.selector-container.multiple .selector-values>li {
    display: inline-block;
    position: relative;
    border-color: #0987d6;
    color: #fff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    font-weight: 300;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    box-shadow: inset 0 1px 1px rgba(255, 255, 255, .5), 0 1px 1px rgba(0, 0, 0, .2);
    background-color: #2dadef;
}

.selector-container.multiple.remove-button .selector-values>li {
    padding-right: 1.9em;
}

.selector-container.multiple.rtl input,
.selector-container.multiple.rtl .selector-values>li {
    float: right;
}

.selector-container.multiple.rtl.remove-button .selector-values>li {
    padding-right: .6em;
    padding-left: 1.9em;
}

/* Helper */
.selector-helper {
    position: absolute;
    display: block;
    width: 2.6em;
    top: 0;
    right: 0;
    bottom: 0;
}

.selector-container.rtl .selector-helper {
    right: inherit;
    left: 0;
}

/* Caret */
.selector-helper .selector-icon {
    display: block;
    position: relative;
    height: 100%;
}

.selector-helper .selector-icon:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -.1em;
    margin-left: -.4em;
    width: 0;
    height: 0;
    border: .4em solid #888;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom: none;
}

/* Unset */
.selector-container.has-value.remove-button .selector-helper {
    border-left: 1px solid #bbb;
}

.selector-container.has-value.remove-button.rtl .selector-helper {
    border-left: none;
    border-right: 1px solid #bbb;
}

.selector-container.has-value.remove-button .selector-icon {
    display: table;
    width: 100%;
}

.selector-container.has-value.remove-button .selector-icon:after {
    content: '\00d7';
    display: table-cell;
    position: relative;
    top: 0;
    left: 0;
    margin: 0;
    border: none;
    height: 100%;
    text-align: center;
    vertical-align: middle;
}

/* Loading */
.selector-container.loading .selector-helper.selector-global-helper {
    width: 1.3em;
    height: 1.3em;
    margin: .65em .7em;
}

.selector-container.loading .selector-global-helper .selector-icon {
    display: table;
    width: 100%;
}

.selector-container.loading .selector-global-helper .selector-icon:after {
    content: '';
    width: 100%;
    height: 100%;
    margin: 0;
    top: 0;
    left: 0;
    opacity: .5;
    border-top: 1px solid #545a6a;
    border-bottom: 1px solid #d4d4db;
    border-left: 1px solid #545a6a;
    border-right: 1px solid #d4d4db;
    -webkit-animation: selector-rotate .5s linear infinite;
    -moz-animation: selector-rotate .5s linear infinite;
    -o-animation: selector-rotate .5s linear infinite;
    animation: selector-rotate .5s linear infinite;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}

.selector-container.open .selector-global-helper,
.selector-container.loading .selector-global-helper {
    border-left: none !important;
    border-right: none !important;
}

.selector-container.multiple .selector-helper {
    display: none;
}

.selector-container.multiple.remove-button .selector-helper {
    display: block;
    width: 1.3em;
    border-left: 1px solid #0987d6;
    cursor: pointer;
}

.selector-container.multiple.remove-button .selector-helper:hover {
    background: rgba(0, 0, 0, .1);
}

.selector-container.multiple.remove-button.rtl .selector-helper {
    border-left: none;
    border-right: 1px solid #0987d6;
}

.selector-container.multiple.loading .selector-input {
    padding-right: 3em !important;
}

.selector-container.multiple.loading .selector-global-helper {
    margin: .75em .8em;
    border-left: none;
    border-right: none;
}

.selector-container.multiple.loading.rtl .selector-input {
    padding-right: .55em !important;
    padding-left: 3em !important;
}

.selector-container.multiple.loading.rtl.has-value .selector-input {
    padding-right: .25em !important;
}

/* Shadow */
.selector-input input,
.selector-shadow {
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    max-width: 100% !important;
}

.selector-shadow {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    white-space: pre;
    margin: 0;
}

/* Dropdown */
.selector-dropdown {
    display: none;
    list-style: none;
    padding: 0 !important;
    margin: 0 !important;
    position: absolute;
    background: #fff;
    border: 1px solid #ccc;
    border-top: 0;
    max-height: 15.5em;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1000;
    -webkit-border-radius: 0 0 3px 3px;
    -moz-border-radius: 0 0 3px 3px;
    border-radius: 0 0 3px 3px;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
    box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.selector-dropdown>li {
    padding: .65em .8em;
    overflow: hidden;
    cursor: pointer;
}

.selector-dropdown>.selector-optgroup {
    background: #fefefe;
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
}

.selector-dropdown>.selector-option.grouped {
    padding-left: 1.6em;
}

.selector-dropdown>.selector-option.active {
    background: #f5fafd;
}

.selector-dropdown>.selector-option.create {
    color: rgba(73, 92, 104, .6);
}

.selector-dropdown>.selector-option.create.active {
    color: #495c68;
}

.selector-container.open .selector-dropdown {
    display: block;
}
