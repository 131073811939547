body.privacy-modal-open {
    .modal {
        background-color: #f5f5f5;
    }

    .terms {
        overflow: auto !important;
        max-height: 350px;
        width: 100%;
    }
}
