.policy-information {
    .policy-title {
        color: $grey-color-1;
    }
    .popover {
        border-radius: 3px;
        max-width: none;

        .popover-content {
            & > .policy-info-custom-popover-template {
                margin: -9px -14px;
                min-width: 200px;
                max-height: 300px;
                overflow-y: auto;
                overflow-x: hidden;
            }
        }
    }
}
