.label-container {
  position: relative;

  .label-list {
    width: calc(100% - 30px);
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
  }

  .label {
    margin-right: 5px;
    margin-top: 3px;
    display: inline-block;

    &:empty {
      display: inline-block;
    }

    .label-text {
      padding: 4px 8px;
      font-size: 1.2em;
    }

    .label-without-text {
      width: 14px;
      height: 10px;
    }
  }

  .label-btn {
    background-color: #e7e8e9;
    color: #a5a5a5;
    border-radius: 3px;
    padding: 3px 6px;
    display: inline-block;
    position: relative;
    margin-top: 3px;
    opacity: 0;
    width: 25px;
    height: 25px;
    vertical-align: middle;

    &.active {
      opacity: 1;
    }

    .label-btn-icon {
      padding-top: 4px;
    }
  }

  &:hover .label-btn {
    opacity: 1;
    cursor: pointer;
    color: #34a7f6;
  }
}
