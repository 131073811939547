@use 'sass:math';
@import '../../../styles/mon-charts/app.scss';

.table-css-compliance {        

    a.btn.button-monsido-icon.nohover:hover {
        cursor: default;
        border-color: transparent;
        .page-text {
            color: #474747;
        }
    }

  a.btn.button-monsido-icon.nohover:hover {
    cursor: default;
    border-color: transparent;
  }
  a.btn.button-monsido-icon.nohover:hover .page-text {
    color: #474747;
  }
}