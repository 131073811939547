a:hover{
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}