@import "@monsido/colors/dist/colors";

mon-form-field-select {
    .ngx-select__item-group {
        > .ngx-select__item.dropdown-item {
            color: #495c68;
            &.ngx-select__item_active {
                background-color: $secondary-3;
            }
        }
    }
}
