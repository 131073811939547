$sidebar-background-color: #555557;
.page-details:focus-visible {
    outline: none;
    box-shadow: 0 0 0 1px $secondary-14;
}
div.mon-dialog.page-details-app {
    .mon-dialog-close {
        top: 28px;
    }

    > div {
        height: 100%;
    }

    .max-height {
        min-height: 100%;
        height: 100%;
    }

    .page-details-view {
        padding-top: 20px;
        height: 100%;
        min-height: 100%;
        overflow-x: hidden;        
    }

    .scroll {
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.page-details-view {
    .page-details-heading {
        .page-details-title {
            width: 100%;
            @media (min-width: 1248px) {
                max-width: 60%;
            }
            .label-container {
                min-width: 70px;
            }
        }
    }

    div.page-details-topmenu {
        border-top: 1px solid $grey-color-3;
        border-bottom: 1px solid $grey-color-3;

        ul.page_details_submenu {
            margin-bottom: 0;
            list-style-type: none;
            padding-left: 40px;

            @mixin submenu-item-padding($left-right-padding: $page-details-topmenu-left-right-padding){
                padding: 20px $left-right-padding
                    calc(#{$page-details-topmenu-padding-bottom} + #{$page-details-topmenu-border-bottom})
                    $left-right-padding;
            }
            li {
                display: inline-block;
                white-space: nowrap;
                color: $color-page-details-link;
                @include submenu-item-padding();

                @media (max-width: 1300px) {
                    @include submenu-item-padding(25px);
                }
                @media (max-width: 1200px) {
                    @include submenu-item-padding(20px);
                }
                @media (max-width: 1100px) {
                    @include submenu-item-padding(16px);
                }

                &.active,
                &:hover {
                    color: $link-color;
                }
            }
        }
    }

    .page-details-section {
        height: 100%;
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        page-header:first-child {
            display: block;
            border-bottom: 1px solid $grey-color-3 !important;

            .page-header {
                padding-left: 15px;
                padding-right: 15px;
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }

        .page-details-sitemenu {
            ul.sidebar-nav {
                padding-left: 0;

                & > li:not(.separator) {
                    & > a:hover,
                    & > button:hover,
                    &.open {
                        cursor: pointer;
                        background-color: $light-grey;
                    }

                    & > a.active,
                    & > button.active{
                        background-color: #d4dce3;
                    }

                    ul.sub {
                        padding: 0;
                        background-color: darken($body-bg, 3);
                        a {
                            padding-left: 30px;
                        }

                        & > li > a:hover,
                        & > li > a.active {
                            cursor: pointer;
                            background-color: darken($body-bg, 8);
                        }
                    }

                    .section-header {
                        font-size: 15px;
                        font-weight: 500;
                        line-height: 1.1;
                        margin-top: 8.5px;
                        margin-bottom: 8.5px;
                    }
                }

                & > li.separator {
                    width: 100%;
                    height: 10px;
                    border-bottom: 1px solid $panel-default-border;
                    margin: 20px 0;

                    h4 {
                        display: inline-block;
                        background-color: $body-bg;
                        padding: 0 10px;
                        margin: 0 12px;
                    }
                }
            }

            li {
                padding: 0;
                list-style: none;
                font-weight: 400;
                font-size: 14px;

                a,
                button {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: $sidebar-background-color;
                    padding: 15px 15px 15px 20px;
                    text-decoration: none;
                    width: 100%;
                    border: none;
                    background-color: white;

                    & > .full-width {
                        text-align: start;
                        flex: 1 0 auto;
                    } 

                    &:focus-visible {
                        outline: solid 2px $secondary-14;
                    }
                }

                i {
                    margin-right: 10px;
                }
            }
        }

        .page-details-information-site {
            min-height: 300px;
            height: 100%;
        }
    }

    .merge-r-l-borders {
        margin-right: -1px;
        margin-left: -1px;
    }

    .merge-t-b-borders {
        margin-top: -1px;
        margin-bottom: -3px;
    }

    .merge-r-border {
        margin-right: -1px;
    }

    .merge-t-border {
        margin-top: -1px;
    }

    .merge-b-border {
        margin-bottom: -3px;
    }

    /* Large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {
        .push-l-none {
            margin-left: 0;
        }
    }
}
