.mon-shortcuts ul li a {
    &:hover,
    &:focus,
    &:focus-within {
        .edit-shortcut {
            display: inline-block;
        }
    }

    .edit-shortcut {
        display: none;
    }

    .external-icon {
        width: 16px;
        height: 16px;
    }
}
