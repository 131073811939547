.popover {
    &.infinite-width {
        max-width: none;
    }
    &.monsido-card {
        @include set-border-radius(2px);
        -webkit-box-shadow: 0 0 5px 2px $light-grey;
        -moz-box-shadow: 0 0 5px 2px $light-grey;
        box-shadow: 0 0 5px 2px $light-grey;

        > .popover-inner > .popover-content {
            padding-left: 0;
            padding-right: 0;
            padding-top: 4px;
            .popover-title {
                padding: 8px 11px 8px 5px;
            }
        }

        .popover-title {
            background: #fff;
            h3 {
                white-space: nowrap;
                font-size: 12px;
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }
    }
}

/**
    Bootstrap 3 Popover styles wrapper class
**/
.popover-bs3 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: none;
    max-width: 276px;
    padding: 1px;
    line-height: 1.428571429;
    line-break: auto;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: normal;
    font-size: 12px;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 6px;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    
    &.top {
        margin-top: -10px;
    }
    
    & > .arrow {
        border-width: 11px;
    }
    
    & > .arrow, & > .arrow:after {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }
    
    & > .arrow:after {
        content: "";
        border-width: 10px;
    }
    
    &.top>.arrow {
        bottom: -11px;
        left: 50%;
        margin-left: -11px;
        border-top-color: #999;
        border-top-color: rgba(0,0,0,.25);
        border-bottom-width: 0
    }

    &.top>.arrow:after {
        bottom: 1px;
        margin-left: -10px;
        content: " ";
        border-top-color: #fff;
        border-bottom-width: 0
    }

    .popover-title {
        padding: 8px 14px;
        margin: 0;
        font-size: 12px;
        background-color: #f7f7f7;
        border-bottom: 1px solid #ebebeb;
        border-radius: 5px 5px 0 0
    }

    .popover-content {
        padding: 9px 14px
    }
}
