@import "../progress.scss";

.progress-bar-monsido-green {
    @include progress-bar-variant($monsido-green);
}

.progress-bar-magenta {
    @include progress-bar-variant($magenta);
}

.progress-bar-fixed {
    @include progress-bar-variant(#57b2b7);
}

.progress-bar-monsido-blue {
    @include progress-bar-variant($blue-color-1);
}

.progress-bar {
    background-color: #337ab7;
}

.progress {
    height: 17px;
    margin-bottom: 17px;
    overflow: hidden;
    background-color: #f5f5f5;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
}