$accessibility-spotlight-input-color: $accessibility-dark-grey-color;

.accessibility-mode { 
  #spotlight.ng-spotlight-overlay {
    a {
      color: $accessibility-link-color;
    }
  
    .ng-spotlight-container {
      .ng-spotlight-main-theme {
        .ng-spotlight-searchbar {
          .input-group:first-child {
            input[type="search"] {
              color: $accessibility-spotlight-input-color;
  
              &::-webkit-input-placeholder {
                color: $accessibility-spotlight-input-color;
              }
  
              &:-moz-placeholder {
                /* Firefox 18- */
                color: $accessibility-spotlight-input-color;
              }
  
              &::-moz-placeholder {
                /* Firefox 19+ */
                color: $accessibility-spotlight-input-color;
              }
  
              &:-ms-input-placeholder {
                color: $accessibility-spotlight-input-color;
              }
            }
          }
        }
      }
    }
  }

}
