.document-details-section-accessibility {
    .not-tagged {
        color: $secondary-9;
        background-color: fade($color-high-red, 10%);

        .triangle-icon {
            color: $secondary-15;
        }
    }

    .not-scanned-icon {
        color: $main-1;
    }

    .not-scanned-text {
        color: $secondary-10;
    }
}
