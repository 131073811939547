/* Extra Large */
.inner-t-extra-large {
    padding-top: $inner-extra-large;
}

.inner-l-extra-large {
  padding-left: $inner-extra-large;
}
.inner-r-extra-large {
  padding-right: $inner-extra-large;
}
/* Large */
.inner-l-large {
  padding-left: $inner-large;
}
.inner-r-large {
  padding-right: $inner-large;
}
.inner-b-large {
  padding-bottom: $inner-large;
}
.inner-t-large {
  padding-top: $inner-large;
}
.inner-all-large {
  padding: $inner-large;
}
/* Normal */
.inner-all {
  padding: $inner;
}
.inner-r {
  padding-right: $inner;
}
.inner-t {
  padding-top: $inner;
}
.inner-l {
  padding-left: $inner;
}
.inner-b {
  padding-bottom: $inner;
}
/* Medium */
.inner-t-medium {
  padding-top: $inner-medium;
}
.inner-l-medium {
  padding-left: $inner-medium;
}
.inner-r-medium {
  padding-right: $inner-medium;
}
.inner-b-medium {
  padding-bottom: $inner-medium;
}
/* Small */
.inner-t-small {
  padding-top: $inner-small;
}
.inner-l-small {
  padding-left: $inner-small;
}
.inner-r-small {
  padding-right: $inner-small;
}
.inner-b-small {
  padding-bottom: $inner-small;
}
.inner-l-between-small-mini {
  padding-left: $inner-between-small-and-mini;
}
.inner-r-between-small-mini {
  padding-right: $inner-between-small-and-mini;
}
/* Mini */
.inner-t-mini {
  padding-top: $inner-mini;
}
.inner-b-mini {
  padding-bottom: $inner-mini;
}
.inner-r-mini {
  padding-right: $inner-mini;
}
.inner-l-mini {
  padding-left: $inner-mini;
}
/* Smurf */
.inner-t-smurf {
  padding-top: $inner-smurf;
}
.inner-b-smurf {
  padding-bottom: $inner-smurf;
}
.inner-r-smurf {
  padding-right: $inner-smurf;
}
.inner-l-smurf {
  padding-left: $inner-smurf;
}
/*None*/
.inner-none {
  padding: $inner-none;
}
.inner-t-none {
  padding-top: $inner-none;
}
.inner-l-none {
  padding-left: $inner-none;
}
.inner-r-none {
  padding-right: $inner-none;
}
.inner-b-none {
  padding-bottom: $inner-none !important;
}