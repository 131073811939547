.progress-xxs .progress, .progress-xxs.progress {
    height: 5px;
}

.progress.progress-square, .progress-square .progress  {
    border-radius: 0;
}

.visits-progress-bar {
    width: 100%;

    .progress {
        margin-bottom: 0;
        margin-top: 5px;
    }
}
