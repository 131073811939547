.mon-velaro {
    display: block;
    clear: both;

    a.mon-velaro-txt {
        width: auto !important;
        height: auto !important;
        background-color: transparent !important;
        margin-right: 5px;

        &:hover {
            background-color: transparent !important;
        }
    }

    .mon-velaro-container {
        position: relative;
        display: block;
    }

    .velaro-text {
        padding-top: 16px;
        font-weight: 500;
    }
}
#velarochat {
    #velarochat-container-dynamic {
        z-index: 9999 !important;
    }
}
