@media screen and (max-width: 1440px) {
    .cookie-overview-panels {
        flex-direction: column-reverse;
        /** TODO: We might re-add it in a later version https://zube.io/monsido/monsido/c/28447
        .auto-cookie-scan-wrapper {
            flex: 100%;
            max-width: 100%;
         } **/
        .cookie-overview-wrapper {
            flex: 100%;
            max-width: 100%;
        }
    }
}
