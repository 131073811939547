.change-history-tables-performance {
    .text-success {
        color: $secondary-16;
    }
    .text-danger {
        color: $secondary-7;
    }

    .score-doughnut-container {
        .doughnut {
            max-width: 60px;
            max-height: 60px;
            margin-left: auto;
        }
    }

    .table.table-hover {
        .performance-update-indicator-row {
            > td {
                padding-bottom: 0;
            }

            &:hover {
                background: $color-white;
            }

            & + tr > td {
                border-top: 0;
            }
        }
    }

    .performance-update-indicator {
        border-bottom: 3px dashed $secondary-15;
        text-align: center;
        padding-top: 2px;
        padding-bottom: 8px;
        margin-bottom: 15px;

        .performance-update-indicator-link {
            color: $secondary-15;
        }
    }
}
