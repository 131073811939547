.statistics-distribution-bar {
    width: 100%;

    .progress {
        margin-bottom: 0;
        margin-top: 5px;
    }

    .progress-xxs .progress,
    .progress-xxs.progress {
        height: 15px;
    }

    .progress.progress-square,
    .progress-square .progress {
        border-radius: 0;
    }

    .progress-bar {
        background-color: #50a6fb;
    }
}
