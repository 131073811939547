@use "sass:math";

$half-grid-gutter-width: math.div($grid-gutter-width, 2);
.seo-dialog-pages-with-duplicate-title {
    .pages-with-duplicate-title-pages {
        .page-header.outer-b-none  {
            margin-bottom: 0 !important;
            padding-bottom: 0 !important;
        }

        .table-container-paginate {
            padding-left: $card-body-padding;
        }
        .pages-with-duplicate-titles-pages-table {
            padding: 0;

            .table-container-paginate {
                padding: $half-grid-gutter-width $grid-gutter-width;
            }
        }
    }

    .pages-with-duplicate-titles-titles-table {
        table.table {
            td,
            th {
                padding: $half-grid-gutter-width;
            }
        }

        .table-container-paginate {
            padding: $half-grid-gutter-width $grid-gutter-width;
        }
    }
    .mon-dialog-body {
        > .max-height {
            height: auto;
            min-height: 100%;
        }
    }
}
