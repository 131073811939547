.form-field-material-switch {
  height: 30px;
  width: 80px;
  padding-top: 5px;
  padding-left: 10px;

  div.material-switch-container {
    padding-top: 11px;
    padding-bottom: 8px;
  }

  .material-switch {
    width: 100%;
    height: 100%;

    > input[type="checkbox"] {
      display: none;

      &:checked + div.handle::before {
        background: inherit;
        opacity: 0.5;
      }

      &:checked + div.handle::after {
        background: inherit;
        left: 20px;
      }
    }

    > div.handle {
      cursor: pointer;
      height: 0;
      position: relative;
      width: 40px;

      &::before {
        background: rgb(0, 0, 0);
        box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
        border-radius: 8px;
        content: '';
        height: 10px;
        margin-top: -7px;
        position: absolute;
        opacity: 0.3;
        transition: all 0.4s ease-in-out;
        width: 40px;
      }

      &::after {
        background: rgb(255, 255, 255);
        border-radius: 16px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        content: '';
        height: 20px;
        left: -4px;
        margin-top: -8px;
        position: absolute;
        top: -4px;
        transition: all 0.3s ease-in-out;
        width: 20px;
      }
    }
  }
}
