.dashboard {
    .domain-overview-table-search {
        height: 40px;
    }
    .shortcut-external-link {
        height: 48px;
    }

    .information-image {
        max-width: 50%;
    }

    .shortcut-link {
        padding: 5px 15px;
        margin: 0px;
        &:hover {
            background-color: $grey-color-4;
        }
    }
}
