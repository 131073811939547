.accessibility-dialogs-new-help-center-decision-tree {
    .answer-list {
        .answer-list-item {
            .answer-list-item-icon {
                margin-top: 3px;
            }

            .answer-list-item-title {
                white-space: normal;
            }

            .answer-list-item-answer {
                background: #f6f9fb;
                color: #5a5a5a;
                padding: 4px 8px;
            }
        }
    }
    .solution {
        h2 {
            font-size: 16px;
        }
    }
}
