.heatmap-dashboard-grid {
    .card-divider {
        width: 40px;
        height: 4px;
        background-color: #229bf9;
        margin: 8px 0;
    }

    .action-btn-group {
        .visual-link-button {
            border: 0;
            border-radius: 0;
            height: 100%;
            padding-left: 20px;
            padding-right: 20px;
        }
        .btn-group-vertical {
            border-width: 0;
            margin: 0;

            button.btn {
                border-radius: 0;
                border-top: 0 transparent;
                border-bottom: 0 transparent;
                margin: 0;
                float: none;
                border-left-width: 2px;

                &:first-child {
                    border-bottom: 1px solid #e4e4e4;
                }

                &:last-child {
                    border-top: 1px solid #e4e4e4;
                }
            }
        }
    }
}
