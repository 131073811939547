#page-content.max-height {
    &.hide-submenu {
        padding-top: 0;
    }

    & > .page-content-scroll-container {
        overflow-y: scroll;
        height: 100%;
        max-height: 100%;

        & > div > .content {
            flex-basis: calc(100% - #{$sidebar-width}); //Width is actually what does the trick, but we set flex-basis in case of browser incompatibility
            width: calc(100% - #{$sidebar-width});
            clear: both;
            min-height: 100%;

            &.hide-sidebar {
                flex-basis: auto;
                width: auto;
            }
        }

        #sidebar ul.sidebar-nav {
            margin-top: 0;
        }

        #sidebar-wrapper {
            background: #f3f5f7;
            flex-basis: $sidebar-width;
            position: static;
            top: auto;
            bottom: auto;
        }
    }
}

.skip-to-content {
    top: 2px;
    left: 2px;
    position: fixed;
    margin: 0;
    padding: 0;
    background-color: $secondary-11;

    span {
        display: inline-block;
    }

    &:focus {
        color: #ffffff;
        border: none;
        padding: 16px;
        z-index: 1050;
    }

    &:hover {
        color: #ffffff;
    }
}

.submenu-wrapper {
    width: 100%;
}