.font-size-18 {
  font-size: 18px;
}

.color-info-btn {
  color: #2e97f8;
}

.boder-info {
  width: 28px;
  height: 28px;
  margin: auto;
  border: 2px solid #2e97f8;
  border-radius: 20px;
}

.icon-row {
  font-size: 16px;
  padding-right: 4px;
  color: #2e97f8;
}

.min-width-55 {
  min-width: 55px;
}

.flag-row {
  border: 1px solid #ccc;
  padding: 6px;
  padding-bottom: 2px;
  border-radius: 2px;
}

.font-size-26 {
  font-size: 26px;
}

.font-size-24 {
  font-size: 24px;
}

.w-35px {
  width: 35px;
}

.border-left-3-grey {
  border-left: 3px solid grey;
}

.line-border-bottom {
  border-bottom: 1px solid #e6e2e2;
}

.icon-color-2e97f8 {
  color: #2e97f8;
}

.background-F2F3F7 {
  background-color: #f2f3f7;
}
