.form-global-policy-pre-content {
  .panel-monsido-card {
    h3 {
      margin-top: 0;
    }
  }
  .rule-box-bg {
    color: $secondary-4;
    background-color: $secondary-1;
    padding: 15px;
  }
}
