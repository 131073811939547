@import 'variables.scss';

.table-header-page-views {
    width: 80px;
}

.table-with-labels {
    tr:hover {
        .label-container {
            .label-btn {
                opacity: 1;
            }
        }
        .label-table {
            .btn-label-edit {
                visibility: visible;
            }
        }
    }
}

table > thead > tr > td {
    border-bottom: 2px solid $table-border-color;
}

.table > tbody > tr > td.td-content-fill {
    padding: 0;
}

.td-content-fill {
    height: 0;
    & > div {
        height: 100%;
        min-height: 90px;
        overflow: auto;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-end;
        padding-right: 10px;
    }
}

table-page-size {
    .btn {
        --bs-btn-border-color: #c5c5c5;
        --bs-btn-hover-border-color: #c5c5c5;

        --bs-btn-active-bg: #e6e6e6;
        --bs-btn-hover-bg: #e6e6e6;

        --bs-btn-active-border-color: #c5c5c5;

        --bs-btn-hover-color: #{$main-1};
        --bs-btn-active-color: #{$main-1};
    }
}
