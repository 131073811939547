$header-one-size: 24px;
$header-two-size: 18px;

.page-header {
  margin-bottom: 20px;
  border-bottom: none !important;

  h1, h2 {
    color: #555557;
    margin: 0;
    line-height: 1.11;
    width: 100%;
    max-width: 100%;
    padding-top: 4px;
  }

  .position-relative {
    position: static;
  }

  .has-subheader {
    padding-top: 3px;
  }

  .icon-container {
    font-size: $header-one-size;

    &.header-two {
      font-size: $header-two-size;
    }
  }

  h2 {
    font-size: $header-two-size;
  }

  h1 {
    font-size: $header-one-size;
  }

  p {
    color: #777777;
    font-weight: 400;
  }

  .page-header-icon {
    margin-right: 10px;

    i {
      color: #229bf9;
      font-size: 2.3em;
    }

    &.push-down {
      margin-top: 8px;
    }
  }

  .header-sub {
    font-weight: 400;
    margin-top: 1px;
    margin-bottom: 14px;
    overflow-wrap: break-word;
    white-space: normal;
  }

  h1 + .header-sub {
    font-size: 13px;
  }

  h2 + .header-sub {
    font-size: 12px;
  }

  .position-static {
    position: static !important;
  }
  .google-icon{
    padding: 5px 10px;
  }
}
