.accessibility-issues-pages,
.readability-pages-with-score {
    .border-l {
        border-left-width: 1px;
        border-left-color: $grey-color-3;
        border-left-style: solid;
    }

    .border-r {
        border-right-width: 1px;
        border-right-color: $grey-color-3;
        border-right-style: solid;
        &-md {
            border-right: 0;
            @media (min-width: 1200px) {
                border-right: 1px solid $grey-color-3;
            }
        }
    }

    .border-b {
        border-bottom-width: 1px;
        border-bottom-color: $grey-color-3;
        border-bottom-style: solid;
        &-md {
            border-bottom: 1px solid $grey-color-3;
            @media (min-width: 1200px) {
                border-bottom: 0;
            }
        }
    }

    .merge-l-border {
        margin-left: -1px;
    }
}
