.notification-dropdown {
  .dropdown-menu {
    min-width: 300px;
    > li {
      &.text-grey {
        cursor: default;
      }

      & > div {
        width: 100%;
        .icon-width {
          width: 18px;
        }
      }
    }
  }

  .border-pointer:before {
    position: absolute;
    top: -7px;
    right: 9px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: "";
  }

  .border-pointer:after {
    position: absolute;
    top: -6px;
    right: 10px;
    display: inline-block;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    border-left: 6px solid transparent;
    content: "";
  }
}
