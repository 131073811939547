.page-title {
    .url-title-link {
        span {
            color: #229bf9;
        }
        .without-link {
            line-height: 3em;
        }
    }
}
