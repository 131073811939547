$submenu-color: #229bf9;
$menu-navbar-height: 60px;

#submenu.navbar.navbar-inverse {
    margin-top: $menu-navbar-height;
    color: $submenu-color;
    background: #fff;
    border: none;
    height: $menu-navbar-height;
    box-shadow: 0 3px 4px -2px #c6c9ca;
    padding: 0;
    border-radius: 0;
    margin-bottom: 0;
    z-index: 1029;

    .picture {
        height: 10px;
        position: absolute;
        margin: 22px 0 0 19px;
    }

    ul.nav {
        .enableTooltip {
            display: none;
        }
    }

    ul.nav {

        .mini-menu & {
            .link-text {
                display: none;
            }
            .picture {
                margin: 34px 0 0 -12px;
            }
            .enableTooltip {
                display: initial;
            }
        }

    }

    .fa-lg {
        line-height: 1em;
    }

    .notification-circle {
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background: #ff5a07;
        position: absolute;
        left: 30px;
        top: 14px;
    }

    .container-fluid {
        text-align: center;

        .nav {
            display: inline-block;
            float: none;
            padding-top: 2px;

            > li a {
                color: $submenu-color;
                border-bottom-style: solid;
                border-bottom-width: 2px;
                border-bottom-color: transparent;

                padding: 16px;
                line-height: 120%;

                &.active {
                    border-bottom-color: $submenu-color;
                }

                &:hover {
                    background-color: $grey-color-3;
                }
            }
        }
    }
}
