.page-details-dashboard-qa {
    .col-md-48:nth-of-type(5) {
        clear: left;
    }

    @media screen and (min-width: 1200px) and (max-width: 1665px), screen and (min-width: 768px) and (max-width: 1024px) {
        & > .col-24 {
            padding-right: 0;
            padding-left: 0;
        }

        notification {
            .notification {
                padding: 20px 10px 20px 10px !important;
            }
        }
    }

    @media screen and (min-width: 1200px) and (max-width: 1379px),
        screen and (min-width: 768px) and (max-width: 919px),
        screen and (max-width: 580px) {
        & > div:nth-child(2),
        & > div:nth-child(4) {
            notification {
                .notification {
                    .notification-icon {
                        & > span:first-child {
                            & > span:first-child {
                                position: relative;
                                top: 6px;
                            }
                        }
                    }
                }
            }
        }

        notification {
            .notification {
                display: block !important;

                .notification-icon {
                    & > span:first-child {
                        & > span:first-child {
                            text-align: left;
                        }
                    }

                    & + div {
                        margin-top: 5px;
                        padding-left: 0 !important;
                    }
                }
            }
        }

        notification {
            display: block;

            div {
                cursor: pointer;
            }

            @media screen and (min-width: 1281px) and (max-width: 1665px), screen and (min-width: 887px) and (max-width: 1024px) {
                .notification {
                    padding-left: 10% !important;
                }
            }

            @media screen and (min-width: 1200px) and (max-width: 1280px), screen and (min-width: 768px) and (max-width: 886px) {
                .notification {
                    padding-left: 10px !important;

                    .notification-icon {
                        padding-right: 10px !important;
                    }
                }
            }
        }
    }
}
