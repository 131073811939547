.accessibility-update-indicator {
    border-bottom: 3px dashed $secondary-15;
    text-align: center;
    padding-bottom: 8px;
    margin-bottom: 15px;

    .accessibility-update-indicator-link {
        color: $secondary-15;
        text-decoration: underline;
        text-decoration-color: $secondary-15;
    }
}
