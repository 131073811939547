div.mon-dialog.document-details-app {
    padding-top: 20px;

    > div {
        height: 100%;
    }

    .mon-dialog-close {
        top: 28px;
    }

    .title-row {
        min-height: 95px;
    }

    div.document-details-topmenu {
        border-top: 1px solid $grey-color-3;
        border-bottom: 1px solid $grey-color-3;

        & > ul {
            margin-bottom: 0;
            list-style-type: none;

            & > li {
                display: inline-block;
                padding-top: 20px;
                padding-left: $page-details-topmenu-left-right-padding;
                padding-right: $page-details-topmenu-left-right-padding;
                padding-bottom: $page-details-topmenu-padding-bottom;
                border-bottom: $page-details-topmenu-border-bottom solid
                    transparent;
                white-space: nowrap;
                color: $color-page-details-link;

                &.active,
                &:hover {
                    color: $link-color;
                }
            }
        }
    }

    .page-details-section {
        height: 100%;
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        .page-header:first-child {
            padding-left: 15px;
            padding-right: 15px;
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: 1px solid $grey-color-3 !important;
        }

        .page-details-sitemenu {
            ul.sidebar-nav {
                padding-left: 0;

                & > li:not(.separator) {
                    & > a:hover,
                    & > button:hover,
                    &.open {
                        cursor: pointer;
                        background-color: $light-grey;
                    }

                    & > a.active,
                    & > button.active{
                        background-color: #d4dce3;
                    }

                    ul.sub {
                        padding: 0;
                        background-color: darken($body-bg, 3);
                        a {
                            padding-left: 30px;
                        }

                        & > li > a:hover,
                        & > li > a.active {
                            cursor: pointer;
                            background-color: darken($body-bg, 8);
                        }
                    }
                }

                & > li.separator {
                    width: 100%;
                    height: 10px;
                    border-bottom: 1px solid $panel-default-border;
                    margin: 20px 0;

                    h4 {
                        display: inline-block;
                        background-color: $body-bg;
                        padding: 0 10px;
                        margin: 0 12px;
                    }
                }
            }

            li {
                padding: 0;
                list-style: none;
                font-weight: 400;
                font-size: 14px;

                a,
                button {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: $sidebar-background-color;
                    padding: 15px 15px 15px 20px;
                    text-decoration: none;
                    width: 100%;
                    border: none;
                    background-color: white;

                    & > .full-width {
                        text-align: start;
                        flex: 1 0 auto;
                    } 

                    &:focus-visible {
                        outline: solid 2px $secondary-14;
                    }
                }


                i {
                    margin-right: 10px;
                }
            }
        }

        .page-details-information-site {
            min-height: 300px;
            height: 100%;
        }
    }
}
