@import '@monsido/colors/dist/colors.scss';

#domain-scan {
    .uib-timepicker {
        .uib-separator {
            display: none;
        }
    }

    .uib-time {
        &.minutes {
            input {
                display: none;
            }
        }
    }
}

.mon-dynamic-form-field-wrapper {
    display: flex;
}

.mon-dynamic-label-wrapper {
    box-sizing: border-box;
    display: inline-flex;
    justify-content: end;

    padding: 7px 2px 0 5px;
    min-width: 80px;
    margin-right: auto;

    .label {
        display: inline-flex;
        height: 15px;
        padding-top: 0;
        padding-bottom: 0;
        align-items: center;
    }

    .mon-dynamic-label-delete-button {
        box-sizing: border-box;
        display: inline-flex;
        font-size: 9px;
        height: 15px;
        appearance: none;
        border: none;
        background-color: #d9edf7;
        color: #31708f;
        padding-top: 0;
        padding-bottom: 0;
        align-items: center;

        &:focus-visible {
            outline: none;
            box-shadow: 0 0 0 1px $secondary-14;
        }
    }
}