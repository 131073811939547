.cookie-banner-cookie-overview-dialog {
    .bounds_for_absolute {
        overflow: hidden;
        position: relative;
        min-height: 500px; /* to fit all dropdowns, including filters */
    }

    .selector-input {
        padding: 0.5em 2.6em 0.5em 0.7em;
    }

    input[aria-invalid="true"] {
        border-color: $secondary-7;
    }

    .fa-question-circle-absolute {
        position: absolute;
        top: 8px;
    }

    .card-footer {
        background-color: $secondary-3;
        border-top: none;
        padding: 10px 20px;

        .btn-save {
            background-color: $main-1;
            color: $color-white;

            &:disabled {
                background-color: $secondary-6;
                color: $secondary-5;
            }

            &:hover {
                background-color: $secondary-10;
            }
        }
    }

    .filters-wrapper {
        table-filters > div {
            border-bottom: none;
            padding: 0 0 0 20px;
        }
    }

    .flex_table {
        display: flex;
        width: 100%;

        .left_section {
            flex: 0 0 45px;

            > div {
                height: 30px;

                &:not(:first-of-type) {
                    margin-bottom: 10px;
                }
            }

            .left_section_icon_wrapper {
                align-items: center;
                justify-content: flex-end;
                display: flex;
                padding-right: 10px;
                position: absolute;
                background: $color-white;
                height: 30px;
                width: 45px;
                z-index: 900;
            }
        }

        .middle_section {
            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: calc(100% - 145px);
            overflow-x: auto;
            overflow-y: hidden;

            .middle_section_content {
                min-width: 746px;

                > div {
                    display: flex;

                    &:not(:first-of-type) {
                        margin-bottom: 10px;
                    }

                    > div {
                        display: flex;
                        align-items: center;
                        flex: 1 0 auto;
                        height: 30px;

                        &:not(:first-of-type) {
                            padding-left: 10px;
                        }
                    }

                    > div:nth-child(1) {
                        flex-basis: 15%;
                    }

                    > div:nth-child(2) {
                        flex-basis: 15%;
                    }

                    > div:nth-child(3) {
                        flex-basis: 15%;
                    }

                    > div:nth-child(4) {
                        flex-basis: 15%;
                    }

                    > div:nth-child(5) {
                        flex-basis: 25%;
                    }

                    > div:nth-child(6) {
                        flex-basis: 15%;
                    }
                }
            }

            form-field-select {
                height: 30px;
                position: absolute;
            }
        }

        .right_section {
            flex: 0 0 100px;

            > div {
                height: 30px;
                padding-left: 10px;
                display: flex;
                align-items: center;

                &:not(:first-of-type) {
                    margin-bottom: 10px;
                }
            }

            button {
                height: 30px;
            }
        }
    }

    .table-container-paginate {
        padding: 10px 45px;
        height: 30px;
        overflow: hidden;
        box-sizing: content-box;
    }

    @media all and (-ms-high-contrast: none) {
        .position-relative-in-ie-only {
            position: relative;
            form-field-select {
                top: 0;
            }
        }
    }

    .border-bottom-none {
        border-bottom: 0;
    }
}
