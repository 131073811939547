.notification {
    .notification-icon {
        .text-grey {
            color: #e2e2e2;
        }

        .check-passed {
            color: #00ab20;
            left: 55%;
            top: 35%;
            font-size: 1.4em;
        }
    }

    .fa-xl {
        font-size: 1.583em;
    }
}

.notification-with-link {
    cursor: pointer;

    &:hover, &:focus {
        background-color: $secondary-1;
    }
}
