.accessibility-dialogs-new-help-center {
    .panel .card-header {
        .help-center-header {
            h2 {
                line-height: 1.5;
            }
        }
    }

    .max-text-width {
        max-width: 1240px;
    }

    .tag {
        color: #1f1f1f;
        border-width: 1px;
        border-style: solid;
        border-radius: 4px;
        background-color: transparent;
        padding: 3px 10px;
        transition: all 0.1s ease;
    }

    .tag-info {
        border-color: $text-grey;
    }

    .tag-warning {
        border-color: $brand-warning;
    }

    .tag-danger {
        border-color: $brand-danger;
    }

    // Help Center Custom design changes to written content.

    pre {
        white-space: normal;
    }
}
