.accessibility-dashboard-compliance-bar {
    .percentage-block:hover,
    .percentage-block:focus {
        ~ .percentage-status {
            visibility: visible;
        }
    }
    .percentage-status {
        visibility: hidden;
    }
}
