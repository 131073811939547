#page-details-data-policy {
    .nav.nav-tabs.table-nav-tabs > li > a {
        line-height: 1.1em;
    }
    .page-details-section-body {
        height: auto !important;
    }
    .panel-monsido-card .card-body {
        padding-top: 5px;
    }
    .pages-table-wrapper .table-container {
        padding: 0;
    }

    .table-wrapper {
        flex: 0 0 60%;
        max-width: 60%;
        margin-right: 15px;
    }
    .table tbody tr:hover {
        background-color: $grey-color-2;
        cursor: pointer;
    }
    .table tr.active {
        background-color: $grey-color-3;
    }
    .card-header {
        padding: 7px 15px;
        .bg-light-grey {
            background-color: $grey-color-3 !important;
        }
        .fa {
            border-width: 1px;
            border-style: solid;
            border-radius: 15px;
        }
    }
    .violation-table {
        tr {
            margin: 1px;
        }
        tr:nth-child(n+2) {
            border-top: 1px solid $grey-color-2;
        }
        tr:hover {
            background-color: $grey-color-2;
        }
        td {
            padding: 4px 0;
            border: none;
        }
    }
    .card-header {
        .type {
            font-size: initial;
            width: 30px;
            height: 30px;
            padding: 6px;
        }
    }

    .mt-19 {
        margin-top: 95px;
    }
}
