.statitistics-visitors-devices--container {
  .statitistics-visitors-devices--doughnut-chart {
    height: 340px;
    .doughnut canvas {
      max-height: unset;
      max-width: unset;
    }
  }

  .statitistics-visitors-devices--bar-chart {
    height: 340px;
    .chart-horizontal-bar {
      max-height: 260px;
    }
    chart-horizontal-bar {
      & > div {
        margin-top: 40px;
      }
    }
  }

  .vertical-ruler {
    margin: 3px 0 3px -5px;
  }

  .visitor-summary-devices__first {
    background-color: #3669d3;
  }

  .visitor-summary-devices__second {
    background-color: #89cdfd;
  }

  .visitor-summary-devices__third {
    background-color: #3da3e9;
  }
}
