@import '@monsido/colors/dist/colors.scss';

#data-privacy-summary {
    .card {
        margin-bottom: 30px;
    }
    .table > thead > tr > th {
        border-bottom: none !important;
    }
    .card-container {
        ul {
            list-style: none;
            li {
                display: inline-block;
            }
        }
    }
    .label-fixed {
        display: inline-block;
    }
    .high {
        color: $color-high-red;
    }
    .medium {
        color: $color-medium-yellow;
    }
    .low {
        color: $color-low-blue;
    }

    .data-privacy-dashboard-doughnut {
        .doughnut .chart.chart-doughnut {
            max-height: 200px !important;
            max-width: 200px !important;
            margin: auto;
        }
    }

    .text-grey {
        color: $secondary-4;
    }
}
