.page-introduction {
  h4 {
    line-height: 1.6em;
  }

  p {
    line-height: 1.6em;
    margin-bottom: 14px;
  }

  ul > li {
    line-height: 1.6em;

    &:last-child {
      margin-bottom: 14px;
    }
  }

  img {
    + h4 {
      margin-top: 30px;
    }
  }
}
