.profile-select-dropdown {
    margin-left: auto;

    .selector-input {
        border: 1px solid #dcdcdc;
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05),
            inset 0 1px 0 rgba(255, 255, 255, 0.8);
        background-color: #fff;
        background-image: none !important;
        height: 73px;
    }
    .selector-dropdown {
        .active-profile-item {
            background-color: $performance-dropdown-selected-color;
        }
    }
    .selector-dropdown > li {
        padding: 0;
    }
    &.no-selector-arrow {
        .selector-helper .selector-icon {
            display: none;
        }
    }
}
