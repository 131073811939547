.policy-form-content {
  .rules-fixed-height-container {
    padding-left: 20px;
    padding-right: 20px;
    height: 75px;

    .icon-container {
      width: 70px;
    }

    .fa-2x {
      font-size: 1.8em;
    }

    .fa {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
