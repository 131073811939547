.accessibility-mode { 
    .nav.nav-tabs.table-nav-tabs > li {
        & > a {
            color: $accessibility-link-color;
        }
    
        &:hover > a,
        &:focus > a {
            color: $accessibility-link-color;
        }
        &.active > a {
            color: $accessibility-link-color;
            border-color: $accessibility-link-color;
    
            &:focus,
            &:hover {
                color: $accessibility-link-color;
                border-color: $accessibility-link-color;
            }
        }
    }

}
