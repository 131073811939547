/* You can add global styles to this file, and also import other style files */
// @import 'ng2/external/dialog/styles';
@import "ng2/services/loading/services/loading.service.scss";

.toast-top-right {
    top: 61px;
    right: 5px;
}

#toast-container>.ngx-toastr {
    // this is fixing a bug with ngx-toastr toast not disappearing from the DOM if it has been interacted with (hover or click)
    pointer-events: unset;
}

.input-group-prepend {
    padding: 0 !important;
}

.input-group-text {
    padding-right: 0;
}