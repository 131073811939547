#accessibilty-check-list {
  .accessibility-issues-table-spinner {
    line-height: 46px;
  }

  table-css-compliance {
    .table-css-compliance {
      justify-content: end !important;
    }
  }
}