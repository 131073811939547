.data-privacy-help-center-data {
    h3 {
        font-size: 110%;
        font-weight: 600;
    }
    .dpa-severity {
        display: inline-block;
        margin-left: 1rem;
    }

    .data-privacy-help-center-description-container {
        background-color: $secondary-1;
        padding: 10px;
        margin: 10px 10px 10px 0;
    }
}
