.stats-summary-traffic-sources-panel {
    .notification {
        & > div {
            margin: 0;
        }
        &:hover {
            background-color: transparent;
        }
    }
}
