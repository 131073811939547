$page-details-accessibility-notification-height: 49px;

#page-details-accessibility {
    .page-details-section-body.with-notification {
        height: calc(100% - (#{$page-details-section-header-height} + #{$page-details-accessibility-notification-height} + 1px)); //Height of container plus border height
    }

    .page-details-information-site {
        .tabs-and-buttons-container {
            display: flex;
            justify-content: space-between;
        }

        .buttons-container {
            display: flex;
            align-self: center;
            flex-wrap: wrap;
            justify-content: flex-end;
            gap: 5px;
            margin-top: 12px;
        }
    }
}
