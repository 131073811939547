.accessibility-fast-track {
    table-search .search-input.form-group {
        .input-group .input-group-prepend .input-group-text {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            border-top-width: 0;
            border-top-color: transparent;
        }

        .input-group .input-group-append .input-group-text {
            border-right: 0;
            border-top: 0;
            border-radius: 0;
        }

        .input-group input.form-control[type="search"] {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            border-top-width: 0;
            border-top-color: transparent;
            border-right-width: 0;
            border-right-color: transparent;
        }
    }

    .table-container-paginate {
        padding-right: 15px;
        padding-left: 15px;
    }

    .ft-table-container {
        overflow-y: auto;
        overflow-x: hidden;
    }

    table.checks-table tbody tr {
        &.captioned {
            background: rgb(254, 255, 229);
        }
    }

    .form-group.has-feedback.has-feedback-left.search-input.white {
        margin-bottom: 0;
    }
}
