.page-performance-page-details-performance-opportunities {
    .markdown-container {
        & > div > p {
            white-space: normal;
            overflow: hidden;
        }
    }
    .export-btn-height {
        height: 40px;
    }
}
