#consentLog {    
    form[name="search-uuid"] {
        input {
            height: 32px;
        }

        .row-valign-center {
            align-items: center;
        }
    
        .icon-valign-center {
            vertical-align: middle;
        }
    }
    .anon-ip-label {
        background-color: $grey-color-3;
        color: $secondary-5;
        position: relative;
        top: -1px;
    }

    .label-cookies {
        font-size: 1em;
        display: inline-block;
        padding: 6px 10px;
        .label-cookie-content {
            &:not(:only-child) {
                text-align: right;
            }
            &:not(:last-child) {
                margin-bottom: 5px;
            }
        }
    }

    .label-warning {
        color: $secondary-15;
    }

    .label-danger {
        color: $secondary-9;
    }
}
