@import "@monsido/colors/dist/colors";

$accessibility-menu-background: $branding-4;
$accessibility-menu-search-form-background: $branding-4;
$accessibility-menu-search-background: $branding-4;
$accessibility-menu-search-form-text-color: #ffffff;
$accessibility-menu-navbar-icon-color: #ffffff;
$accessibility-menu-navbar-icon-hover-color: #ffffff;
$accessibility-menu-navbar-link-active-color: #4dc0bd;
$accessibility-menu-link-background-color: #021827;

.navbar.navbar-fixed-top {
    height: $topmenu-navbar-height;
    min-height: $topmenu-navbar-height;
    background-color: $menu-background;
    border: none;
    margin-bottom: 0;

    .accessibility-mode & {

        background-color: $accessibility-menu-background;
    }

    .navbar.navbar-fixed-top {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
    }

    img.logo {
        max-height: 100%;
    }
    a.logo {
        margin: 0 !important;
        height: inherit !important;
        width: auto !important;
        &:hover {
            background-color: transparent !important;
        }
    }

    .navbar-brand {
        height: $topmenu-navbar-height;
    }

    a {
        color: $menu-navbar-icon-color;

        &:hover {
            color: $link-color;
        }
        &:focus {
            outline: $color-dark-blue auto 5px;
        }
    }

    .dropdown-menu {
        top: 95%;
        font-size: 13px;
        right: 0;
        left: auto;

        ul li a:hover {
            background-color: #878787;
        }
    }

    .active,
    .active > a {
        color: $menu-text-active-color;

        &:hover {
            background-color: transparent;
        }
    }

    #profile-menu {
        background-color: transparent;
    }

    .navbar-right {
        margin-left: auto;
    }

    .container-fluid {
        height: $topmenu-navbar-height;
        padding: 0;
        width: 100%;

        .navbar-header {
            height: $topmenu-navbar-height;
            min-width: 450px;

            menu-search-input {
                display: none;
            }

            @media (min-width: 769px) {
                min-width: 165px;
                padding-left: 5px;
                padding-right: 5px;
            }

            @media (min-width: $screen-md-min) {
                padding-left: 0;
                padding-right: 0;
                menu-search-input {
                    display: inline-block;
                }
            }

            a.navbar-brand {
                padding: 15px 10px 15px 0;

                @media (min-width: $screen-sm-min) {
                    padding: 15px 35px 15px 10px;
                }
                @media (min-width: $screen-md-min) {
                    padding: 15px 15px 15px 20px;
                }
                @media (min-width: $screen-lg-min) {
                    padding: 15px 15px 15px 20px;
                }
            }

            .navbar-toggler {
                border-color: #000000;
                background-color: transparent;
                margin-right: 30px;
                margin-top: 13px;

                .accessibility-mode & {
                    border-color: #ffffff;
                }

                .icon-bar {
                    color: #000000;
                    background-color: #000000;

                    .accessibility-mode & {
                        color: #ffffff;
                        background-color: #ffffff;
                    }
                }
            }

            @media (max-width: $screen-xs-max) {
                width: 100%;
            }


        }

        #navbar {

            justify-content: space-between;

            &.navbar-collapse {

                &.collapse.show {
                    border-color: $menu-background;
                    background: $menu-background;

                    overflow-y: auto;
                }

                .navbar-nav {

                    &:nth-child(2) {
                        justify-content: flex-end;
                        margin-left: auto;
                    }

                    & > li > a {
                        display: block;
                        padding: 17px 10px;
                        height: 47px;

                        span:not(.far, .fas, .fab, .fal) {
                            font-size: 0.85em;
                            &.label {
                                font-size: 0.7em;
                            }
                        }
                    }

                    .dropdown {
                        height: $topmenu-navbar-height;
                    }

                    @media (min-width: $screen-xs-max) {
                        &,

                        & > li {
                            height: $topmenu-navbar-height;
                        }
                    }

                    & > li {
                        padding-left: 0;
                        padding-right: 0;
                        color: $menu-navbar-icon-color;

                        .menu-modules {
                            width: 280px;
                            font-size: 12px;

                            .global-modules > div:hover {
                                background-color: $topmenu-on-hover-color;

                                a {
                                    color: $menu-text-active-color;
                                    text-decoration: none;
                                }
                            }
                        }

                        & > a,
                        .mon-spotlight > a,
                        .notification-info > a,
                        .mon-intercom a,
                        .mon-velaro a,
                        .mon-shortcuts > a,
                        & > .btn-group > .dropdown-toggle:not(.btn-transparent):not(#profile-menu) {
                            background-color: transparent;
                            margin-top: $topmenu-navbar-active-space - 2;
                            margin-bottom: $topmenu-navbar-active-space - 2;
                            height: 34px;
                            width: 34px;
                            text-decoration: none;

                            @media (max-width: $screen-sm-min) {
                                width: auto;
                                height: auto;
                            }

                            @media (min-width: $screen-xs-max) {
                                &:hover {
                                    @include set-border-radius(50%);
                                    background-color: $topmenu-on-hover-color;
                                    color: $menu-text-active-color;
                                }
                            }
                        }

                        &.dropdown.open > a {
                            @include set-border-radius(50%);
                            background-color: #f1f3f6;
                        }

                        &.open > a {
                            background-color: transparent;
                        }
                    }
                }

                &.collapsing {
                    border-color: $menu-background;
                    background: $menu-background;
                }

                .accessibility-mode & {
                    a {
                        color: $accessibility-menu-navbar-icon-color;
                    }

                    .dropdown-menu a:not(.text-link) {
                        color: #595959 !important;
                        &:hover {
                            color: $accessibility-link-color;
                        }
                    }

                    &.collapse.show {
                        background-color: $accessibility-menu-background;
                        border-color: $accessibility-menu-background;

                        @media (max-width: $screen-sm-min) {
                            .nav.navbar-nav > li {
                                & > a,
                                .mon-spotlight > a,
                                .mon-intercom a {
                                    &:hover {
                                        background-color: transparent;
                                    }
                                }
                            }
                        }
                    }

                    .navbar-nav {
                        & > li {
                            & > a,
                            .mon-spotlight > a,
                            .notification-info > a,
                            .mon-intercom a,
                            .mon-velaro a,
                            .mon-shortcuts > a,
                            &
                                > .btn-group
                                > .dropdown-toggle:not(.btn-transparent):not(#profile-menu) {
                                &:hover {
                                    background-color: $accessibility-menu-link-background-color;
                                    color: $accessibility-menu-navbar-link-active-color;
                                }
                            }

                            .dropdown {
                                &.open {
                                    .btn-transparent {
                                        background-color: #ffffff;
                                        color: $accessibility-theme-base-color;
                                    }
                                }
                            }
                        }
                    }

                    .dropdown-menu .active {
                        color: $accessibility-link-color !important;

                        &:hover {
                            background-color: transparent;
                        }

                        & > a {
                            color: $accessibility-link-color !important;
                        }
                    }

                    .active {
                        color: $accessibility-menu-navbar-link-active-color !important;

                        &:hover {
                            background-color: transparent;
                        }

                        & > a {
                            color: $accessibility-menu-navbar-link-active-color !important;
                        }
                    }

                    &.collapsing {
                        border-color: $accessibility-menu-background;
                        background: $accessibility-menu-background;
                    }

                    .dropdown-menu {
                        li > a {
                            color: $accessibility-link-color;
                        }
                    }
                }

                .menu-item-text {
                    display: none;
                }

                @media (max-width: $screen-sm-min) {
                    .navbar-nav {
                        display: none;
                    }

                    &.collapse.show {
                        .navbar-nav {
                            display: block;
                        }
                    }

                    .menu-item-text {
                        display: inline-block;
                        margin-left: 10px;
                    }

                    @media (min-width: $screen-lg-min) {
                        height: $topmenu-navbar-height;
                    }
                }
            }
        }

    }
}
