.mon-link-url-title {
    div {
        margin-bottom: 3px;
        color: $main-1;
    }

    mon-link {
        --mon-link-color: #{$dark-grey};
        --mon-link-border-radius: 0;
        --mon-link-border: none;
        --mon-link-padding: 0;
        --mon-link-justify-content: flex-start;
        --mon-link-display: unset;
        --mon-link-hover-background-color: transparent;
        --mon-link-background-color: transparent;
        --mon-link-icon-padding: 0;
    }
}
