#data-privacy-module {
    .dpa-coming-soon-illustration {
        max-width: 470px;
    }

    .no-pages-wrapper {
        min-height: calc(100vh - 140px);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media screen and (max-width: $screen-sm) {
        .no-pages-wrapper {
            min-height: auto;
            display: block;
        }
    }
}
