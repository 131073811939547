@import 'vars';
@import 'keyframes';
@import 'mixins/dialog-size';
@import "mixins/dialog-middle";

.mon-dialog-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10000;
  background: #000;
  -webkit-animation: famon-dialog-fadeindein .5s forwards; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: mon-dialog-fadein .5s forwards; /* Firefox < 16 */
  -ms-animation: mon-dialog-fadein .5s forwards; /* Internet Explorer */
  -o-animation: mon-dialog-fadein .5s forwards; /* Opera < 12.1 */
  animation: mon-dialog-fadein .5s forwards;

  &.mon-dialog-overlay-fadeout {
    animation: mon-dialog-fadeout .5s forwards;
  }
}

.mon-dialog {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 70%;
  background: #fff;

  -webkit-animation: sidein ease .5s;
  animation: sidein ease .5s;


  &.fade-animation {
    -webkit-animation: fadein ease .5s;
    animation: fadein ease .5s;
  }

  &.mon-dialog-size-xs {
    @include set-dialog-size($mon-dialog-width-xs);
    @include set-dialog-middle($mon-dialog-width-xs);
  }

  &.mon-dialog-size-sm {
    @include set-dialog-size($mon-dialog-width-sm);
    @include set-dialog-middle($mon-dialog-width-sm);
  }

  &.mon-dialog-size-md {
    @include set-dialog-size($mon-dialog-width-md);
    @include set-dialog-middle($mon-dialog-width-md);
  }

  &.mon-dialog-size-lg {
    @include set-dialog-size($mon-dialog-width-lg);
    @include set-dialog-middle($mon-dialog-width-lg);
  }

  &.mon-dialog-size-fw {
    @include set-dialog-size($mon-dialog-width-fw);
    @include set-dialog-middle($mon-dialog-width-fw);
  }

  .mon-dialog-delay-fadein {
    opacity: 0;
    animation: mon-dialog-delay-fadein .2s forwards;
    animation-delay: .5s;
  }

  .mon-dialog-close {
    position: absolute;
    top: 35px;
    left: 30px;
    &::before {
      content: '\00D7'; // 2715 if the X icon should be bigger than it is
      font-size: 30px;
      color: #6a6a6a;
    }
    
    &:focus-visible {
        outline: none;
        box-shadow: 0 0 0 1px $secondary-14;
    }
  }

  .mon-dialog-body {    
    position: absolute;
    top: $mon-dialog-header-height;
    bottom: 0;
    background-color: #f3f5f7;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
  }

  .mon-dialog-body-with-footer {
    @extend .mon-dialog-body;
    bottom: $mon-dialog-footer-height;
  }

  .mon-dialog-body, .mon-dialog-body-with-footer {
    &.auto-scroll {
      overflow-y: auto;
    }
  }

  .mon-dialog-header {
    background-color: #fff;
    position: absolute;
    top: 0;
    height: $mon-dialog-header-height;
    width: 100%;
    padding: 0 20px 8px 63px;
    border-bottom: 1px solid #e6e6e6 !important;
  }

  .mon-dialog-footer {
    background-color: #fff;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: $mon-dialog-footer-height;
    border-top: 3px solid #e6e6e6;
    padding-top: 23px;
    padding-right: 23px;
  }

  &.mon-dialog-closeing {
    -webkit-animation: sideout ease .5s forwards;
    animation: sideout ease .5s forwards;

    &.fade-animation {
      -webkit-animation: fadeout ease  .5s;
      animation: fadeout ease .5s;
    }

    .mon-dialog-delay-fadein {
      animation: mon-dialog-delay-fadeout .1s forwards;
    }
  }
}
