.dropdown-menu-right {
    right: 0;
    left: auto !important;
}

.dropdown-toggle::after {
    display: none;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 12px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);

    .divider {
        height: 1px;
        margin: 7.5px 0;
        overflow: hidden;
        background-color: #e5e5e5;
    }
}
.dropdown-menu>li>a:not([href]):not([class]),
.dropdown-menu>li>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: $legacy-main-color;
    white-space: nowrap;
}

.open>.dropdown-menu {
    display: block;
}

.dropdown-menu.pull-right {
    right: 0;
    left: auto;
}

.dropdown-item {
    padding-left: 0;
    padding-right: 0;
}