.block-referring-information-panel {
  position: relative;
  .progress-bar {
    background-color: #50a6fb;
  }
  .card-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
