.table-score-chart {
    $mon-doughnut-size-sm: 40px;
    $line-color-over-90: $secondary-16;
    $line-color-over-49: $secondary-15;
    $line-color-under-50: $secondary-7;

    .big-score-chart {
        $mon-doughnut-size-sm: 130px;
    }

    .mon-doughnut-chart.chart-point-100 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-100 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-90;
    }
    .mon-doughnut-chart.chart-point-100 .slices.mon-doughnut-slice-two {
        transform: rotate(360deg);
        background: $line-color-over-90;
    }
    .mon-doughnut-chart.chart-point-99 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-99 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-90;
    }
    .mon-doughnut-chart.chart-point-99 .slices.mon-doughnut-slice-two {
        transform: rotate(356.4deg);
        background: $line-color-over-90;
    }
    .mon-doughnut-chart.chart-point-98 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-98 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-90;
    }
    .mon-doughnut-chart.chart-point-98 .slices.mon-doughnut-slice-two {
        transform: rotate(352.8deg);
        background: $line-color-over-90;
    }
    .mon-doughnut-chart.chart-point-97 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-97 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-90;
    }
    .mon-doughnut-chart.chart-point-97 .slices.mon-doughnut-slice-two {
        transform: rotate(349.2deg);
        background: $line-color-over-90;
    }
    .mon-doughnut-chart.chart-point-96 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-96 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-90;
    }
    .mon-doughnut-chart.chart-point-96 .slices.mon-doughnut-slice-two {
        transform: rotate(345.59999999999997deg);
        background: $line-color-over-90;
    }
    .mon-doughnut-chart.chart-point-95 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-95 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-90;
    }
    .mon-doughnut-chart.chart-point-95 .slices.mon-doughnut-slice-two {
        transform: rotate(342deg);
        background: $line-color-over-90;
    }
    .mon-doughnut-chart.chart-point-94 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-94 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-90;
    }
    .mon-doughnut-chart.chart-point-94 .slices.mon-doughnut-slice-two {
        transform: rotate(338.4deg);
        background: $line-color-over-90;
    }
    .mon-doughnut-chart.chart-point-93 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-93 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-90;
    }
    .mon-doughnut-chart.chart-point-93 .slices.mon-doughnut-slice-two {
        transform: rotate(334.8deg);
        background: $line-color-over-90;
    }
    .mon-doughnut-chart.chart-point-92 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-92 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-90;
    }
    .mon-doughnut-chart.chart-point-92 .slices.mon-doughnut-slice-two {
        transform: rotate(331.2deg);
        background: $line-color-over-90;
    }
    .mon-doughnut-chart.chart-point-91 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-91 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-90;
    }
    .mon-doughnut-chart.chart-point-91 .slices.mon-doughnut-slice-two {
        transform: rotate(327.6deg);
        background: $line-color-over-90;
    }
    .mon-doughnut-chart.chart-point-90 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-90 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-90;
    }
    .mon-doughnut-chart.chart-point-90 .slices.mon-doughnut-slice-two {
        transform: rotate(324deg);
        background: $line-color-over-90;
    }
    .mon-doughnut-chart.chart-point-89 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-89 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-89 .slices.mon-doughnut-slice-two {
        transform: rotate(320.4deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-88 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-88 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-88 .slices.mon-doughnut-slice-two {
        transform: rotate(316.8deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-87 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-87 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-87 .slices.mon-doughnut-slice-two {
        transform: rotate(313.2deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-86 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-86 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-86 .slices.mon-doughnut-slice-two {
        transform: rotate(309.6deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-85 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-85 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-85 .slices.mon-doughnut-slice-two {
        transform: rotate(306deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-84 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-84 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-84 .slices.mon-doughnut-slice-two {
        transform: rotate(302.4deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-83 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-83 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-83 .slices.mon-doughnut-slice-two {
        transform: rotate(298.8deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-82 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-82 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-82 .slices.mon-doughnut-slice-two {
        transform: rotate(295.2deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-81 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-81 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-81 .slices.mon-doughnut-slice-two {
        transform: rotate(291.6deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-80 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-80 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-80 .slices.mon-doughnut-slice-two {
        transform: rotate(288deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-79 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-79 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-79 .slices.mon-doughnut-slice-two {
        transform: rotate(284.40000000000003deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-78 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-78 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-78 .slices.mon-doughnut-slice-two {
        transform: rotate(280.8deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-77 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-77 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-77 .slices.mon-doughnut-slice-two {
        transform: rotate(277.2deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-76 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-76 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-76 .slices.mon-doughnut-slice-two {
        transform: rotate(273.6deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-75 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-75 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-75 .slices.mon-doughnut-slice-two {
        transform: rotate(270deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-74 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-74 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-74 .slices.mon-doughnut-slice-two {
        transform: rotate(266.4deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-73 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-73 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-73 .slices.mon-doughnut-slice-two {
        transform: rotate(262.8deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-72 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-72 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-72 .slices.mon-doughnut-slice-two {
        transform: rotate(259.2deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-71 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-71 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-71 .slices.mon-doughnut-slice-two {
        transform: rotate(255.6deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-70 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-70 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-70 .slices.mon-doughnut-slice-two {
        transform: rotate(251.99999999999997deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-69 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-69 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-69 .slices.mon-doughnut-slice-two {
        transform: rotate(248.39999999999998deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-68 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-68 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-68 .slices.mon-doughnut-slice-two {
        transform: rotate(244.8deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-67 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-67 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-67 .slices.mon-doughnut-slice-two {
        transform: rotate(241.20000000000002deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-66 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-66 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-66 .slices.mon-doughnut-slice-two {
        transform: rotate(237.60000000000002deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-65 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-65 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-65 .slices.mon-doughnut-slice-two {
        transform: rotate(234deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-64 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-64 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-64 .slices.mon-doughnut-slice-two {
        transform: rotate(230.4deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-63 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-63 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-63 .slices.mon-doughnut-slice-two {
        transform: rotate(226.8deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-62 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-62 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-62 .slices.mon-doughnut-slice-two {
        transform: rotate(223.2deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-61 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-61 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-61 .slices.mon-doughnut-slice-two {
        transform: rotate(219.6deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-60 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-60 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-60 .slices.mon-doughnut-slice-two {
        transform: rotate(216deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-59 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-59 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-59 .slices.mon-doughnut-slice-two {
        transform: rotate(212.39999999999998deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-58 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-58 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-58 .slices.mon-doughnut-slice-two {
        transform: rotate(208.79999999999998deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-57 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-57 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-57 .slices.mon-doughnut-slice-two {
        transform: rotate(205.2deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-56 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-56 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-56 .slices.mon-doughnut-slice-two {
        transform: rotate(201.60000000000002deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-55 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-55 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-55 .slices.mon-doughnut-slice-two {
        transform: rotate(198.00000000000003deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-54 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-54 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-54 .slices.mon-doughnut-slice-two {
        transform: rotate(194.4deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-53 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-53 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-53 .slices.mon-doughnut-slice-two {
        transform: rotate(190.8deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-52 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-52 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-52 .slices.mon-doughnut-slice-two {
        transform: rotate(187.20000000000002deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-51 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-51 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-51 .slices.mon-doughnut-slice-two {
        transform: rotate(183.6deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-50 {
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-50 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-50 .slices.mon-doughnut-slice-two {
        transform: rotate(180deg);
        background: $line-color-over-49;
    }
    .mon-doughnut-chart.chart-point-49 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-49 .slices.mon-doughnut-slice-one {
        transform: rotate(266.4deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-49 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-48 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-48 .slices.mon-doughnut-slice-one {
        transform: rotate(262.79999999999995deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-48 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-47 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-47 .slices.mon-doughnut-slice-one {
        transform: rotate(259.2deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-47 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-46 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-46 .slices.mon-doughnut-slice-one {
        transform: rotate(255.6deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-46 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-45 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-45 .slices.mon-doughnut-slice-one {
        transform: rotate(252deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-45 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-44 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-44 .slices.mon-doughnut-slice-one {
        transform: rotate(248.4deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-44 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-43 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-43 .slices.mon-doughnut-slice-one {
        transform: rotate(244.8deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-43 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-42 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-42 .slices.mon-doughnut-slice-one {
        transform: rotate(241.2deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-42 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-41 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-41 .slices.mon-doughnut-slice-one {
        transform: rotate(237.6deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-41 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-40 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-40 .slices.mon-doughnut-slice-one {
        transform: rotate(234deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-40 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-39 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-39 .slices.mon-doughnut-slice-one {
        transform: rotate(230.4deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-39 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-38 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-38 .slices.mon-doughnut-slice-one {
        transform: rotate(226.8deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-38 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-37 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-37 .slices.mon-doughnut-slice-one {
        transform: rotate(223.2deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-37 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-36 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-36 .slices.mon-doughnut-slice-one {
        transform: rotate(219.6deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-36 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-35 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-35 .slices.mon-doughnut-slice-one {
        transform: rotate(216deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-35 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-34 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-34 .slices.mon-doughnut-slice-one {
        transform: rotate(212.4deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-34 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-33 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-33 .slices.mon-doughnut-slice-one {
        transform: rotate(208.8deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-33 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-32 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-32 .slices.mon-doughnut-slice-one {
        transform: rotate(205.2deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-32 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-31 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-31 .slices.mon-doughnut-slice-one {
        transform: rotate(201.6deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-31 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-30 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-30 .slices.mon-doughnut-slice-one {
        transform: rotate(198deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-30 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-29 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-29 .slices.mon-doughnut-slice-one {
        transform: rotate(194.39999999999998deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-29 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-28 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-28 .slices.mon-doughnut-slice-one {
        transform: rotate(190.8deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-28 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-27 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-27 .slices.mon-doughnut-slice-one {
        transform: rotate(187.2deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-27 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-26 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-26 .slices.mon-doughnut-slice-one {
        transform: rotate(183.60000000000002deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-26 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-25 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-25 .slices.mon-doughnut-slice-one {
        transform: rotate(180deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-25 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-24 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-24 .slices.mon-doughnut-slice-one {
        transform: rotate(176.39999999999998deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-24 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-23 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-23 .slices.mon-doughnut-slice-one {
        transform: rotate(172.8deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-23 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-22 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-22 .slices.mon-doughnut-slice-one {
        transform: rotate(169.2deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-22 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-21 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-21 .slices.mon-doughnut-slice-one {
        transform: rotate(165.6deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-21 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-20 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-20 .slices.mon-doughnut-slice-one {
        transform: rotate(162deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-20 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-19 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-19 .slices.mon-doughnut-slice-one {
        transform: rotate(158.4deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-19 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-18 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-18 .slices.mon-doughnut-slice-one {
        transform: rotate(154.8deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-18 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-17 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-17 .slices.mon-doughnut-slice-one {
        transform: rotate(151.2deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-17 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-16 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-16 .slices.mon-doughnut-slice-one {
        transform: rotate(147.6deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-16 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-15 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-15 .slices.mon-doughnut-slice-one {
        transform: rotate(144deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-15 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-14 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-14 .slices.mon-doughnut-slice-one {
        transform: rotate(140.4deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-14 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-13 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-13 .slices.mon-doughnut-slice-one {
        transform: rotate(136.8deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-13 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-12 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-12 .slices.mon-doughnut-slice-one {
        transform: rotate(133.2deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-12 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-11 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-11 .slices.mon-doughnut-slice-one {
        transform: rotate(129.6deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-11 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-10 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-10 .slices.mon-doughnut-slice-one {
        transform: rotate(126deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-10 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-9 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-9 .slices.mon-doughnut-slice-one {
        transform: rotate(122.4deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-9 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-8 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-8 .slices.mon-doughnut-slice-one {
        transform: rotate(118.8deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-8 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-7 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-7 .slices.mon-doughnut-slice-one {
        transform: rotate(115.2deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-7 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-6 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-6 .slices.mon-doughnut-slice-one {
        transform: rotate(111.6deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-6 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-5 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-5 .slices.mon-doughnut-slice-one {
        transform: rotate(108deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-5 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-4 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-4 .slices.mon-doughnut-slice-one {
        transform: rotate(104.4deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-4 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-3 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-3 .slices.mon-doughnut-slice-one {
        transform: rotate(100.8deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-3 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-2 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-2 .slices.mon-doughnut-slice-one {
        transform: rotate(97.2deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-2 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-1 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-1 .slices.mon-doughnut-slice-one {
        transform: rotate(93.6deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-1 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-0 {
        background: $line-color-under-50;
    }
    .mon-doughnut-chart.chart-point-0 .slices.mon-doughnut-slice-one {
        transform: rotate(90deg);
        background: #e1e1e1;
    }
    .mon-doughnut-chart.chart-point-0 .slices.mon-doughnut-slice-two {
        transform: rotate(0deg);
        background: #e1e1e1;
    }
}
