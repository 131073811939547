.page-fix-accessibility-checks {
    div.filters {
        & > button.btn {
            padding: 3px 8px;

            &.active {
                background-color: $blue-color-1;
                color: $color-white;
            }
        }
    }

    .check-heading {
        font-size: 14px;
    }

    img.icon {
        max-height: 45px;
        max-width: 45px;
    }

    table.table.accessibility-check-table {
        & > thead {
            .table-action-grid {
                width: 160px;
            }

            .table-difficulty-grid {
                width: 120px;
            }
        }
        & > thead > tr > th {
            border-bottom: none;
        }

        & > tbody > tr:not(.table-divider) {
            box-shadow: 0 0 6px #00000029;
            & > td {
                background-color: $color-white;
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }

        & > tbody {
            tr.table-divider td {
                padding: 2px 0;
            }
        }

        & > thead > tr > th,
        & > tbody > tr > th,
        & > thead > tr > td,
        & > tbody > tr > td {
            border-top: none;
        }

        .name-section {
            padding-left: 71px;
        }

        .loading-pagefix-img {
            width: 20px;
            height: 20px;
        }
    }

    th .extra-margin-for-th {
        margin-left: 65px;
    }

    span.fa-question-circle {
        margin-left: 5px;
    }
}
