.accessibility-mode { 
  
  .button-monsido-icon {
    .page-count {
      color: $accessibility-link-color;
    }
  
    .page-text {
      color: #000000;
    }
  }

}
