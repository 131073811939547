@mixin set-background-color($name, $color){
  .bg-#{"" + $name} {
    background-color: $color !important;
  }

  .mon-dialog {
    .mon-dialog-body,
    .mon-dialog-body-with-footer {
      &.bg-#{"" + $name} {
        background-color: $color;
      }
    }
  }
}
