.alert {
    padding: 15px;
    margin-bottom: 17px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.alert-info {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.alert-notifier {
    --#{$prefix}alert-color: #{#53462a};
    --#{$prefix}alert-bg: #{#fff1c6};
    --#{$prefix}alert-border-color: #{#ead678};
    --#{$prefix}alert-link-color: #{shade-color(#53462a, 20%)};

    @if $enable-gradients {
        background-image: var(--#{$prefix}gradient);
    }

    .alert-link {
        color: var(--#{$prefix}alert-link-color);
    }

    font-size: 14px;
    @include set-border-radius(0);
}

.alert-notification {
    --#{$prefix}alert-color: #{$color-white};
    --#{$prefix}alert-bg: #{$blue-color-1};
    --#{$prefix}alert-border-color: #{transparent};
    --#{$prefix}alert-link-color: #{shade-color($color-white, 20%)};

    @if $enable-gradients {
        background-image: var(--#{$prefix}gradient);
    }

    .alert-link {
        color: var(--#{$prefix}alert-link-color);
    }
}