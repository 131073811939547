.page-fix-accessibility-form {
    h2 {
        font-size: 18px;
        margin-bottom: 20px;
    }

    h3 {
        font-size: 16px;
    }

    .modal-content {
        overflow: hidden;
    }

    .btn-reset-text {
        border-radius: 50%;
        padding: 6px;
        margin-left: 5px;

        &:hover,
        &:focus {
            background-color: $grey-color-3;
        }
    }
}
