#domain-dashboard {
  .acc-issue-by-type {
    .fa-mon-wcag-a,
    .fa-mon-wcag-aa,
    .fa-mon-wcag-aaa {
      font-weight: normal;
      height: 22px;
      padding-top: 4px;
      width: 22px;
    }
  }
}
