.input-group {
  .input-group-addon {
    background: transparent;
    color: #2BA3F5;

    + input.form-control {
      border-left: 0;
      box-shadow: none;
      padding: 0;
    }
  }
}

.form-control[type="search"][placeholder] {
  border-color: #ccc;
  &::-webkit-input-placeholder {
    color: #2BA3F5;
  }

  &:-moz-placeholder { /* Firefox 18- */
    color: #2BA3F5;
  }

  &::-moz-placeholder {  /* Firefox 19+ */
    color: #2BA3F5;
  }

  &:-ms-input-placeholder {
    color: #2BA3F5;
  }
}