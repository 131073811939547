.guidelines-dialogs-checks {
  .panel-pointer {
    margin-top: 10px;
  }

  .panel .card-header {
    h4 {
      margin-top: 6px;

      &.issue-counter {
        margin-top: 7px;
      }
    }
  }

    .panel {
        background-color: #fff;
        border: 1px solid transparent;
    }

    .panel-group .panel {
        margin-bottom: 0;
        border-radius: 4px;
    }
    .panel-monsido-card>.panel-heading {
        color: #333;
        background-color: #fff;
        border-color: #e6e6e6;
    }

    .panel-monsido-card .panel-body {
        padding: 30px 15px;
    }

    .panel-heading {
        padding: 10px 15px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }

    .panel-title>.small, .panel-title>.small>a, .panel-title>a, .panel-title>small, .panel-title>small>a {
        color: inherit;
    }

    .panel-title {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 14px;
    }

}
