@use "sass:math";
@import "./mixins/dougnut.scss";
@import "variants/text";

@mixin doughnut-chart($size, $text-size){
  $width: 5px;
  width: $size;
  height: $size;

  .slices {
    &.mon-doughnut-slice-one {
      clip: rect(0 $size math.div($size, 2) 0);
    }

    &.mon-doughnut-slice-two {
      clip: rect(0 math.div($size, 2) $size 0);
    }
  }

  .chart-center {
    top: $width;
    left: $width;
    width: $size - ($width * 2);
    height: $size - ($width * 2);

    span {
      font-size: $text-size;
      line-height: $size - ($width * 2);
    }
  }
}

.mon-doughnut-chart {
  position: relative;
  border-radius: 50%;
  overflow: hidden;

  .slices {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .chart-center {
    position: absolute;
    border-radius: 50%;

    span {
      display: block;
      text-align: center;
    }
  }

  @include doughnut-chart($mon-doughnut-size-sm, 11px);
  @include make-doughnut-graphs($mon-doughnut-charts, $mon-doughnut-slice-color, $mon-doughnut-color);
}

.chart-center {
  @include doughnut-text-variant(#fff);
}