@import "mixins";
$wcag-circle-size-l: 36px;
$wcag-circle-size: 24px;

.fa-mon-wcag-a,
.fa-mon-wcag-aa,
.fa-mon-wcag-aaa,
.fa-mon-circle {
    display: inline-block;
    padding-top: 16%;
    @include set-border-radius(50%);
    @include set-wcag-icon-size($wcag-circle-size);

    &.fa-2x {
        @include set-wcag-icon-size($wcag-circle-size-l);
    }

    &.outer-r-small.pull-left {
        margin-right: 10px;
    }
}

.fa-mon-wcag-a {
    background-color: $wcag-a;

    &:before {
        display: inline-block;
        content: "A";
    }
}

.fa-mon-wcag-aa {
    background-color: $wcag-aa;

    &:before {
        display: inline-block;
        content: "AA";
    }
}

.fa-mon-wcag-aaa {
    background-color: $wcag-aaa;

    &:before {
        display: inline-block;
        content: "AAA";
    }
}

.fa-mon-circle {
    padding-top: 8px;
    background-color: #fff;
    border: 1px solid #e3e3e3;
    color: #3e3e3e !important;
    font-size: 1em !important;
    font-weight: normal !important;
    display: inline-block;
}
