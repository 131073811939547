.list-inline>li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
}

.list-inline {
    margin-left: -5px;
    display: inline-block;
}

.text-capitalize     { text-transform: capitalize; }

.initialism, .text-uppercase {
    text-transform: uppercase;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    line-height: 1.1;
}

.h1, .h2, .h3, h1, h2, h3 {
    margin-top: 17px;
    margin-bottom: 8.5px;
}

blockquote {
    padding: 8.5px 17px;
    margin: 0 0 17px;
    font-size: 15px;
    border-left: 5px solid #eee;
}