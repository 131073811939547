.page-fix-form-wizard-container {
    position: relative;

    .menu-container {
        overflow: hidden;
        font-size: 14px;
        margin-bottom: 0;
        a {
            &:focus,
            &:hover {
                text-decoration: none;
            }
        }

        & > li {
            flex: 1;
            border-bottom: 2px solid $grey-color-4;

            &:hover {
                background-color: $light-grey;
            }

            &:first-child {
                border-top-left-radius: 6px;
            }

            &:last-child {
                display: flex;
                flex: 0 1 55px;
                justify-content: center;
                border-top-right-radius: 6px;

                button {
                    align-self: center;
                    width: 35px;
                    height: 35px;
                    text-decoration: none;
                    padding: 0;

                    span {
                        display: block;
                        margin: 0 auto;
                        height: 15px;
                        width: 15px;

                        &.fa-2x {
                            font-size: 20px;
                            line-height: 0.9;
                        }
                    }
                }
            }
            &:last-child:hover {
                background-color: transparent;
            }

            &.active-underline {
                border-bottom: 2px solid $link-color;
            }
        }

        .close-button:hover {
            border-radius: 50%;
            background-color: $light-grey;
        }
    }

    .footer {
        background-color: $blue-color-2;
    }

    .warning {
        color: $secondary-15;
    }

    span.fa-question-circle {
        margin-left: 5px;
    }

    form-field-errors {
        .invalid-feedback {
            margin-top: 10px;
        }
    }
}
