@import '@monsido/colors/dist/colors.scss';

.mon-chart-dashboard {
  .doughnut {
    float: none;
    max-width: none;

    .chart.chart-doughnut {
      max-height: 200px !important;
      max-width: 200px !important;
      margin: auto;
    }
  }

  .card-container {
    padding-top: 60px;
    padding-bottom: 10px;

    .list-group {
      .list-group-item {
        border: none;

        .card-count {
          font-size: 27px;
        }
      }
    }
  }
  .text-grey {
    color: $secondary-4;
  }
}
