@import "@monsido/colors/dist/colors";

.accessibility-mode { 
    .text-success {
        color: $secondary-16;
    }
    
    .text-link {
        color: $accessibility-link-color !important;
    }

}
