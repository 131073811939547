.accessibility-mode {


    .text-link,
    a {
        color: $accessibility-link-color;
        &:hover {
            color: $accessibility-link-color;
        }
    }
    /* .text-link,
    a {
        color: $accessibility-link-color !important;
        &:hover {
            color: $accessibility-link-color !important;
        }
    } */

    .text-grey,
    .link-tooltip {
        color: $accessibility-grey-color;
    }

    .text-blue-1 {
        color: $blue-color-1;
    }

    .text-grey-1 {
        color: $grey-color-1;
    }

    .border-color-grey-3 {
        border-color: $grey-color-3;
    }

    .text-light-green {
        color: $accessibility-light-green;
    }

    .bg-monsido-green {
        background-color: $accessibility-theme-base-color;
    }

    .label {
        font-size: 100%;
    }

    .label-container .label .label-text {
        font-size: 1em;
    }

    .card-header .fa {
        color: $accessibility-link-color;
        border-color: $accessibility-link-color;
    }
}
