.page-details-dashboard-overview {
    height: 100%;

    .title {
        color: #848e9e;
    }

    .content {
        color: $color-page-details-link;
    }
}
