.statistics-visits-progress-bar {
    width: 100%;

    .progress {
        margin-bottom: 0;
        margin-top: 5px;
    }

    .visits-percentage,
    .visits-number,
    .progress-xxs {
        display: inline-block;
    }

    .visits-number {
        width: 20%;
    }

    .visits-percentage {
        color: #bcbcbc;
    }

    .progress-xxs {
        width: 50%;
    }

    .progress-xxs .progress,
    .progress-xxs.progress {
        height: 5px;
    }

    .progress.progress-square,
    .progress-square .progress {
        border-radius: 0;
    }

    .progress-bar {
        background-color: #50a6fb;
    }
}
