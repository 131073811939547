#domain-dashboard {
    .card {
        margin-bottom: 0;
    }

    .list-inline {
        margin-left: 0;
    }

    .domain-chart-footer {
        border-top: 1px solid #cecece;
        width: 100%;
        clear: both;
    }

    .doughnut canvas {
        max-width: 150px;
    }

    @mixin domain-dashboard-panel-variant($border, $heading-text-color, $heading-bg-color, $heading-border){
        border-color: $border;

        & > a > .card-header {
            color: $heading-text-color;
            background-color: $heading-bg-color;
            border-color: $heading-border;

            + .panel-collapse > .card-body {
                border-top-color: $border;
            }
            .badge {
                color: $heading-bg-color;
                background-color: $heading-text-color;
            }
        }
    }

    .panel-monsido-card {
        @include domain-dashboard-panel-variant(transparent, $gray-dark, #FFFFFF, $panel-default-border);
    }
}
