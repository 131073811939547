.accessibility-mode { 
  .pagination li {
    &.active a, &.active button {
      background-color: $accessibility-link-color;
      color: #ffffff;
    }
  
    &:not(.active) a, &:not(.active) button {
      color: $accessibility-link-color;
  
      &:hover {
        color: $accessibility-link-color;
      }
    }
  
    & > button {
      position: relative;
      float: left;
      padding: 6px 12px;
      margin-left: -1px;
      line-height: 1.42857143;
      color: #229bf9;
      text-decoration: none;
      background-color: #fff;
      border: 1px solid #ddd;
    }
  
    &:not(.disabled) > button:hover {
        background-color: #eeeeee;
        border-color: #ddd;
      }
  
    &.disabled > button {
      cursor: not-allowed;
      background-color: #fff;
      border-color: #ddd;
      color: #777777;
    }
  
    &:first-child > button {
      margin-left: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child > button {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

}
