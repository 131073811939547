.spelling-play-container {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .misspelling-word {
    user-select: text;
  }

  .word-meta {
    background-color: #f8f8f8;
  }

  .is-misspelling {
    background-color: #fbe0b1;
  }

  .dictionary {
    background-color: #4ccc93;
    padding: 12px 16px;
  }

  .dictionary-all {
    background-color: #57b2b7;
  }

  .is-misspelling,
  .is-misspelling:hover {
    color: #ab6d18;
  }

  .dictionary,
  .dictionary-all,
  .dictionary-all:hover,
  .dictionary:hover {
    color: #fff;
  }

  .word-nav-btn {
    color: #cecece;
  }

  .btn {
    padding: 12px;
  }
}
