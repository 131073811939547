.sidemenu-search {
    background-color: #fff;
    min-height: 100%;
    overflow: hidden;

    .no-border {
        border: 0;
    }

    .no-shadow {
        box-shadow: none;
    }

    .panel-border-top-color {
        border-top-color: $grey-color-3;
    }
}
