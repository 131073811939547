.form-user-domains {
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  & .spinner {
    width: 100%;
  }
}

.form-user-domains-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100% ;
}