@import "../../../../../../../src/client/deprecate/frontend-components/dist/components/table/search/mixins";

@import "../../../../../styles/variables.scss";

.accessibility-mode {
    .search-input {

        @include table-search-variant(#fff, $grey-color-3, #595959, #595959); // Default ...

        &.white-theme {
            @include table-search-variant(#fff, $grey-color-3, #595959, #595959);
        }

        &.grey-theme {
            @include table-search-variant(#ebebec, #ebebec, #595959, #595959);
        }
    }

}
