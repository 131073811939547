$border-color: $grey-color-3;

.quick-help-compliance-status {
    border-bottom-width: 1px;
    border-bottom-color: $border-color;
    border-bottom-style: solid;

    .compliance-status {
        @media (min-width: $screen-md-min) {
            border-left-width: 1px;
            border-left-color: $border-color;
            border-left-style: solid;
        }

        @media (max-width: $screen-sm-max) {
            border-top-width: 1px;
            border-top-color: $border-color;
            border-top-style: solid;
        }
    }
}
