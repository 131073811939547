table-search_REMOVE_LATER_IF_NOT_USED {
    .input-group {
        position: relative;
        display: table;
        border-collapse: separate;
      }
      .input-group[class*="col-"] {
        float: none;
        padding-right: 0;
        padding-left: 0;
      }
      .input-group .form-control {
        position: relative;
        z-index: 2;
        float: left;
        width: 100%;
        margin-bottom: 0;
      }
      .input-group .form-control:focus {
        z-index: 3;
      }
      .input-group-lg > .form-control,
      .input-group-lg > .input-group-addon,
      .input-group-lg > .input-group-btn > .btn {
        height: 42px;
        padding: 10px 16px;
        font-size: 15px;
        line-height: 1.3333333;
        border-radius: 6px;
      }
      select.input-group-lg > .form-control,
      select.input-group-lg > .input-group-addon,
      select.input-group-lg > .input-group-btn > .btn {
        height: 42px;
        line-height: 42px;
      }
      textarea.input-group-lg > .form-control,
      textarea.input-group-lg > .input-group-addon,
      textarea.input-group-lg > .input-group-btn > .btn,
      select[multiple].input-group-lg > .form-control,
      select[multiple].input-group-lg > .input-group-addon,
      select[multiple].input-group-lg > .input-group-btn > .btn {
        height: auto;
      }
      .input-group-sm > .form-control,
      .input-group-sm > .input-group-addon,
      .input-group-sm > .input-group-btn > .btn {
        height: 28px;
        padding: 5px 10px;
        font-size: 11px;
        line-height: 1.5;
        border-radius: 3px;
      }
      select.input-group-sm > .form-control,
      select.input-group-sm > .input-group-addon,
      select.input-group-sm > .input-group-btn > .btn {
        height: 28px;
        line-height: 28px;
      }
      textarea.input-group-sm > .form-control,
      textarea.input-group-sm > .input-group-addon,
      textarea.input-group-sm > .input-group-btn > .btn,
      select[multiple].input-group-sm > .form-control,
      select[multiple].input-group-sm > .input-group-addon,
      select[multiple].input-group-sm > .input-group-btn > .btn {
        height: auto;
      }
      .input-group-addon,
      .input-group-btn,
      .input-group .form-control {
        display: table-cell;
      }
      .input-group-addon:not(:first-child):not(:last-child),
      .input-group-btn:not(:first-child):not(:last-child),
      .input-group .form-control:not(:first-child):not(:last-child) {
        border-radius: 0;
      }
      .input-group-addon,
      .input-group-btn {
        width: 1%;
        white-space: nowrap;
        vertical-align: middle;
      }
      .input-group-addon {
        padding: 6px 12px;
        font-size: 12px;
        font-weight: 400;
        line-height: 1;
        color: #555555;
        text-align: center;
        background-color: #eeeeee;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
      .input-group-addon.input-sm {
        padding: 5px 10px;
        font-size: 11px;
        border-radius: 3px;
      }
      .input-group-addon.input-lg {
        padding: 10px 16px;
        font-size: 15px;
        border-radius: 6px;
      }
      .input-group-addon input[type="radio"],
      .input-group-addon input[type="checkbox"] {
        margin-top: 0;
      }
      .input-group .form-control:first-child,
      .input-group-addon:first-child,
      .input-group-btn:first-child > .btn,
      .input-group-btn:first-child > .btn-group > .btn,
      .input-group-btn:first-child > .dropdown-toggle,
      .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
      .input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      .input-group-addon:first-child {
        border-right: 0;
      }
      .input-group .form-control:last-child,
      .input-group-addon:last-child,
      .input-group-btn:last-child > .btn,
      .input-group-btn:last-child > .btn-group > .btn,
      .input-group-btn:last-child > .dropdown-toggle,
      .input-group-btn:first-child > .btn:not(:first-child),
      .input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      .input-group-addon:last-child {
        border-left: 0;
      }
      .input-group-btn {
        position: relative;
        font-size: 0;
        white-space: nowrap;
      }
      .input-group-btn > .btn {
        position: relative;
      }
      .input-group-btn > .btn + .btn {
        margin-left: -1px;
      }
      .input-group-btn > .btn:hover,
      .input-group-btn > .btn:focus,
      .input-group-btn > .btn:active {
        z-index: 2;
      }
      .input-group-btn:first-child > .btn,
      .input-group-btn:first-child > .btn-group {
        margin-right: -1px;
      }
      .input-group-btn:last-child > .btn,
      .input-group-btn:last-child > .btn-group {
        z-index: 2;
        margin-left: -1px;
      }
}


table-search {
    .input-group-text {
        padding: 6px 9px;
    }
}