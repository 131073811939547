@import "bootstrap/scss/_mixins.scss";
// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

//
// Color system
//
@function theme-color($key: "primary") {
	@return map-get($theme-colors, $key);
}

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;

$colors: (
	blue: $blue,
	indigo: $indigo,
	purple: $purple,
	pink: $pink,
	red: $red,
	orange: $orange,
	yellow: $yellow,
	green: $green,
	teal: $teal,
	cyan: $cyan,
	white: $white,
	gray: $gray-600,
	gray-dark: $gray-800,
);

$primary: $blue;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

$theme-colors: (
	primary: $primary,
	secondary: $secondary,
	success: $success,
	info: $info,
	warning: $warning,
	danger: $danger,
	light: $light,
	dark: $dark,
);

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 150;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-900;
$yiq-text-light: $white;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true;
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-hover-media-query: false; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes: true;
$enable-print-styles: true;

// Body
//
// Settings for the `<body>` element.

$body-bg: $white;
$body-color: $gray-900;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base: 12px; // Assumes the browser default, typically `16px`
$font-size-lg: (
	$font-size-base * 1.25
);
$font-size-sm: (
	$font-size-base * 0.875
);

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;

$line-height-base: 1.5;

$small-font-size: 80%;

$text-muted: $gray-600;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg: 1.5;
$line-height-sm: 1.5;

$border-width: 1px;
$border-color: $gray-300;

$border-radius: 4px;
$border-radius-lg: 4.8px;
$border-radius-sm: 3.2px;

$box-shadow-sm: 0 2px 4px fade($black, 7.5%);
$box-shadow: 0 8px 16px fade($black, 15%);
$box-shadow-lg: 0 16px 48px fade($black, 17.5%);

$component-active-color: $white;
$component-active-bg: theme-color($primary);

$caret-width: 0.3em;

$transition-base: all 0.2s ease-in-out;
$transition-fade: opacity 0.15s linear;
$transition-collapse: height 0.35s ease;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 48;
$grid-gutter-width: 30px;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 4.5px;
$input-btn-padding-x: 9px;
$input-btn-line-height: $line-height-base;

$input-btn-focus-width: 2px;
$input-btn-focus-color: fade($blue, 25%);
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm: 3px;
$input-btn-padding-x-sm: 6px;
$input-btn-line-height-sm: $line-height-sm;

$input-btn-padding-y-lg: 6px;
$input-btn-padding-x-lg: 12px;
$input-btn-line-height-lg: $line-height-lg;

$input-btn-border-width: $border-width;

// Tooltips

$tooltip-font-size: $font-size-sm;
$tooltip-max-width: 200px;
$tooltip-color: $white;
$tooltip-bg: $black;
$tooltip-border-radius: $border-radius;
$tooltip-opacity: 0.9;
$tooltip-padding-y: 4px;
$tooltip-padding-x: 8px;
$tooltip-margin: 0;

$tooltip-arrow-width: 12.8px;
$tooltip-arrow-height: 6.4px;
$tooltip-arrow-color: $tooltip-bg;

// Forms

$label-margin-bottom: 8px;

$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-line-height: $input-btn-line-height;

$input-padding-y-sm: $input-btn-padding-y-sm;
$input-padding-x-sm: $input-btn-padding-x-sm;
$input-line-height-sm: $input-btn-line-height-sm;

$input-padding-y-lg: $input-btn-padding-y-lg;
$input-padding-x-lg: $input-btn-padding-x-lg;
$input-line-height-lg: $input-btn-line-height-lg;

$input-bg: $white;
$input-disabled-bg: $gray-200;

$input-color: $gray-700;
$input-border-color: $gray-400;
$input-border-width: $input-btn-border-width;
$input-box-shadow: inset 0 1px 1px fade($black, 7.5%);

$input-border-radius: $border-radius;
$input-border-radius-lg: $border-radius-lg;
$input-border-radius-sm: $border-radius-sm;

$input-focus-bg: $input-bg;

$component-active-bg: #999;

$input-focus-border-color: tint-color($component-active-bg, 25%);
$input-focus-color: $input-color;
$input-focus-width: $input-btn-focus-width;
$input-focus-box-shadow: $input-btn-focus-box-shadow;

$input-placeholder-color: $gray-600;
$input-plaintext-color: $body-color;

$input-height-border: (
	$input-border-width * 2
);

	$input-height-inner: ($font-size-base * $input-btn-line-height + $input-btn-padding-y * 2
);
$input-height: calc(#{$input-height-inner} + #{$input-height-border});
$input-height-inner-sm: ($font-size-sm * $input-btn-line-height-sm + $input-btn-padding-y-sm * 2);
$input-height-sm: calc(#{$input-height-inner-sm} + #{$input-height-border});
$input-height-inner-lg: (
	$font-size-lg * $input-btn-line-height-lg + $input-btn-padding-y-lg * 2
);
$input-height-lg: calc(#{$input-height-inner-lg} + #{$input-height-border});
$input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
$form-text-margin-top: 4px;
$form-check-input-gutter: 20px;
$form-check-input-margin-y: 5.28px;
$form-check-input-margin-x: 4px;
$form-check-inline-margin-x: 12px;
$form-check-inline-input-margin-x: 5px;
$form-group-margin-bottom: 16px;
$input-group-addon-color: $input-color;
$input-group-addon-bg: $gray-200;
$input-group-addon-border-color: $input-border-color;
$custom-forms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
$custom-control-gutter: 24px;
$custom-control-spacer-x: 16px;
$custom-control-indicator-size: 16px;
$custom-control-indicator-bg: $gray-300;
$custom-control-indicator-bg-size: 50% 50%;
$custom-control-indicator-box-shadow: inset 0 4px 4px fade($black, 10%);
$custom-control-indicator-disabled-bg: $gray-200;
$custom-control-label-disabled-color: $gray-600;
$custom-control-indicator-checked-color: $component-active-color;
$custom-control-indicator-checked-bg: $component-active-bg;
$custom-control-indicator-checked-disabled-bg: fade(theme-color(primary), 50%);
$custom-control-indicator-checked-box-shadow: none;
$custom-control-indicator-focus-box-shadow: 0 0 0 1px $body-bg, $input-btn-focus-box-shadow;
$custom-control-indicator-active-color: $component-active-color;
$custom-control-indicator-active-bg: tint-color($component-active-bg, 35%);
$custom-control-indicator-active-box-shadow: none;
$custom-checkbox-indicator-border-radius: $border-radius;
$custom-checkbox-indicator-icon-checked: url("data:image/svg+xml;charset=utf8,%s%s%s"), "%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='", replace("${custom-control-indicator-checked-color}", "#", "%23"), "' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E";
$custom-checkbox-indicator-indeterminate-bg: $component-active-bg;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color;
$custom-checkbox-indicator-icon-indeterminate: url("data:image/svg+xml;charset=utf8,%s%s%s"), "%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='", replace("${custom-checkbox-indicator-indeterminate-color}", "#", "%23"), "' d='M0 2h4'/%3E%3C/svg%3E";
$custom-checkbox-indicator-indeterminate-box-shadow: none;
$custom-radio-indicator-border-radius: 50%;
$custom-radio-indicator-icon-checked: url("data:image/svg+xml;charset=utf8,%s%s%s"), "%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='", replace("${custom-control-indicator-checked-color}", "#", "%23"), "'/%3E%3C/svg%3E";
$custom-select-padding-y: 6px;
$custom-select-padding-x: 12px;
$custom-select-height: $input-height;
$custom-select-indicator-padding: 16px; // Extra padding to account for the presence of the background-image based indicator
$custom-select-line-height: $input-btn-line-height;
$custom-select-color: $input-color;
$custom-select-disabled-color: $gray-600;
$custom-select-bg: $input-bg;
$custom-select-disabled-bg: $gray-200;
$custom-select-bg-size: 8px 10px; // In pixels because image dimensions
$custom-select-indicator-color: $gray-800;
$custom-select-indicator: url("data:image/svg+xml;charset=utf8,%s%s%s"), "%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='", replace("${custom-select-indicator-color}", "#", "%23"), "' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E";
$custom-select-border-width: $input-btn-border-width;
$custom-select-border-color: $input-border-color;
$custom-select-border-radius: $border-radius;
$custom-select-box-shadow: inset 0 1px 2px fade($black, 7.5%);
$custom-select-focus-border-color: $input-focus-border-color;
$custom-select-focus-width: $input-btn-focus-width;
$custom-select-focus-box-shadow: 0 0 0 $custom-select-focus-width fade($custom-select-focus-border-color, 50%);
$custom-select-font-size-sm: 75%;
$custom-select-height-sm: $input-height-sm;
$custom-select-font-size-lg: 125%;
$custom-select-height-lg: $input-height-lg;
$custom-range-track-width: 100%;
$custom-range-track-height: 8px;
$custom-range-track-cursor: pointer;
$custom-range-track-bg: $gray-300;
$custom-range-track-border-radius: 16px;
$custom-range-track-box-shadow: inset 0 4px 4px fade($black, 10%);
$custom-range-thumb-width: 16px;
$custom-range-thumb-height: $custom-range-thumb-width;
$custom-range-thumb-bg: $component-active-bg;
$custom-range-thumb-border: 0;
$custom-range-thumb-border-radius: 16px;
$custom-range-thumb-box-shadow: 0 1.6 4px fade($black, 10%);
$custom-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg, $input-btn-focus-box-shadow;
$custom-range-thumb-focus-box-shadow-width: $input-btn-focus-width; // For focus box shadow issue in IE/Edge
$custom-range-thumb-active-bg: tint-color($component-active-bg, 35%);
$custom-file-height: $input-height;
$custom-file-height-inner: $input-height-inner;
$custom-file-focus-border-color: $input-focus-border-color;
$custom-file-focus-box-shadow: $input-btn-focus-box-shadow;
$custom-file-disabled-bg: $input-disabled-bg;
$custom-file-padding-y: $input-btn-padding-y;
$custom-file-padding-x: $input-btn-padding-x;
$custom-file-line-height: $input-btn-line-height;
$custom-file-color: $input-color;
$custom-file-bg: $input-bg;
$custom-file-border-width: $input-btn-border-width;
$custom-file-border-color: $input-border-color;
$custom-file-border-radius: $input-border-radius;
$custom-file-box-shadow: $input-box-shadow;
$custom-file-button-color: $custom-file-color;
$custom-file-button-bg: $input-group-addon-bg;
$custom-file-text: en "Browse";
// Form validation
$form-feedback-margin-top: $form-text-margin-top;
$form-feedback-font-size: $small-font-size;
$form-feedback-valid-color: $success;
$form-feedback-invalid-color: $danger;