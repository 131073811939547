.document-details-dashboard {
    .mon-panel-header {
        hr {
            color: transparent;
            border-color: transparent;
            margin: 0;
            border-width: 0;
        }
    }

    .panel {
        box-shadow: none;
        border-color: $panel-default-border;
        border-width: 1px;
    }

    .module-title {
        height: 27px;
    }

    .border-b-none {
        border-bottom: 0;
    }

    .module-panels {
        min-height: 303px;
        margin-bottom: 0;
        margin-top: -1px;
    }

    .on-hover:hover {
        background-color: #f6f9fb;
        .card-header {
            background-color: #f6f9fb;
        }
    }

    .readability-score {
        border: 1px solid #e3e3e3;
        border-radius: 5px;
        padding: 0 8px;
        text-align: center;
        display: inline-block;
        margin-right: 3px;
    }

    .merge-t-border {
        margin-top: -3px;
    }

    .page-dashboard-doughnut-container {
        .doughnut {
            max-width: 130px;
            max-height: 130px;
        }
    }

    .language-editable {
        border-bottom-color: #0088cc;
        border-bottom-style: dashed;
        border-bottom-width: 1px;
    }

    /* Medium devices */
    @media (min-width: $screen-md-min) {
        .module-panels {
            margin-right: -1px;
        }
    }

    @media (min-width: $screen-lg-min) {
        .dashboard-grid-container {
            & > .col-lg-24:nth-child(2n) {
                padding-left: 0 !important;
            }
            & > .col-lg-24:nth-child(2n + 1) {
                padding-right: 0 !important;
            }
        }
    }

    .merge-t-1 {
        margin-top: -1px;
    }
}
