/*!
 * Bootstrap (https://getbootstrap.com/)
 * Less file dependencies we want from the bootstrap library
 */

/* Include functions first (so you can manipulate colors, SVGs, calc, etc) */
@import "bootstrap/scss/_functions";

/* Core variables and mixins */
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_variables-dark.scss";
@import "bootstrap/scss/_maps.scss";
@import "bootstrap/scss/_mixins.scss";

/* Reset and dependencies */
@import "bootstrap/scss/_reboot";
/* @import "bootstrap/scss/_normalize.scss"; */
/* @import "bootstrap/scss/_print.scss"; */

/* Core CSS */
/* @import "bootstrap/scss/_scaffolding.scss"; */
@import "bootstrap/scss/_type.scss";
/* @import "bootstrap/scss/_code.scss"; */
@import "bootstrap/scss/_tables.scss";
@import "bootstrap/scss/_buttons.scss";

/* Components */
@import "bootstrap/scss/_forms.scss";
/* @import "bootstrap/scss/_input-groups.scss"; */
/* @import "bootstrap/scss/_component-animations.scss"; */
@import "bootstrap/scss/_dropdown.scss";
@import "bootstrap/scss/_button-group.scss";
@import "bootstrap/scss/_nav.scss";
@import "bootstrap/scss/_navbar.scss";
@import "bootstrap/scss/_breadcrumb.scss";
@import "bootstrap/scss/_pagination.scss";
/* @import "bootstrap/scss/_pager.scss"; */
/* @import "bootstrap/scss/_labels.scss"; */
@import "bootstrap/scss/_badge.scss";
/* @import "bootstrap/scss/_jumbotron.scss"; */
/* @import "bootstrap/scss/_thumbnails.scss"; */
@import "bootstrap/scss/_alert.scss";
/* @import "bootstrap/scss/_progress-bars.scss"; */
@import "bootstrap/scss/_progress.scss";
/* @import "bootstrap/scss/_media.scss"; */
@import "bootstrap/scss/_list-group.scss";
/* @import "bootstrap/scss/_panels.scss"; */
/* @import "bootstrap/scss/_responsive-embed.scss"; */
/* @import "bootstrap/scss/_wells.scss"; */
@import "bootstrap/scss/_close.scss";

/* Components w/ JavaScript */
@import "bootstrap/scss/_modal.scss";
@import "bootstrap/scss/_tooltip.scss";
@import "bootstrap/scss/_popover.scss";
@import "bootstrap/scss/_carousel.scss";

/* Utility classes */
@import "bootstrap/scss/_utilities.scss";
/* @import "bootstrap/scss/_responsive-utilities.scss"; */