.accessibility-mode { 
    .label {
        &.label-new {
            background-color: $accessibility-link-color;
        }
        &.label-default {
            background-color: #575757;
        }
        &.label-dodger-blue {
            background-color: $accessibility-link-color;
        }
    }

}
