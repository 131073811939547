.accessibility-error-image {
  vertical-align: middle !important;
  text-align: center;
  &.hidden {
    display: none;
  }

  img {
    max-height: 60px !important;
  }
}
