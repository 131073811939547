$radius: 50%;
$icon-circle-size: 29px;

.icon-circle {
  text-align: center;
  border: 2px solid;
  display: inline-block;
  position: relative;
  width: $icon-circle-size;
  height: $icon-circle-size;

  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;

  .icon {
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%)
  }

}