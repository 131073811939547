.readability-pages-with-score {
  .progress {
    margin-bottom: 0;
    margin-top: 10px;
  }
  .progress-bar {
    box-shadow: none;
    background-color: #239cf9;
  }

  .table-container-paginate {
    padding: 0 15px;
  }
}
