.btn-group-justified {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;
    > .btn,
    > .btn-group {
      display: table-cell;
      float: none;
      width: 1%;
    }
    > .btn-group .btn {
      width: 100%;
    }

    > .btn-group .dropdown-menu {
      left: auto;
    }
  }