$image-thumbnail-size: 48px;

.page-performance-audit-display {
    .table {
        .table-column-thumbnail {
            width: $image-thumbnail-size;
            padding: 0;
        }
    }

    .page-performance-thumbnail {
        object-fit: cover;
        width: $image-thumbnail-size;
        height: $image-thumbnail-size;
        display: block;
    }
}