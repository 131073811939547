.label-dialog{
  min-height: 320px;
}

label-container-selector {
  .label-selector {
    overflow-y: auto;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2;
    width: 200px;
    background-color: #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.18);
    border: 0;
    padding-left: 0;
    margin-left: -60px;
    margin-top: 1px;
    margin-bottom: 30px;

    &:focus-visible {
      outline: none;
      box-shadow: 0 0 0 1px $secondary-14;
    }

    ul {
      overflow-y: auto;
      background: white;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.18);
      border: 0;
      list-style: none;
      padding-left: 0;
      text-align: left;
      margin-bottom: 0;
      max-height: 496px;

      li {
        position: relative;
        padding: 10px 12px;

        &.label-search-box {
          background: #f5f5f5;
          box-shadow: 1px 1px rgba(0, 0, 0, 0.08) inset, -1px -1px rgba(0, 0, 0, 0.08) inset;

          input {
            width: 100%;
            background-color: white;
            padding: 0 .4em;
            line-height: 1.5em;
            border: 1px solid #ccc;

            &:focus-visible {
              outline: none;
              box-shadow: 0 0 0 1px $secondary-14;
            }
          }
        }

        &:hover {
          background: #f5f5f5;
          color: #424242;
          cursor: pointer;

          .indicators.active {
            .selected {
              display: none;
            }
            .remove {
              display: initial;
            }
          }
        }

        &:focus-visible {
          outline: none;
          box-shadow: 0 0 0 1px $secondary-14;
        }
      }

      .indicators {
        opacity: 0;
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-right: 5px;
        color: #555557;

        .remove {
          display: none;
        }

        &.active {
          opacity: 1;
        }
      }

      .label-color-box {
        width: 15px;
        height: 15px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 0.2em;
      }

      .label-name {
        display: inline-block;
        padding-left: 7px;
        vertical-align: middle;
        color: #555557;
      }
    }
  }
}
