.side-menu-page-selector-domain-panel {
    background-color: #f6f9fb;
    margin-bottom: 4px;
    transition: all 0.1s linear;

    .min-image-space {
        min-width: 28px
    }

    &:hover,
    &:focus,
    &:focus-within,
    &.is-focused {
        transform: scaleX(1.05);
        background-color: #e2e4e6;
    }
}