/**
 * These are the variables used throughout the application. This is where
 * overwrites that are not specific to components should be maintained.
 */

/**
 * Typography-related.
 */
$sansFontFamily: 'Roboto', sans-serif;

/**
 * Colors
 */
$monsido-green: #57b2b7;
$light-green: #86d08a;
$light-black: #505050;
$light-grey: #EAEAEB;
$light-red: #e66468;

/**
 * Menus
 */
$page-content-padding: 3px;

$sidebar-width: 265px;
$sidebar-background-color: #555557;

/**
 * Wizard
 */
$wz-color-current: $monsido-green;
$wz-color-editing: $monsido-green;

/**
 * Domain picker
 */
$domain-picker-icon-size: 30px;
$domain-picker-background-color: #F2F2F2;
$domain-picker-item-padding-horziontal: 15px;
$domain-picker-item-padding-vertical: 10px;
$domain-picker-icon-width: $domain-picker-icon-size + $domain-picker-item-padding-horziontal;

/**
 * Spelling
 */
$confirmed-misspelling-color: #fcb785;
$potential-misspelling-color: #9ac6df;

 /**
 *  Text
 */
$grey-text: #A6A6A6;
$dark-grey: #8d8d8d;
$grey-text-ada: #595959;
$text-warning: #F0AD4E;
$text-gray-small: #777777;

/**
* Inner
*/

$inner-extra-large: 60px;
$inner-large: 30px;
$inner: 20px;
$inner-medium: 15px;
$inner-small: 10px;
$inner-between-small-and-mini: 8px;
$inner-mini: 5px;
$inner-smurf: 3px;
$inner-none: 0;
$image-path: "/images";
