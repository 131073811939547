.stats-most-visits-pages-panel {
  position: relative;

  .card-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .page-url-icon {
      color: $main-1;
  }
}
