@import '@monsido/colors/dist/colors.scss';

.accessibility-dashboard-notifications {
  padding-top: 40px;

  .list-group {
    .list-group-item {
      border-color: transparent;
      @include set-border-radius(0);

      .notification-count {
        font-size: 27px;
        small {
          font-size: 68%;
        }
      }
    }
  }

  .text-grey {
    color: $secondary-4;
  }
}
