#domain-settings-data-policy {
    .regionBar {
        height: 40px;
        width: 100%;
        background-color: $secondary-1;
        color: $secondary-4;
        padding: 7px 0 0 35px;
        font-size: 20px;
        span {
            margin: 0 10px;
            font-size: 12px;
            vertical-align: middle;
            color: #229bf9;
        }
    }
    .table-filters {
        margin-top: 15px;
    }
    .table-container {
        padding: 0;
    }
    .form-field-material-switch {
        width: 55px;
        padding-top: 12px;
    }
    .type-cell {
        width: 7%;
    }
    .v-align-middle {
        vertical-align: middle;
    }
    td.disabled {
        color: $text-grey !important;
        .privacy-info-type-icon span {
            color: $text-grey !important;
            border-color: $text-grey!important;
        }
    }

    .form-field-material-switch {
        padding-left: 0;
        margin-left: 0;

        .col-48 {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
