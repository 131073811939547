#page-details-page-performance {
    .page-details-page-performance-header.page-details-section-header {
        margin-right: -15px;

        page-header .page-header {
            height: $page-details-section-header-height - 1;
            padding-left: 0;
            padding-right: 0;
            &.row {
                margin-left: -15px;
                margin-right: -15px;
            }
        }
    }

    .min-height {
        min-height: 95%;
    }
}
.page-details-page-performance-header {
    .page-performance-actions-group {
        margin-top: -18px;

        .page-performance-rescan-btn {
            width: 100px;
            height: 73px;
            white-space: normal;
            padding: 15px 0;
        }
    }
}
