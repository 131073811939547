.cursor-not-allowed {
    cursor: not-allowed !important;
}

.cursor-default {
    cursor: default !important;
}

.cursor-auto {
    cursor: auto !important;
}
