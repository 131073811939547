/* ------------------------------------------- */
/* Mixins
/* ------------------------------------------- */
/* ------------------------------------------- */
/* Toggle Fade
/* ------------------------------------------- */
.toggle {
    -webkit-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -moz-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -ms-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    /* easeOutQuad */
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -moz-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -ms-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    /* easeOutQuad */
}
.toggle.ng-enter {
    opacity: 0;
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
}
.toggle.ng-enter-active {
    opacity: 1;
}
.toggle.ng-leave {
    opacity: 1;
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
}
.toggle.ng-leave-active {
    opacity: 0;
}
.toggle.ng-hide-add {
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 1;
}
.toggle.ng-hide-add.ng-hide-add-active {
    opacity: 0;
}
.toggle.ng-hide-remove {
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    display: block !important;
    opacity: 0;
}
.toggle.ng-hide-remove.ng-hide-remove-active {
    opacity: 1;
}

/* ------------------------------------------- */
/* Slide Top
/* ------------------------------------------- */
.slide-top {
    -webkit-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -moz-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -ms-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    /* easeOutQuad */
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -moz-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -ms-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    /* easeOutQuad */
}
.slide-top.ng-enter {
    transform: translateY(60px);
    -ms-transform: translateY(60px);
    -webkit-transform: translateY(60px);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 0;
}
.slide-top.ng-enter-active {
    transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    opacity: 1;
}
.slide-top.ng-leave {
    transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 1;
}
.slide-top.ng-leave-active {
    transform: translateY(60px);
    -ms-transform: translateY(60px);
    -webkit-transform: translateY(60px);
    opacity: 0;
}
.slide-top.ng-hide-add {
    transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 1;
}
.slide-top.ng-hide-add.ng-hide-add-active {
    transform: translateY(60px);
    -ms-transform: translateY(60px);
    -webkit-transform: translateY(60px);
    opacity: 0;
}
.slide-top.ng-hide-remove {
    transform: translateY(60px);
    -ms-transform: translateY(60px);
    -webkit-transform: translateY(60px);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    display: block !important;
    opacity: 0;
}
.slide-top.ng-hide-remove.ng-hide-remove-active {
    transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    opacity: 1;
}

/* ------------------------------------------- */
/* Slide Rigth
/* ------------------------------------------- */
.slide-right {
    -webkit-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -moz-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -ms-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    /* easeOutQuad */
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -moz-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -ms-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    /* easeOutQuad */
}
.slide-right.ng-enter {
    transform: translateX(60px);
    -ms-transform: translateX(60px);
    -webkit-transform: translateX(60px);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 0;
}
.slide-right.ng-enter-active {
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    opacity: 1;
}
.slide-right.ng-leave {
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 1;
}
.slide-right.ng-leave-active {
    transform: translateX(60px);
    -ms-transform: translateX(60px);
    -webkit-transform: translateX(60px);
    opacity: 0;
}
.slide-right.ng-hide-add {
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 1;
}
.slide-right.ng-hide-add.ng-hide-add-active {
    transform: translateX(60px);
    -ms-transform: translateX(60px);
    -webkit-transform: translateX(60px);
    opacity: 0;
}
.slide-right.ng-hide-remove {
    transform: translateX(60px);
    -ms-transform: translateX(60px);
    -webkit-transform: translateX(60px);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    display: block !important;
    opacity: 0;
}
.slide-right.ng-hide-remove.ng-hide-remove-active {
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    opacity: 1;
}

/* ------------------------------------------- */
/* Slide Left
/* ------------------------------------------- */
.slide-left {
    -webkit-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -moz-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -ms-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    /* easeOutQuad */
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -moz-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -ms-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    /* easeOutQuad */
}
.slide-left.ng-enter {
    transform: translateX(-60px);
    -ms-transform: translateX(-60px);
    -webkit-transform: translateX(-60px);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 0;
}
.slide-left.ng-enter-active {
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    opacity: 1;
}
.slide-left.ng-leave {
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 1;
}
.slide-left.ng-leave-active {
    transform: translateX(-60px);
    -ms-transform: translateX(-60px);
    -webkit-transform: translateX(-60px);
    opacity: 0;
}
.slide-left.ng-hide-add {
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 1;
}
.slide-left.ng-hide-add.ng-hide-add-active {
    transform: translateX(-60px);
    -ms-transform: translateX(-60px);
    -webkit-transform: translateX(-60px);
    opacity: 0;
}
.slide-left.ng-hide-remove {
    transform: translateX(-60px);
    -ms-transform: translateX(-60px);
    -webkit-transform: translateX(-60px);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    display: block !important;
    opacity: 0;
}
.slide-left.ng-hide-remove.ng-hide-remove-active {
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    opacity: 1;
}

/* ------------------------------------------- */
/* Slide Down
/* ------------------------------------------- */
.slide-down {
    -webkit-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -moz-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -ms-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    /* easeOutQuad */
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -moz-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -ms-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    /* easeOutQuad */
}
.slide-down.ng-enter {
    transform: translateY(-60px);
    -ms-transform: translateY(-60px);
    -webkit-transform: translateY(-60px);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 0;
}
.slide-down.ng-enter-active {
    transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    opacity: 1;
}
.slide-down.ng-leave {
    transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 1;
}
.slide-down.ng-leave-active {
    transform: translateY(-60px);
    -ms-transform: translateY(-60px);
    -webkit-transform: translateY(-60px);
    opacity: 0;
}
.slide-down.ng-hide-add {
    transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 1;
}
.slide-down.ng-hide-add.ng-hide-add-active {
    transform: translateY(-60px);
    -ms-transform: translateY(-60px);
    -webkit-transform: translateY(-60px);
    opacity: 0;
}
.slide-down.ng-hide-remove {
    transform: translateY(-60px);
    -ms-transform: translateY(-60px);
    -webkit-transform: translateY(-60px);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    display: block !important;
    opacity: 0;
}
.slide-down.ng-hide-remove.ng-hide-remove-active {
    transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    opacity: 1;
}

/* ------------------------------------------- */
/* Bouncy Slide Top
/* ------------------------------------------- */
.bouncy-slide-top {
    -webkit-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1);
    /* older webkit */
    -webkit-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -moz-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -ms-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -o-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    /* easeOutBack */
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1);
    /* older webkit */
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    /* custom */
}
.bouncy-slide-top.ng-enter {
    transform: translateY(240px);
    -ms-transform: translateY(240px);
    -webkit-transform: translateY(240px);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 0;
}
.bouncy-slide-top.ng-enter-active {
    transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    opacity: 1;
}
.bouncy-slide-top.ng-leave {
    transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 1;
}
.bouncy-slide-top.ng-leave-active {
    transform: translateY(240px);
    -ms-transform: translateY(240px);
    -webkit-transform: translateY(240px);
    opacity: 0;
}
.bouncy-slide-top.ng-hide-add {
    transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 1;
}
.bouncy-slide-top.ng-hide-add.ng-hide-add-active {
    transform: translateY(240px);
    -ms-transform: translateY(240px);
    -webkit-transform: translateY(240px);
    opacity: 0;
}
.bouncy-slide-top.ng-hide-remove {
    transform: translateY(240px);
    -ms-transform: translateY(240px);
    -webkit-transform: translateY(240px);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    display: block !important;
    opacity: 0;
}
.bouncy-slide-top.ng-hide-remove.ng-hide-remove-active {
    transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    opacity: 1;
}

/* ------------------------------------------- */
/* Bouncy Slide Rigth
/* ------------------------------------------- */
.bouncy-slide-right {
    -webkit-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1);
    /* older webkit */
    -webkit-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -moz-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -ms-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -o-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    /* easeOutBack */
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1);
    /* older webkit */
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    /* custom */
}
.bouncy-slide-right.ng-enter {
    transform: translateX(240px);
    -ms-transform: translateX(240px);
    -webkit-transform: translateX(240px);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 0;
}
.bouncy-slide-right.ng-enter-active {
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    opacity: 1;
}
.bouncy-slide-right.ng-leave {
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 1;
}
.bouncy-slide-right.ng-leave-active {
    transform: translateX(240px);
    -ms-transform: translateX(240px);
    -webkit-transform: translateX(240px);
    opacity: 0;
}
.bouncy-slide-right.ng-hide-add {
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 1;
}
.bouncy-slide-right.ng-hide-add.ng-hide-add-active {
    transform: translateX(240px);
    -ms-transform: translateX(240px);
    -webkit-transform: translateX(240px);
    opacity: 0;
}
.bouncy-slide-right.ng-hide-remove {
    transform: translateX(240px);
    -ms-transform: translateX(240px);
    -webkit-transform: translateX(240px);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    display: block !important;
    opacity: 0;
}
.bouncy-slide-right.ng-hide-remove.ng-hide-remove-active {
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    opacity: 1;
}

/* ------------------------------------------- */
/* Bouncy Slide Left
/* ------------------------------------------- */
.bouncy-slide-left {
    -webkit-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1);
    /* older webkit */
    -webkit-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -moz-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -ms-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -o-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    /* easeOutBack */
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1);
    /* older webkit */
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    /* custom */
}
.bouncy-slide-left.ng-enter {
    transform: translateX(-240px);
    -ms-transform: translateX(-240px);
    -webkit-transform: translateX(-240px);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 0;
}
.bouncy-slide-left.ng-enter-active {
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    opacity: 1;
}
.bouncy-slide-left.ng-leave {
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 1;
}
.bouncy-slide-left.ng-leave-active {
    transform: translateX(-240px);
    -ms-transform: translateX(-240px);
    -webkit-transform: translateX(-240px);
    opacity: 0;
}
.bouncy-slide-left.ng-hide-add {
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 1;
}
.bouncy-slide-left.ng-hide-add.ng-hide-add-active {
    transform: translateX(-240px);
    -ms-transform: translateX(-240px);
    -webkit-transform: translateX(-240px);
    opacity: 0;
}
.bouncy-slide-left.ng-hide-remove {
    transform: translateX(-240px);
    -ms-transform: translateX(-240px);
    -webkit-transform: translateX(-240px);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    display: block !important;
    opacity: 0;
}
.bouncy-slide-left.ng-hide-remove.ng-hide-remove-active {
    transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    opacity: 1;
}

/* ------------------------------------------- */
/* Bouncy Slide Down
/* ------------------------------------------- */
.bouncy-slide-down {
    -webkit-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1);
    /* older webkit */
    -webkit-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -moz-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -ms-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -o-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    /* easeOutBack */
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1);
    /* older webkit */
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    /* custom */
}
.bouncy-slide-down.ng-enter {
    transform: translateY(-240px);
    -ms-transform: translateY(-240px);
    -webkit-transform: translateY(-240px);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 0;
}
.bouncy-slide-down.ng-enter-active {
    transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    opacity: 1;
}
.bouncy-slide-down.ng-leave {
    transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 1;
}
.bouncy-slide-down.ng-leave-active {
    transform: translateY(-240px);
    -ms-transform: translateY(-240px);
    -webkit-transform: translateY(-240px);
    opacity: 0;
}
.bouncy-slide-down.ng-hide-add {
    transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 1;
}
.bouncy-slide-down.ng-hide-add.ng-hide-add-active {
    transform: translateY(-240px);
    -ms-transform: translateY(-240px);
    -webkit-transform: translateY(-240px);
    opacity: 0;
}
.bouncy-slide-down.ng-hide-remove {
    transform: translateY(-240px);
    -ms-transform: translateY(-240px);
    -webkit-transform: translateY(-240px);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    display: block !important;
    opacity: 0;
}
.bouncy-slide-down.ng-hide-remove.ng-hide-remove-active {
    transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    opacity: 1;
}

/* ------------------------------------------- */
/* Scale Fade
/* ------------------------------------------- */
.scale-fade {
    -webkit-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -moz-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -ms-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    /* easeOutQuad */
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -moz-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -ms-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    /* easeOutQuad */
}
.scale-fade.ng-enter {
    transform: scale(0.7);
    -ms-transform: scale(0.7);
    -webkit-transform: scale(0.7);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 0;
}
.scale-fade.ng-enter-active {
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 1;
}
.scale-fade.ng-leave {
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 1;
}
.scale-fade.ng-leave-active {
    transform: scale(0.7);
    -ms-transform: scale(0.7);
    -webkit-transform: scale(0.7);
    opacity: 0;
}
.scale-fade.ng-hide-add {
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 1;
}
.scale-fade.ng-hide-add.ng-hide-add-active {
    transform: scale(0.7);
    -ms-transform: scale(0.7);
    -webkit-transform: scale(0.7);
    opacity: 0;
}
.scale-fade.ng-hide-remove {
    transform: scale(0.7);
    -ms-transform: scale(0.7);
    -webkit-transform: scale(0.7);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    display: block !important;
    opacity: 0;
}
.scale-fade.ng-hide-remove.ng-hide-remove-active {
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 1;
}

/* ------------------------------------------- */
/* Spin Fade
/* ------------------------------------------- */
.spin-toggle {
    -webkit-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -moz-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -ms-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    /* easeOutQuad */
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -moz-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -ms-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    /* easeOutQuad */
}
.spin-toggle.ng-enter {
    transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 0;
}
.spin-toggle.ng-enter-active {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    opacity: 1;
}
.spin-toggle.ng-leave {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 1;
}
.spin-toggle.ng-leave-active {
    transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    opacity: 0;
}
.spin-toggle.ng-hide-add {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 1;
}
.spin-toggle.ng-hide-add.ng-hide-add-active {
    transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    opacity: 0;
}
.spin-toggle.ng-hide-remove {
    transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    display: block !important;
    opacity: 0;
}
.spin-toggle.ng-hide-remove.ng-hide-remove-active {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    opacity: 1;
}

/* ------------------------------------------- */
/* Scale in Fade
/* ------------------------------------------- */
.scale-fade-in {
    -webkit-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -moz-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -ms-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    /* easeOutQuad */
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -moz-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -ms-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    /* easeOutQuad */
}
.scale-fade-in.ng-enter {
    transform: scale(3);
    -ms-transform: scale(3);
    -webkit-transform: scale(3);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 0;
}
.scale-fade-in.ng-enter-active {
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 1;
}
.scale-fade-in.ng-leave {
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 1;
}
.scale-fade-in.ng-leave-active {
    transform: scale(3);
    -ms-transform: scale(3);
    -webkit-transform: scale(3);
    opacity: 0;
}
.scale-fade-in.ng-hide-add {
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    opacity: 1;
}
.scale-fade-in.ng-hide-add.ng-hide-add-active {
    transform: scale(3);
    -ms-transform: scale(3);
    -webkit-transform: scale(3);
    opacity: 0;
}
.scale-fade-in.ng-hide-remove {
    transform: scale(3);
    -ms-transform: scale(3);
    -webkit-transform: scale(3);
    transition-duration: 250ms;
    -webkit-transition-duration: 250ms;
    display: block !important;
    opacity: 0;
}
.scale-fade-in.ng-hide-remove.ng-hide-remove-active {
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 1;
}

/* ------------------------------------------- */
/* Bouncy Scale in Fade
/* ------------------------------------------- */
.bouncy-scale-in {
    -webkit-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1);
    /* older webkit */
    -webkit-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -moz-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -ms-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -o-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    /* easeOutBack */
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1);
    /* older webkit */
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    /* custom */
}
.bouncy-scale-in.ng-enter {
    transform: scale(3);
    -ms-transform: scale(3);
    -webkit-transform: scale(3);
    transition-duration: 450ms;
    -webkit-transition-duration: 450ms;
    opacity: 0;
}
.bouncy-scale-in.ng-enter-active {
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 1;
}
.bouncy-scale-in.ng-leave {
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transition-duration: 450ms;
    -webkit-transition-duration: 450ms;
    opacity: 1;
}
.bouncy-scale-in.ng-leave-active {
    transform: scale(3);
    -ms-transform: scale(3);
    -webkit-transform: scale(3);
    opacity: 0;
}
.bouncy-scale-in.ng-hide-add {
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transition-duration: 450ms;
    -webkit-transition-duration: 450ms;
    opacity: 1;
}
.bouncy-scale-in.ng-hide-add.ng-hide-add-active {
    transform: scale(3);
    -ms-transform: scale(3);
    -webkit-transform: scale(3);
    opacity: 0;
}
.bouncy-scale-in.ng-hide-remove {
    transform: scale(3);
    -ms-transform: scale(3);
    -webkit-transform: scale(3);
    transition-duration: 450ms;
    -webkit-transition-duration: 450ms;
    display: block !important;
    opacity: 0;
}
.bouncy-scale-in.ng-hide-remove.ng-hide-remove-active {
    transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 1;
}

/* ------------------------------------------- */
/* Flip In
/* ------------------------------------------- */
.flip-in {
    -webkit-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -moz-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -ms-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    /* easeOutQuad */
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -moz-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -ms-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -o-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    /* easeOutQuad */
}
.flip-in.ng-enter {
    transform: perspective(300px) rotateX(90deg);
    -ms-transform: perspective(300px) rotateX(90deg);
    -webkit-transform: perspective(300px) rotateX(90deg);
    transition-duration: 550ms;
    -webkit-transition-duration: 550ms;
    opacity: 0.7;
}
.flip-in.ng-enter-active {
    transform: perspective(300px) rotateX(0deg);
    -ms-transform: perspective(300px) rotateX(0deg);
    -webkit-transform: perspective(300px) rotateX(0deg);
    opacity: 1;
}
.flip-in.ng-leave {
    transform: perspective(300px) rotateX(0deg);
    -ms-transform: perspective(300px) rotateX(0deg);
    -webkit-transform: perspective(300px) rotateX(0deg);
    transition-duration: 550ms;
    -webkit-transition-duration: 550ms;
    opacity: 1;
}
.flip-in.ng-leave-active {
    transform: perspective(300px) rotateX(135deg);
    -ms-transform: perspective(300px) rotateX(135deg);
    -webkit-transform: perspective(300px) rotateX(135deg);
    opacity: 0.7;
}
.flip-in.ng-hide-add {
    transform: perspective(300px) rotateX(0deg);
    -ms-transform: perspective(300px) rotateX(0deg);
    -webkit-transform: perspective(300px) rotateX(0deg);
    transition-duration: 550ms;
    -webkit-transition-duration: 550ms;
    opacity: 1;
}
.flip-in.ng-hide-add.ng-hide-add-active {
    transform: perspective(300px) rotateX(135deg);
    -ms-transform: perspective(300px) rotateX(135deg);
    -webkit-transform: perspective(300px) rotateX(135deg);
    opacity: 0;
}
.flip-in.ng-hide-remove {
    transform: perspective(300px) rotateX(90deg);
    -ms-transform: perspective(300px) rotateX(90deg);
    -webkit-transform: perspective(300px) rotateX(90deg);
    transition-duration: 550ms;
    -webkit-transition-duration: 550ms;
    display: block !important;
    opacity: 0;
}
.flip-in.ng-hide-remove.ng-hide-remove-active {
    transform: perspective(300px) rotateX(0deg);
    -ms-transform: perspective(300px) rotateX(0deg);
    -webkit-transform: perspective(300px) rotateX(0deg);
    opacity: 1;
}

/* ------------------------------------------- */
/* Rotate In
/* ------------------------------------------- */
.rotate-in {
    -webkit-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1);
    /* older webkit */
    -webkit-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -moz-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -ms-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -o-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    /* easeOutBack */
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1);
    /* older webkit */
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    /* custom */
}
.rotate-in.ng-enter {
    transform: perspective(300px) rotateY(40deg);
    -ms-transform: perspective(300px) rotateY(40deg);
    -webkit-transform: perspective(300px) rotateY(40deg);
    transition-duration: 550ms;
    -webkit-transition-duration: 550ms;
    opacity: 0.7;
}
.rotate-in.ng-enter-active {
    transform: perspective(300px) rotateY(0deg);
    -ms-transform: perspective(300px) rotateY(0deg);
    -webkit-transform: perspective(300px) rotateY(0deg);
    opacity: 1;
}
.rotate-in.ng-leave {
    transform: perspective(300px) rotateY(0deg);
    -ms-transform: perspective(300px) rotateY(0deg);
    -webkit-transform: perspective(300px) rotateY(0deg);
    transition-duration: 550ms;
    -webkit-transition-duration: 550ms;
    opacity: 1;
}
.rotate-in.ng-leave-active {
    transform: perspective(300px) rotateY(-40deg);
    -ms-transform: perspective(300px) rotateY(-40deg);
    -webkit-transform: perspective(300px) rotateY(-40deg);
    opacity: 0.7;
}
.rotate-in.ng-hide-add {
    transform: perspective(300px) rotateY(0deg);
    -ms-transform: perspective(300px) rotateY(0deg);
    -webkit-transform: perspective(300px) rotateY(0deg);
    transition-duration: 550ms;
    -webkit-transition-duration: 550ms;
    opacity: 1;
}
.rotate-in.ng-hide-add.ng-hide-add-active {
    transform: perspective(300px) rotateY(-40deg);
    -ms-transform: perspective(300px) rotateY(-40deg);
    -webkit-transform: perspective(300px) rotateY(-40deg);
    opacity: 0;
}
.rotate-in.ng-hide-remove {
    transform: perspective(300px) rotateY(40deg);
    -ms-transform: perspective(300px) rotateY(40deg);
    -webkit-transform: perspective(300px) rotateY(40deg);
    transition-duration: 550ms;
    -webkit-transition-duration: 550ms;
    display: block !important;
    opacity: 0;
}
.rotate-in.ng-hide-remove.ng-hide-remove-active {
    transform: perspective(300px) rotateY(0deg);
    -ms-transform: perspective(300px) rotateY(0deg);
    -webkit-transform: perspective(300px) rotateY(0deg);
    opacity: 1;
}

.mon-toggle-slide {
    -webkit-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1);
    /* older webkit */
    -webkit-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -moz-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -ms-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -o-transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all 0 cubic-bezier(0.175, 0.885, 0.32, 1.275);
    /* easeOutBack */
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1);
    /* older webkit */
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.45, 1.595);
    &.ng-hide {
        transition: max-height 500ms;
        max-height: 0;
        overflow: hidden;
    }

    &.ng-hide.ng-hide-active {
        max-height: 500px;
    }

    &.ng-hide-add {
        max-height: 500px;
        overflow: hidden;
        transition-delay: 0ms;
        transition: max-height 500ms;
    }

    &.ng-hide-add.ng-hide-add-active {
        max-height: 0;
    }

    &.ng-hide-remove {
        transition: max-height 500ms;
        max-height: 0;
        overflow: hidden;
    }

    &.ng-hide-remove.ng-hide-remove-active {
        max-height: 500px;
    }
}
.mon-table-row-toggle {
    transition: all 0.5s;
    &.ng-enter {
        td > div {
            max-height: 0;
            overflow: hidden;
            transition: all 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        }
    }
    &.ng-enter-active {
        & > td > div {
            max-height: 100px;
        }
    }

    &.ng-leave {
        td > div,
        td {
            max-height: 100px;
            transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        }
    }
    &.ng-leave-active {
        & > td {
            padding: 0;
            border: 0;
        }
        & > td > div {
            max-height: 0;
            padding: 0;
            box-sizing: border-box;
            overflow: hidden;
        }
    }
}
