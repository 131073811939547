@keyframes mon-dialog-delay-fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes mon-dialog-delay-fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

@keyframes mon-dialog-fadein {
  from { opacity: 0; }
  to   { opacity: 0.6; }
}

/* Firefox < 16 */
@-moz-keyframes mon-dialog-fadein {
  from { opacity: 0; }
  to   { opacity: 0.6; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes mon-dialog-fadein {
  from { opacity: 0; }
  to   { opacity: 0.6; }
}

/* Internet Explorer */
@-ms-keyframes mon-dialog-fadein {
  from { opacity: 0; }
  to   { opacity: 0.6; }
}

/* Opera < 12.1 */
@-o-keyframes mon-dialog-fadein {
  from { opacity: 0; }
  to   { opacity: 0.6; }
}

@keyframes mon-dialog-fadeout {
  from { opacity: 0.6; }
  to   { opacity: 0; }
}

/* Firefox < 16 */
@-moz-keyframes mon-dialog-fadeout {
  from { opacity: 0.6; }
  to   { opacity: 0; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes mon-dialog-fadeout {
  from { opacity: 0.6; }
  to   { opacity: 0; }
}

/* Internet Explorer */
@-ms-keyframes mon-dialog-fadeout {
  from { opacity: 0.6; }
  to   { opacity: 0; }
}

/* Opera < 12.1 */
@-o-keyframes mon-dialog-fadeout {
  from { opacity: 0.6; }
  to   { opacity: 0; }
}

@-webkit-keyframes sidein {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes sidein {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@-webkit-keyframes sideout {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes sideout {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}