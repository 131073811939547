.most-frequent-summary {
  .progress-bar {
    background-color: #50a6fb;
  }
  .progress-xxs {
    width: 70%;
    margin: auto;
  }
  .most-frequent-label {
    font-size: 1.3em;
  }
}
