.mon-panel-header {
  h2 {
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 0;
    line-height: 1.4;
  }

  &.card-header.no-border {
    border-color: transparent;
  }
}
