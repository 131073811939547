.page-details-statistics {
    .section-header {
        page-header .page-header {
            height: $page-details-section-header-height;
        }
    }
}

.daterangepicker {
    z-index: 10001;
}
