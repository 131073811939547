.global-policy-form-boxes {
    .full-width {
        width: 100%;
    }

    .btn-group {
        .add-rule-btn {
            border-color: $secondary-1;
            color: $main-1;

            &:hover {
                background-color: $secondary-1;
            }
        }

        .active {
            color: $color-white;
            background-color: $main-1;

            &:hover {
                color: $color-white;
                background-color: $main-1;
            }
        }
    }
}
