.form-page-performance-page {
    .profile-block {
        border: 1px solid rgb(228, 228, 228);
    }

    .zero-width {
        // It blows my mind thinking about it, but this for one reason or another makes text-ellipsis work inside flexbox.
        width: 0;
    }

    .pages-container {
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        z-index: 900;
        background: white;
        max-width: 1000%;
        &.loading {
            width: 100% !important;
        }

        table {
            margin-top: -18px;

            thead {
                width: 0;
                height: 0;
                visibility: hidden;
                padding: 0;
            }
            tbody {
                tr {
                    td {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .profile-container {
        position: relative;
        overflow: hidden;

        .profile-label {
            position: absolute;
            left: 0;
            top: 0;
            padding: 3px 6px;
            border-radius: 3px 0;
        }

        .dropdown-menu.dropdown-menu-right {
            right: -3px;
        }
        .dropdown-button:focus {
            outline: -webkit-focus-ring-color auto 5px;
        }
        .dropdown-button {
            border: none !important;
            background-color: transparent !important;
        }
    }

    .profile-card-text-to-right {
        margin-left: 23px;
    }

    .profile-card-text-to-left {
        margin-left: -1px;
    }
}
