$uib-time-spaceing: 5px;
$border-radius-base: 4px;

.scheduled-downtime-form {
  .selector-input {
    padding: 1.2em 2.6em 1.1em 1.2em;
    border: 1px solid #dcdcdc;
    box-sizing: border-box;
    background-color: #fff;
    background-image: none;
  }

  legend {
    border-bottom: none;
  }

  .datepicker-field {
    border-top-right-radius: $border-radius-base !important;
    border-bottom-right-radius: $border-radius-base !important;
  }
  .uib-time {
    &.hours {
      input {
        margin-right: $uib-time-spaceing;
      }
    }

    &.minutes {
      input {
        margin-right: $uib-time-spaceing;
        margin-left: $uib-time-spaceing;
      }
    }

    &.am-pm {
      button {
        margin-left: $uib-time-spaceing;
      }
    }
  }
}
