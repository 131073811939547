.cookie-banner-traffic-light {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    color: $color-white;
    text-align: center;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.red {
        background-color: $secondary-7;
    }
    &.yellow {
        background-color: $secondary-8;
    }
    &.green {
        background-color: $secondary-16;
    }
}
