.fa-width-8x {
    width: 40px;
    display: inline-block;
}

.fa-width-6x {
    width: 30px;
    display: inline-block;
}

.far,
.fas,
.fab {
    &.pull-left {
        margin-right: 0.3em;
    }
    &.pull-right {
        margin-right: 0.3em;
    }
}
