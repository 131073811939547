@import '@monsido/colors/dist/colors.scss';

/** Local styles **/
$fontDir: "../fonts";
$flagIconPath: "../../../../../../node_modules/flag-icons/flags";
/** Font size **/
$font-size-base: 12px;

/** Colors **/

/** Legacy color names **/
$color-black-transparency-1: #00000029;
$color-white: #ffffff;
$color-confirmed: #fcb785;
$color-potential: #9ac6df;
$color-page-details-link: #324768;
$turqoise-color: #4dc0bd;
$blue-tinted-green: #2a5e65;
$wcag-a: $secondary-12;
$wcag-aa: $secondary-13;
$wcag-aaa: $secondary-14;
$monsido-green: #263233;
$light-grey: #f6f9fb;
$color-green: #7ec97f;
$text-grey: #a6a6a6;
$grey-color-1: $secondary-4;
$grey-color-2: #f5f5f5;
$grey-color-3: $secondary-6;
$grey-color-4: $secondary-1;
$grey-color-5: $secondary-2;
$blue-color-1: $main-1;
$blue-color-2: $secondary-3;
$blue-color-3: #c9e6fd;
$magenta: #ea5c7e;
$cornflower-blue: #6195ed;
$fuchsia-blue: #7056c2;
$color-purple: $branding-2;
$color-high-red: $secondary-7;
$color-medium-yellow: $secondary-8;
$color-orange: #fdc50d;
$color-dark-blue: #004ac1;

/** Color list **/

$color-low-blue: $main-1;
$color-deep-blue: #182847;

/** Page details **/
$page-details-topmenu-padding-bottom: 20px;
$page-details-topmenu-border-bottom: 3px;
$page-details-topmenu-left-right-padding: 30px;

$page-details-section-header-height: 96px;

/** Border **/
$border-grey-color: $grey-color-3;

/** Top menu **/
$topmenu-navbar-height: 60px;

/**
 * Menus
 */
$page-content-padding: 3px;
$sidebar-width: 265px;
$sidebar-background-color: #555557;

/** Page Performance **/
$fcp-color: #6122ee;
$lcp-color: $secondary-12;
$fmp-color: #6100f2;
$tti-color: #00dedb;
$si-color: $branding-6;
$tbt-color: $secondary-15;
$cls-color: $secondary-11;
$performance-dropdown-selected-color: $grey-color-3;

/** Bootstrap variables **/
$btn-orange-color: #f49956;
$state-success-text: $secondary-16;

/**
 * Colors
 */
$wcag-a-color: $wcag-a;
$wcag-aa-color: $wcag-aa;
$wcag-aaa-color: $wcag-aaa;
$link-color: #229bf9;
$shadow-color: #00000029;
$grey-bg-color: #e8ebf0;

/**
 *  Text
 */
$grey-text: #A6A6A6;
$dark-grey: #8d8d8d;
$grey-text-ada: #595959;
$text-warning: #F0AD4E;
$text-gray-small: #777777;

/* Menu */
$menu-navbar-icon-color: #696969;
$menu-search-background: #fff;
$menu-background: #fff;
$menu-search-text-color: #696969;
$menu-text-active-color: $link-color;
$topmenu-navbar-active-space: 15px;
$topmenu-on-hover-color: #f1f3f6;
$sidebar-width: 265px;
$sidebar-background-color: #555557;

$legacy-main-color: $main-1;
$legacy-hover-color: #0574c9;

$primary-legacy-bg-color: #286090;
$primary-legacy-bg-color-hover: #286090;
$primary-legacy-border-color: #2e6da4;
$primary-legacy-border-color-hover: #204d74;
$primary-legacy-border-color-focus: #122b40;
