.filters-button {
  margin: 0 8px 0 8px;
  a {
    height: 35px;
    width: 35px;
    padding: 7px 8px;

    &:focus-visible {
      border-radius: 50%;
    }
    &:hover,
    &.active {
      background-color: $secondary-2;
      border-radius: 50%;
    }

    &:focus {
      outline: $secondary-11 auto 5px;
    }
  }
}
