@import "variables";
.policy-exchange-center {
    font-size: $font-size;
    .border-color-secondary-5 {
        border-color: $secondary-5;
    }
    .exchange-center-search {
        &.form-group {
            margin-bottom: 0;
            .input-group {
                width: 100%;
            }
        }
        .input-group {
            color: $grey-text;

            input[type="search"].form-control {
                font-size: $search-font-size;
                border-width: 1px;
                border-color: #fff;
                height: $search-height;
                line-height: $search-height;
                @include set-border-radius(0);
                color: $grey-color-1;
                box-shadow: none;

                &::-webkit-input-placeholder {
                    color: $grey-text;
                }

                &:-moz-placeholder {
                    /* Firefox 18- */
                    color: $grey-text;
                }

                &::-moz-placeholder {
                    /* Firefox 19+ */
                    color: $grey-text;
                }

                &:-ms-input-placeholder {
                    color: $grey-text;
                }
            }

            .input-group-prepend .input-group-text {
                color: $grey-text;
                font-size: $search-font-size;
                padding-left: 5px;
                line-height: $search-height;
                @include set-border-radius(0);
                border-width: 1px;
                border-color: #fff;
                background: #fff;
            }
        }
    }

    .table-filters-dropdown > .table-filters-selector {
        .selected-filters-container {
            display: none;
        }

        div.tfs-dropdown-menu {
            top: 26px;
            left: -85px;

            .tfs-submenu .tfs-dropdown-menu {
                left: auto;
                right: 100%;
            }
        }
    }

    .overflow-hidden {
        overflow: hidden;
    }
    .button-container {
        width: 100%;
        height: $container-height + 30px;
        font-size: $font-size;
        white-space: normal;

        .outer-t-45 {
            margin-top: 45px;
        }
        p {
            font-size: 12px;
        }
        h4 {
            font-size: 14px;
        }
    }
}
