#data-privacy-list {
    .page-details.outer-r-smurf {
        vertical-align: middle !important;
    }
    .notification-right {
        padding-left: 47px;
    }
    .warning {
        color: $color-high-red;
    }
}
