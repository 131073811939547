@import '@monsido/colors/dist/colors.scss';

.fast-track-table {
    .selected,
    .focused,
    tbody > tr:focus,
    tbody > tr:hover {
        background-color: darken($light-grey, 5%) !important;
    }

    .button-container {
        white-space: nowrap;
        padding: 0 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
    }

    .row-container {
        position: relative;
    }

    .open-snippet-details-button {
        background: transparent;
        border: none;
        height: 100%;
        // focus frame will be cut without this
        width: calc(100% - 4px);
        margin-left: 2px;
        position: absolute;
        top: 0;
        left: 0;
        &:focus-visible { outline: none; box-shadow: 0 0 0 1px $secondary-14; }
    }

    .button-name {
        opacity: 0;
    }
}
