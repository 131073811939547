//The notification-text component, has just slightly different sizes than the
//notification component. The styling here is to make them look the same
.spelling-dashboard {
    .common-language-block {
        div {
            &:nth-child(1) {
                padding-right: 12px;
            }
        }
    }
}
