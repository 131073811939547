@import "../../variables";

.policy-exchange-center-views-grid {
    font-size: $font-size;

    .panel-template-policy {
        border: 1px solid transparent;;
        @include panel-variant($grey-color-3,$color-deep-blue,$color-white,transparent);

        &:hover {
            box-shadow: 0px 5px 10px -2px rgba(71, 71, 71, 0.35);
        }

        .card-header {
            height: 100px;

            .badge-new {
                color: $color-white;
                border-radius: 50%;
                height: 45px;
                width: 45px;
                position: absolute;
                right: -7px;
                top: -7px;
            }
        }

        .card-body {
            .country {
                position: absolute;
                top: -28px;
                right: 5px;
            }
        }

        .card-footer {
            border-top-color: transparent;
        }

        .grid-icon {
            background-color: $blue-color-1;
            color: $color-white;
            padding: 10px;
            border-radius: 5px;
        }
    }
}
