$black: #000;
$hr-grey: #a2a2a2;
$link-grey: #333;
$white: #fff;

@media print {
    * {
        border-color: transparent !important;
        background: none;
    }

    html {
        overflow: visible !important;
    }

    body {
        position: relative;
        width: 100% !important;
        height: auto !important;
        margin: 0 !important;
        padding: 0 !important;
        line-height: 1.45;
        color: $black;
        font-size: 12pt;
        padding-bottom: 50px;
        overflow: visible !important;
    }

    html,
    body {
        height: auto !important;
        max-height: none !important;

        div[ui-view] {
            height: auto !important;
            max-height: none !important;
            overflow: visible;

            &.max-height {
                height: auto !important;
                max-height: none !important;
                overflow: visible;
            }
        }
    }

    page-header>.page-header {
        //The page-header component must not have any spacing in the bottom
        margin-bottom: 0;
    }

    .max-height {
        height: auto;
        min-height: auto;
        max-height: initial;
    }

    .card-body {
        padding-left: 0;
        padding-right: 0;
    }

    .print-visible {
        display: block !important;
        width: auto !important;
        height: auto !important;
        min-height: auto !important;
        overflow: visible !important;
    }

    #page-content {
        page-break-inside: avoid;
        margin: 0 !important;
        height: auto;

        &.max-height>.page-content-scroll-container {
            height: auto !important;
            max-height: none !important;
            overflow-y: visible !important;

            &>div {
                display: block !important; // FireFox Fix, Flexbox doesn't work very well in print view..

                &>div.content {
                    margin-left: 0;
                    width: 100%;
                    overflow: visible;
                    height: auto;
                    min-height: auto;
                }
            }
        }
    }

    .print {
        display: block !important;
    }

    .main-ui-view {
        padding-bottom: 0 !important;
    }

    /* Headings */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        page-break-after: avoid;
    }

    h1 {
        font-size: 19pt;
    }

    h2 {
        font-size: 17pt;
    }

    h3 {
        font-size: 15pt;
    }

    h4,
    h5,
    h6 {
        font-size: 14pt;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    code {
        font: 12pt Courier, monospace;
    }

    blockquote {
        margin: 1.2em;
        padding: 1em;
        font-size: 12pt;
    }

    hr {
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: $hr-grey  !important;
    }

    /* Images */
    img {
        float: left;
        margin: 1em 1.5em 1.5em 0;
        max-width: 100% !important;
    }

    a img {
        border: none;
    }

    /* Links */
    a:link,
    a:visited {
        background: transparent;
        font-weight: 700;
        text-decoration: underline;
        color: $link-grey;
    }

    a:link[href^="http://"]:after,
    a[href^="http://"]:visited:after {
        content: " (" attr(href) ") ";
        font-size: 90%;
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    /* Don't show linked images  */
    a[href^="http://"] {
        color: $black;
    }

    a[href$=".jpg"]:after,
    a[href$=".jpeg"]:after,
    a[href$=".gif"]:after,
    a[href$=".png"]:after {
        content: " (" attr(href) ") ";
        display: none;
    }

    /* Don't show links that are fragment identifiers, or use the `javascript:` pseudo protocol .. taken from html5boilerplate */
    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    .avoid-page-break {
        page-break-inside: avoid;
    }

    .break-before {
        page-break-before: always;
    }

    .break-after {
        page-break-after: always;
    }

    /* Canvas */
    canvas {
        float: left;
        margin: 1em 1.5em 1.5em 0;
        max-width: 100% !important;
    }

    /* Table */
    table {
        margin: 1px;
        text-align: left;

        th {
            border-bottom: 1px solid $link-grey;
            font-weight: bold;
            padding: 4px 10px 4px 0 !important;
        }

        td {
            border-bottom: 1px solid $link-grey;
            padding: 4px 10px 4px 0 !important;
        }

        caption {
            background: $white;
            margin-bottom: 2em;
            text-align: left;
        }

        thead {
            display: table-header-group;
        }

        tr {
            display: block;
            page-break-before: auto;
        }
    }

    img,
    table,
    tr,
    td,
    th {
        page-break-inside: avoid;
    }

    /*Bootstrap overrides*/
    .row {
        display: block;
        clear: both;
    }

    [class*="col"] {
        display: block;
        flex: none;
        float: left;
    }
}