@import "mixins/background";

@include set-background-color(turqoise, $turqoise-color);
@include set-background-color(monsido-green, $monsido-green);
@include set-background-color(grey, $grey-text);
@include set-background-color(body, $body-bg);
@include set-background-color(white, #fff);
@include set-background-color(light-grey, $light-grey);
@include set-background-color(purple, $color-purple);

.bg-transparent {
    background-color: transparent;
    &.table {
        background-color: transparent;
    }
}
