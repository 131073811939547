@import "bootstrap-variables";
@import '@monsido/colors/dist/colors.scss';
@import "accessibility-mode/accessibility-mode-variables.scss";

/**
 * Labels
 */

$label-success-bg: #dff0d8;
$label-info-bg: #d9edf7;
$label-warning-bg: #fcf8e3;
$label-danger-bg: #f2dede;
$form-check-input-border: 1px solid #{$secondary-4};
$form-check-input-checked-bg-color: #{$main-1};


$modal-lg: 900px;

@import "bootstrap-sass-import.scss";

@import 'colors.scss';
@import 'variables.scss';
@import 'ngx-select.scss';
@import 'stacked-icon.scss';
@import 'modal-compatibility.scss';
@import 'modal.scss';
@import 'mon-date-picker.scss';
@import "./mon-grid/app.scss";

@import "variables";
@import "background";
@import "mon-grid";
@import "mon-spacer";
@import "mixins";
@import "roboto/roboto";
@import "../deprecate/frontend-components/src/styles/main.scss";
@import "bootstrap/bootstrap.scss";
@import "mon-dialog";
@import "fontawesome/fontawesome";
@import "../deprecate/angular-selector/angular-selector.css";
@import "custom";
@import "bubble";
@import "buttons";
@import "border";
@import "table";
@import "merge";
@import "navbar";
@import "animation";
@import "panels";
@import "progress";
@import "print";
@import "icons";
@import "cursors";
@import "ngb-tooltip";
@import "mon-flexbox";
@import "from-bootstrap-3";
@import "text";
@import "bootstrap-3-input-group";
@import "local-style-files.scss";
@import "inner.scss";
@import "reboot.scss";
@import "global-overrides.scss";
@import "accessibility-mode/accessibility-mode";
@import "mon-link-url-title";

 * {
    box-sizing: border-box;
  }
  *:before,
  *:after {
    box-sizing: border-box;
  }

.mon-dialog-header {
    .page-header {
        margin-top: 30px;
    }
}

mon-issue {
    .mon-dialog-header {
        .page-header {
            margin-top: 35px;
        }
    }
}

button,
input,
input[type=radio],
input[type=checkbox],
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}

.page-header {
    padding-bottom: 7.5px;
    margin: 34px 0 20px;
    border-bottom: 1px solid #eeeeee;
}

.bg-body {
    background-color: #f3f5f7 !important;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
}

.form-horizontal fieldset.form-group {
    margin-right: 0;
    margin-left: 0;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 5px 10px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 3px;
}
.btn-no-style {
    appearance: none;
    background: none;
	border: none;
	padding: 0;

    &:focus-visible {
        outline: none;
        box-shadow: 0 0 0 1px $secondary-14;
    }
}

.btn-mon-icon {
    &:focus-visible {
        outline: none;
        box-shadow: 0 0 0 1px $secondary-14;
    }
}

html {
    font-size: 12px;
}

body {
    font-family: 'Roboto', 'Arial Narrow', sans-serif;
    font-size: 12px;
    line-height: 1.42857143;
    color: $secondary-4;
    background-color: $secondary-1;

    a:not([href]):not([class]),
    a, .btn-link,
    a.btn:not(.btn-marine-blue):not(.button-monsido-icon),
    .nav-link {
        text-decoration: none;
    }

    .nav-link:focus,
    .nav-link:hover {
        text-decoration: none;
    }

    .dropdown-menu>li>a:not([href]):not([class]):hover, .dropdown-menu>li>a:not([href]):not([class]):focus,
    .dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover {
        text-decoration: none;
        background-color: #f5f5f5;
    }
}

body:not(.accessibility-mode) {
    a:not([href]):not([class]),
    a, .btn-link,
    a.btn:not(.btn-marine-blue):not(.button-monsido-icon),
    .nav-link {
        color: $legacy-main-color;
    }

    .nav-link:focus,
    .nav-link:hover {
        color: $legacy-hover-color;
        background-color: #eee;
        border-color: #eee #eee #ddd;
    }

    .dropdown-menu>li>a:not([href]):not([class]):hover, .dropdown-menu>li>a:not([href]):not([class]):focus,
    .dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover {
        color: $legacy-main-color;
    }
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

button.no-button-appearance {
    all: unset;
}

.panel,
.card {
    background-color: #fff;
}

.card-body {
    padding: 15px;
}

.monsido-panel.card,
.panel-monsido-card {
    border-color: white;
    border-radius: 3px;
    box-shadow: 0px 0px 6px #00000040;
}

.table {
    margin-bottom: 17px;

    domain-dashboard .panel-monsido-card & {
        margin-bottom: 0;
    }
}

.table > :not(caption) > * > th,
.table > :not(caption) > * > td {
    padding: 8px;
    line-height: 1.428571429;
    vertical-align: top;
    border-bottom-width: 0;
    display: table-cell;
    float: none;
}

.table>thead>tr>th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd;
}

.table > :not(caption) > * > td {
    border-top: 1px solid #ddd;
}

/* e.g. TBODY */
.table > :not(:first-child) {
    border-top: none;
}

.input-group-prepend {
    padding: 0 !important;
}
.mon-panel-header.card-header,
.card.monsido-panel>.card-header {
    color: #333;
    background-color: #fff;
}
.card-header {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.pull-left {
    float: left!important;
}
.pull-right {
    float: right!important;
}

.btn-group-vertical>.btn-group:after,
.btn-group-vertical>.btn-group:before,
.btn-toolbar:after, .btn-toolbar:before,
.clearfix:after, .clearfix:before,
.container-fluid:after, .container-fluid:before,
.container:after, .container:before,
.dl-horizontal dd:after, .dl-horizontal dd:before,
.form-horizontal .form-group:after,
.form-horizontal .form-group:before, .modal-footer:after,
.modal-footer:before, .modal-header:after, .modal-header:before,
.nav:after, .nav:before, .navbar-collapse:after,
.navbar-collapse:before, .navbar-header:after, .navbar-header:before, .navbar:after,
.navbar:before, .pager:after, .pager:before, .card-body:after, .card-body:before, .row:after, .row:before {
    display: table;
    content: " ";
}

.btn-group-vertical>.btn-group:after, .btn-toolbar:after,
.clearfix:after, .container-fluid:after, .container:after,
.dl-horizontal dd:after, .form-horizontal .form-group:after,
.modal-footer:after, .modal-header:after, .nav:after,
.navbar-collapse:after, .navbar-header:after, .navbar:after, .pager:after, .card-body:after, .row:after {
    clear: both;
}

.selector-container.multiple.remove-button .selector-helper {
    padding-right: 15px;
}

label.separator {
    margin-bottom: 4px !important;
}

input + .help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #959597;
}

.outer-l-none {
    margin-left: 0 !important;
}
.inner-l-none {
    padding-left: 0 !important;
}

.inner-t-mini {
    padding-top: 5px !important;
}

.outer-b-none {
    margin-bottom: 0 !important;
}

.h4, .h5, .h6, h4, h5, h6 {
    margin-top: 8.5px;
    margin-bottom: 8.5px;
}

.modal-header {
    display: block;
}
.modal-title {
    margin-top: 0;
}
.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.form-text {
    font-size: 12px;
}
textarea.form-control {
    height: auto;
}

.mon-dialog .mon-dialog-body-with-footer {
    background: #f3f5f7 !important;
}
.inner-none {
    padding: 0;
}

.modal-header h4#modal-title {
    float: left;
    max-width: calc(100% - 20px);
}

.mon-dialog-close {
    color: $secondary-4;
}
.label-default {
    background-color: $text-gray-small;
    border-radius: 2px;
    color: #fff;
    font-size: 9px;
    font-weight: 500;
    padding: 2px 5px;
}
.hidden {
    display: none!important;
}
.dropdown-menu>.active>a, .dropdown-menu>.active>a:focus, .dropdown-menu>.active>a:hover {
    background-color: #337ab7;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.float-none {
    float: none !important;
}

.selector-container.loading .selector-global-helper .selector-icon {
    width: 15px;
    height: 15px;
}

@media print {
    body {
        font-size: 12pt;
    }
}
