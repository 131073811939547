.score-line-container {
    .top-line {
        stroke-width: 3px;
    }
    .line-chart {
        position: relative;
        .score-line-chart-tooltips {
            position: absolute;
            top: 15px;
            left: 0;
            padding: 6px;
            background-color: #fff;
            color: #000;
            box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
            z-index: 1;
        }
    }
    #performance-score-line-chart {
        margin-top: 5px;

        .milestoneLine {
            stroke: $secondary-15;
            stroke-width: 2;
        }

        .milestoneText {
            stroke: $secondary-15;
            font-size: 12px;
            font-weight: 300;
            margin-left: -50%;
        }

        .milestoneLink {
            text-decoration: none;
        }
    }
}
