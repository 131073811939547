.page-details-dashboard-data-privacy-box-chart {
    .high {
        color: $color-high-red;
    }
    .medium {
        color: $color-medium-yellow;
    }
    .low {
        color: $color-low-blue;
    }
}
