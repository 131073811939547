.data-privacy {
    .filters-button {
        &:active,
        &:focus {
            text-decoration: none !important;
        }
    }

    .type-cell {
        width: 5%;
    }

    .table-css-compliance {
        justify-content: flex-end !important;
    }
}
