ngb-tooltip-window.show.tooltip {
    opacity: 1;

    &.bs-tooltip-top {
        padding: 5px 0;
        margin-top: -3px;
    }
    &.bs-tooltip-right {
        padding: 0 5px;
        margin-left: 3px;
    }
    &.bs-tooltip-bottom {
        padding: 5px 0;
        margin-top: 3px;
    }
    &.bs-tooltip-left {
        padding: 0 5px;
        margin-left: -3px;
    }

    .arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }

    &.bs-tooltip-top .arrow {
        bottom: 0;
        left: 50%;
        margin-left: -5px;
        border-width: 5px 5px 0;
        border-top-color: #000;
      }
      &.bs-tooltip-top-left .arrow {
        right: 5px;
        bottom: 0;
        margin-bottom: -5px;
        border-width: 5px 5px 0;
        border-top-color: #000;
      }
      &.bs-tooltip-top-right .arrow {
        bottom: 0;
        left: 5px;
        margin-bottom: -5px;
        border-width: 5px 5px 0;
        border-top-color: #000;
      }
      &.bs-tooltip-right .arrow {
        top: 50%;
        left: 0;
        margin-top: -5px;
        border-width: 5px 5px 5px 0;
        border-right-color: #000;
      }
      &.bs-tooltip-left .arrow {
        top: 50%;
        right: 0;
        margin-top: -5px;
        border-width: 5px 0 5px 5px;
        border-left-color: #000;
      }
      &.bs-tooltip-bottom .arrow {
        top: 0;
        left: 50%;
        margin-left: -5px;
        border-width: 0 5px 5px;
        border-bottom-color: #000;
      }
      &.bs-tooltip-bottom-left .arrow {
        top: 0;
        right: 5px;
        margin-top: -5px;
        border-width: 0 5px 5px;
        border-bottom-color: #000;
      }
      &.bs-tooltip-bottom-right .arrow {
        top: 0;
        left: 5px;
        margin-top: -5px;
        border-width: 0 5px 5px;
        border-bottom-color: #000;
      }
}