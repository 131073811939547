.page-performance-last-load-time-indicators {
    padding: 15px 0;

    .last-load-time-color-indicator {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
    }

    & > div {
        flex-wrap: wrap;
    }
}

.ruler-fmp {
    background-color: $fmp-color;
}
.ruler-fcp {
    background-color: $fcp-color;
}
.ruler-lcp {
    background-color: $secondary-12;
}
.ruler-tti {
    background-color: $tti-color;
}
.ruler-si {
    background-color: $branding-6;
}
.ruler-tbt {
    background-color: $secondary-15;
}
.ruler-cls {
    background-color: $secondary-11;
}
