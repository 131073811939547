.modal-dialog {
    margin: 80px auto 30px auto;

    --bs-modal-width: 600px;
}

.modal-lg {
    @media (min-width: 992px) {
        --bs-modal-width: 900px;
    }
}

.modal-content {
    box-shadow: 0 5px 15px rgba(0,0,0,.5);
}

.modal-header {
    border-bottom: 1px solid #e5e5e5;
    padding: 15px;

    .modal-title {
        float: left;
    }
}

.modal-body {
    --bs-modal-padding: 15px;
    &:empty {
        display: none;
        + .modal-footer {
            border-top: 0;
        }
    }
}

.modal-footer {
    padding: 15px;
    border-top: 1px solid #e5e5e5;
}
