@import "@monsido/colors/dist/colors";

ul.list-group {
    padding-left: 0;
    margin-bottom: 20px;

    &.flex-wrap {
        flex-direction: unset;
    }
}

.list-group-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
}

a {
    .list-group-item {
        span {
            color: #{$main-1} !important;
        }
    }
}