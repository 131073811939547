.uptime-email-selector-container {
  label {
    padding-top: 7px;
  }
  .uptime-email-selector {
    .tag-list li.tag-item {
      background: rgb(67, 133, 245);
      font-weight: 400;
      color: rgb(255, 255, 255);
      border-radius: 20px;
      padding: 5px 10px;
      box-shadow: none;
      text-shadow: none;
      line-height: 1.2em;
      border: 0;
    }

    .tags {
      border: 1px solid #ccc;
      border-radius: 4px;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    }
  }
}
