.accessibility-mode { 
    .page-details-view {
        .title-row .title-icon {
            background-color: $accessibility-theme-base-color;
        }
    
        div.page-details-topmenu {
            ul.page_details_submenu {
                li.active {
                    color: $accessibility-link-color;
                    border-color: $accessibility-link-color;
                }
            }
        }
    }

}
