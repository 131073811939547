#cookie-acceptance-rate-overview {

    .no-result-message-container {
        p {
            font-size: 21px;
        }
    }

    .page-cookie-summary-doughnut-container {
        .mon-chart {
            display: flex;
            justify-content: center;
        }
    }

    h4 {
        font-size: 14px;
    }
}
