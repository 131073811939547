$selector-helper-width: 4.6em;
.changes-history {
  .page-header h1 .label {
    font-size: 12px;
  }

  .selector-container {
    &.open {
      .selecto-input {
        @include set-border-radius(0);
      }
    }

    .selector-input {
      min-height: 60px;
      padding: 1.8em $selector-helper-width 1.3em 1.8em;
      border: 1px solid #dcdcdc;
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05),
        inset 0 1px 0 rgba(255, 255, 255, 0.8);
      box-sizing: border-box;
      background-color: #fff;
      background-image: none;
      @include set-border-radius(0);

      .selector-helper {
        width: $selector-helper-width;
      }
    }

    ul {
      @include set-border-radius(0);
      &.selector-values {
        li {
          max-width: calc(#{"100% - 32px"});
          display: inline-block;
        }
      }
    }
  }
}
