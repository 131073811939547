.data-privacy-fast-track {
    .table-container-paginate {
        padding-right: 15px;
        padding-left: 15px;
    }

    .ft-table-container {
        overflow-y: auto;
        overflow-x: hidden;
    }

    table.checks-table tbody tr {
        &.captioned {
            background: rgb(254, 255, 229);
        }
    }
}
