@import "../../../../styles/variables.scss";
@import "mixins";

$border-radius: 3px;
$input-group-padding-top: 6px;

.search-input {
  margin-bottom: 0;

  .input-group {
    font-size: 12px;

    .input-group-append {
      &.btn-hide {
        cursor: text;
      }

      .input-group-text {
        padding-top: $input-group-padding-top;
        border-left-color: transparent;
      }
    }

    .input-group-prepend .input-group-text {
      padding-top: $input-group-padding-top;
      border-right-color: transparent;
    }

    input.form-control[type="search"] {
      border-left-color: transparent;
      border-right-color: transparent;
      height: 38px;
      padding: 0;
      box-shadow: none;
    }

    .v-none {
      visibility: hidden;
    }
  }
  @include table-search-variant(#FFF, #e6e6e6, #7B7B7B, #7B7B7B); // Default ...

  &.white-theme {
    @include table-search-variant(#FFF, #e6e6e6, #7B7B7B, #7B7B7B);
  }

  &.grey-theme {
    @include table-search-variant(#EBEBEC, #EBEBEC, #7B7B7B, #7B7B7B);
  }
}
