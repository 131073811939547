.policy-form-dialogs-rule {
    .sub-entries {
        label {
            margin-right: 10px;
            display: inline-block;
        }
    }

    .main-1-color {
        color: $main-1;
    }
}

mon-action-menu.rule-menu-action {
    .mon-button {
        .mon-button-wrapper {
            color: $gray-dark;
        }
    }
}

//
