@use "sass:math";

 #wrap{
    height: auto;
    min-height: 100%;
    width: 100%;
    position: relative;
}

#sidebar-wrapper {
    width: $sidebar-width;
    position: absolute;
    top: 0;
    bottom: 0;
    color: $sidebar-background-color;
    background: $body-bg;
    border-right: 1px solid $panel-default-border;
}

#sidebar {
    ul.sidebar-nav {
        margin-top: $topmenu-navbar-height + 10px;
        padding-left: 0;

        & > li:not(.separator) {
            & > a:hover,
            &.open {
                cursor: pointer;
                background-color: #d4dce3;
            }

            & > a.active {
                background-color: #d4dce3;
            }

            ul.sub {
                padding: 0;
                background-color: #eaedf1;
                a {
                    padding-left: 30px;
                }

                & > li > a:hover,
                & > li > a.active {
                    cursor: pointer;
                    background-color: #dbe1e7;
                }
            }
        }

        & > li.separator {
            width: 100%;
            height: math.div($font-size-base * 1.6, 2);
            border-bottom: 1px solid $panel-default-border;
            margin: 20px 0;

            h4 {
                display: inline-block;
                background-color: $body-bg;
                padding: 0 10px;
                margin: 0 12px;
            }

        }
    }

    li {
        padding: 0;
        list-style: none;
        font-weight: 400;
        font-size: 14px;

        a {
            display: block;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $sidebar-background-color;
            padding: 15px 15px 15px 20px;
            text-decoration: none;
        }

        i {
            margin-right: 10px;
        }
    }
}
