.inactive-domain-color {
    color: $secondary-15;
}
.noData {
    display: inline-block;
    width: 100%;
    text-align: center;
}

.get-data-button {
    width: 50%;
    margin: 0 25%;
}

.domain-group-title-overview {
    a {
        line-height: 18px;
    }

    .domain-group-icon {
        padding-left: 20px;
        font-size: 20px;
    }
}
