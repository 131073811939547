.domain-group-title {
  > span {
    padding-left: 40px;
  }

  .label-table {
    padding-left: 78px;
  }

  a {
    line-height: 24px;
  }
}

.domain-title-cell {
  .label-table {
    padding-left: 37px;
  }
}
