@media (min-width: 0) {
    .p-0 {
      padding: 0px !important;
    }
    .pt-0 {
      padding-top: 0px !important;
    }
    .pl-0 {
      padding-left: 0px !important;
    }
    .pr-0 {
      padding-right: 0px !important;
    }
    .pb-0 {
      padding-bottom: 0px !important;
    }
    .px-0 {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
    .py-0 {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
    .m-0 {
      margin: 0px !important;
    }
    .mt-0 {
      margin-top: 0px !important;
    }
    .ml-0 {
      margin-left: 0px !important;
    }
    .mr-0 {
      margin-right: 0px !important;
    }
    .mb-0 {
      margin-bottom: 0px !important;
    }
    .mx-0 {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
    .my-0 {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
    .p-1 {
      padding: 5px !important;
    }
    .pt-1 {
      padding-top: 5px !important;
    }
    .pl-1 {
      padding-left: 5px !important;
    }
    .pr-1 {
      padding-right: 5px !important;
    }
    .pb-1 {
      padding-bottom: 5px !important;
    }
    .px-1 {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
    .py-1 {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }
    .m-1 {
      margin: 5px !important;
    }
    .mt-1 {
      margin-top: 5px !important;
    }
    .ml-1 {
      margin-left: 5px !important;
    }
    .mr-1 {
      margin-right: 5px !important;
    }
    .mb-1 {
      margin-bottom: 5px !important;
    }
    .mx-1 {
      margin-left: 5px !important;
      margin-right: 5px !important;
    }
    .my-1 {
      margin-top: 5px !important;
      margin-bottom: 5px !important;
    }
    .p-2 {
      padding: 10px !important;
    }
    .pt-2 {
      padding-top: 10px !important;
    }
    .pl-2 {
      padding-left: 10px !important;
    }
    .pr-2 {
      padding-right: 10px !important;
    }
    .pb-2 {
      padding-bottom: 10px !important;
    }
    .px-2 {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
    .py-2 {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
    .m-2 {
      margin: 10px !important;
    }
    .mt-2 {
      margin-top: 10px !important;
    }
    .ml-2 {
      margin-left: 10px !important;
    }
    .mr-2 {
      margin-right: 10px !important;
    }
    .mb-2 {
      margin-bottom: 10px !important;
    }
    .mx-2 {
      margin-left: 10px !important;
      margin-right: 10px !important;
    }
    .my-2 {
      margin-top: 10px !important;
      margin-bottom: 10px !important;
    }
    .p-3 {
      padding: 15px !important;
    }
    .pt-3 {
      padding-top: 15px !important;
    }
    .pl-3 {
      padding-left: 15px !important;
    }
    .pr-3 {
      padding-right: 15px !important;
    }
    .pb-3 {
      padding-bottom: 15px !important;
    }
    .px-3 {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
    .py-3 {
      padding-top: 15px !important;
      padding-bottom: 15px !important;
    }
    .m-3 {
      margin: 15px !important;
    }
    .mt-3 {
      margin-top: 15px !important;
    }
    .ml-3 {
      margin-left: 15px !important;
    }
    .mr-3 {
      margin-right: 15px !important;
    }
    .mb-3 {
      margin-bottom: 15px !important;
    }
    .mx-3 {
      margin-left: 15px !important;
      margin-right: 15px !important;
    }
    .my-3 {
      margin-top: 15px !important;
      margin-bottom: 15px !important;
    }
    .p-4 {
      padding: 20px !important;
    }
    .pt-4 {
      padding-top: 20px !important;
    }
    .pl-4 {
      padding-left: 20px !important;
    }
    .pr-4 {
      padding-right: 20px !important;
    }
    .pb-4 {
      padding-bottom: 20px !important;
    }
    .px-4 {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
    .py-4 {
      padding-top: 20px !important;
      padding-bottom: 20px !important;
    }
    .m-4 {
      margin: 20px !important;
    }
    .mt-4 {
      margin-top: 20px !important;
    }
    .ml-4 {
      margin-left: 20px !important;
    }
    .mr-4 {
      margin-right: 20px !important;
    }
    .mb-4 {
      margin-bottom: 20px !important;
    }
    .mx-4 {
      margin-left: 20px !important;
      margin-right: 20px !important;
    }
    .my-4 {
      margin-top: 20px !important;
      margin-bottom: 20px !important;
    }
    .p-5 {
      padding: 25px !important;
    }
    .pt-5 {
      padding-top: 25px !important;
    }
    .pl-5 {
      padding-left: 25px !important;
    }
    .pr-5 {
      padding-right: 25px !important;
    }
    .pb-5 {
      padding-bottom: 25px !important;
    }
    .px-5 {
      padding-left: 25px !important;
      padding-right: 25px !important;
    }
    .py-5 {
      padding-top: 25px !important;
      padding-bottom: 25px !important;
    }
    .m-5 {
      margin: 25px !important;
    }
    .mt-5 {
      margin-top: 25px !important;
    }
    .ml-5 {
      margin-left: 25px !important;
    }
    .mr-5 {
      margin-right: 25px !important;
    }
    .mb-5 {
      margin-bottom: 25px !important;
    }
    .mx-5 {
      margin-left: 25px !important;
      margin-right: 25px !important;
    }
    .my-5 {
      margin-top: 25px !important;
      margin-bottom: 25px !important;
    }
    .p-6 {
      padding: 30px !important;
    }
    .pt-6 {
      padding-top: 30px !important;
    }
    .pl-6 {
      padding-left: 30px !important;
    }
    .pr-6 {
      padding-right: 30px !important;
    }
    .pb-6 {
      padding-bottom: 30px !important;
    }
    .px-6 {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
    .py-6 {
      padding-top: 30px !important;
      padding-bottom: 30px !important;
    }
    .m-6 {
      margin: 30px !important;
    }
    .mt-6 {
      margin-top: 30px !important;
    }
    .ml-6 {
      margin-left: 30px !important;
    }
    .mr-6 {
      margin-right: 30px !important;
    }
    .mb-6 {
      margin-bottom: 30px !important;
    }
    .mx-6 {
      margin-left: 30px !important;
      margin-right: 30px !important;
    }
    .my-6 {
      margin-top: 30px !important;
      margin-bottom: 30px !important;
    }
    .p-7 {
      padding: 35px !important;
    }
    .pt-7 {
      padding-top: 35px !important;
    }
    .pl-7 {
      padding-left: 35px !important;
    }
    .pr-7 {
      padding-right: 35px !important;
    }
    .pb-7 {
      padding-bottom: 35px !important;
    }
    .px-7 {
      padding-left: 35px !important;
      padding-right: 35px !important;
    }
    .py-7 {
      padding-top: 35px !important;
      padding-bottom: 35px !important;
    }
    .m-7 {
      margin: 35px !important;
    }
    .mt-7 {
      margin-top: 35px !important;
    }
    .ml-7 {
      margin-left: 35px !important;
    }
    .mr-7 {
      margin-right: 35px !important;
    }
    .mb-7 {
      margin-bottom: 35px !important;
    }
    .mx-7 {
      margin-left: 35px !important;
      margin-right: 35px !important;
    }
    .my-7 {
      margin-top: 35px !important;
      margin-bottom: 35px !important;
    }
    .p-8 {
      padding: 40px !important;
    }
    .pt-8 {
      padding-top: 40px !important;
    }
    .pl-8 {
      padding-left: 40px !important;
    }
    .pr-8 {
      padding-right: 40px !important;
    }
    .pb-8 {
      padding-bottom: 40px !important;
    }
    .px-8 {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }
    .py-8 {
      padding-top: 40px !important;
      padding-bottom: 40px !important;
    }
    .m-8 {
      margin: 40px !important;
    }
    .mt-8 {
      margin-top: 40px !important;
    }
    .ml-8 {
      margin-left: 40px !important;
    }
    .mr-8 {
      margin-right: 40px !important;
    }
    .mb-8 {
      margin-bottom: 40px !important;
    }
    .mx-8 {
      margin-left: 40px !important;
      margin-right: 40px !important;
    }
    .my-8 {
      margin-top: 40px !important;
      margin-bottom: 40px !important;
    }
    .p-9 {
      padding: 45px !important;
    }
    .pt-9 {
      padding-top: 45px !important;
    }
    .pl-9 {
      padding-left: 45px !important;
    }
    .pr-9 {
      padding-right: 45px !important;
    }
    .pb-9 {
      padding-bottom: 45px !important;
    }
    .px-9 {
      padding-left: 45px !important;
      padding-right: 45px !important;
    }
    .py-9 {
      padding-top: 45px !important;
      padding-bottom: 45px !important;
    }
    .m-9 {
      margin: 45px !important;
    }
    .mt-9 {
      margin-top: 45px !important;
    }
    .ml-9 {
      margin-left: 45px !important;
    }
    .mr-9 {
      margin-right: 45px !important;
    }
    .mb-9 {
      margin-bottom: 45px !important;
    }
    .mx-9 {
      margin-left: 45px !important;
      margin-right: 45px !important;
    }
    .my-9 {
      margin-top: 45px !important;
      margin-bottom: 45px !important;
    }
    .p-10 {
      padding: 50px !important;
    }
    .pt-10 {
      padding-top: 50px !important;
    }
    .pl-10 {
      padding-left: 50px !important;
    }
    .pr-10 {
      padding-right: 50px !important;
    }
    .pb-10 {
      padding-bottom: 50px !important;
    }
    .px-10 {
      padding-left: 50px !important;
      padding-right: 50px !important;
    }
    .py-10 {
      padding-top: 50px !important;
      padding-bottom: 50px !important;
    }
    .m-10 {
      margin: 50px !important;
    }
    .mt-10 {
      margin-top: 50px !important;
    }
    .ml-10 {
      margin-left: 50px !important;
    }
    .mr-10 {
      margin-right: 50px !important;
    }
    .mb-10 {
      margin-bottom: 50px !important;
    }
    .mx-10 {
      margin-left: 50px !important;
      margin-right: 50px !important;
    }
    .my-10 {
      margin-top: 50px !important;
      margin-bottom: 50px !important;
    }
    .p-11 {
      padding: 55px !important;
    }
    .pt-11 {
      padding-top: 55px !important;
    }
    .pl-11 {
      padding-left: 55px !important;
    }
    .pr-11 {
      padding-right: 55px !important;
    }
    .pb-11 {
      padding-bottom: 55px !important;
    }
    .px-11 {
      padding-left: 55px !important;
      padding-right: 55px !important;
    }
    .py-11 {
      padding-top: 55px !important;
      padding-bottom: 55px !important;
    }
    .m-11 {
      margin: 55px !important;
    }
    .mt-11 {
      margin-top: 55px !important;
    }
    .ml-11 {
      margin-left: 55px !important;
    }
    .mr-11 {
      margin-right: 55px !important;
    }
    .mb-11 {
      margin-bottom: 55px !important;
    }
    .mx-11 {
      margin-left: 55px !important;
      margin-right: 55px !important;
    }
    .my-11 {
      margin-top: 55px !important;
      margin-bottom: 55px !important;
    }
    .p-12 {
      padding: 60px !important;
    }
    .pt-12 {
      padding-top: 60px !important;
    }
    .pl-12 {
      padding-left: 60px !important;
    }
    .pr-12 {
      padding-right: 60px !important;
    }
    .pb-12 {
      padding-bottom: 60px !important;
    }
    .px-12 {
      padding-left: 60px !important;
      padding-right: 60px !important;
    }
    .py-12 {
      padding-top: 60px !important;
      padding-bottom: 60px !important;
    }
    .m-12 {
      margin: 60px !important;
    }
    .mt-12 {
      margin-top: 60px !important;
    }
    .ml-12 {
      margin-left: 60px !important;
    }
    .mr-12 {
      margin-right: 60px !important;
    }
    .mb-12 {
      margin-bottom: 60px !important;
    }
    .mx-12 {
      margin-left: 60px !important;
      margin-right: 60px !important;
    }
    .my-12 {
      margin-top: 60px !important;
      margin-bottom: 60px !important;
    }
    .m-auto {
      margin: auto !important;
    }
    .mt-auto,
    .my-auto {
      margin-top: auto !important;
    }
    .mr-auto,
    .mx-auto {
      margin-right: auto !important;
    }
    .mb-auto,
    .my-auto {
      margin-bottom: auto !important;
    }
    .ml-auto,
    .mx-auto {
      margin-left: auto !important;
    }
  }
  @media (min-width: 768px) {
    .p-sm-0 {
      padding: 0px !important;
    }
    .pt-sm-0 {
      padding-top: 0px !important;
    }
    .pl-sm-0 {
      padding-left: 0px !important;
    }
    .pr-sm-0 {
      padding-right: 0px !important;
    }
    .pb-sm-0 {
      padding-bottom: 0px !important;
    }
    .px-sm-0 {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
    .py-sm-0 {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
    .m-sm-0 {
      margin: 0px !important;
    }
    .mt-sm-0 {
      margin-top: 0px !important;
    }
    .ml-sm-0 {
      margin-left: 0px !important;
    }
    .mr-sm-0 {
      margin-right: 0px !important;
    }
    .mb-sm-0 {
      margin-bottom: 0px !important;
    }
    .mx-sm-0 {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
    .my-sm-0 {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
    .p-sm-1 {
      padding: 5px !important;
    }
    .pt-sm-1 {
      padding-top: 5px !important;
    }
    .pl-sm-1 {
      padding-left: 5px !important;
    }
    .pr-sm-1 {
      padding-right: 5px !important;
    }
    .pb-sm-1 {
      padding-bottom: 5px !important;
    }
    .px-sm-1 {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
    .py-sm-1 {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }
    .m-sm-1 {
      margin: 5px !important;
    }
    .mt-sm-1 {
      margin-top: 5px !important;
    }
    .ml-sm-1 {
      margin-left: 5px !important;
    }
    .mr-sm-1 {
      margin-right: 5px !important;
    }
    .mb-sm-1 {
      margin-bottom: 5px !important;
    }
    .mx-sm-1 {
      margin-left: 5px !important;
      margin-right: 5px !important;
    }
    .my-sm-1 {
      margin-top: 5px !important;
      margin-bottom: 5px !important;
    }
    .p-sm-2 {
      padding: 10px !important;
    }
    .pt-sm-2 {
      padding-top: 10px !important;
    }
    .pl-sm-2 {
      padding-left: 10px !important;
    }
    .pr-sm-2 {
      padding-right: 10px !important;
    }
    .pb-sm-2 {
      padding-bottom: 10px !important;
    }
    .px-sm-2 {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
    .py-sm-2 {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
    .m-sm-2 {
      margin: 10px !important;
    }
    .mt-sm-2 {
      margin-top: 10px !important;
    }
    .ml-sm-2 {
      margin-left: 10px !important;
    }
    .mr-sm-2 {
      margin-right: 10px !important;
    }
    .mb-sm-2 {
      margin-bottom: 10px !important;
    }
    .mx-sm-2 {
      margin-left: 10px !important;
      margin-right: 10px !important;
    }
    .my-sm-2 {
      margin-top: 10px !important;
      margin-bottom: 10px !important;
    }
    .p-sm-3 {
      padding: 15px !important;
    }
    .pt-sm-3 {
      padding-top: 15px !important;
    }
    .pl-sm-3 {
      padding-left: 15px !important;
    }
    .pr-sm-3 {
      padding-right: 15px !important;
    }
    .pb-sm-3 {
      padding-bottom: 15px !important;
    }
    .px-sm-3 {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
    .py-sm-3 {
      padding-top: 15px !important;
      padding-bottom: 15px !important;
    }
    .m-sm-3 {
      margin: 15px !important;
    }
    .mt-sm-3 {
      margin-top: 15px !important;
    }
    .ml-sm-3 {
      margin-left: 15px !important;
    }
    .mr-sm-3 {
      margin-right: 15px !important;
    }
    .mb-sm-3 {
      margin-bottom: 15px !important;
    }
    .mx-sm-3 {
      margin-left: 15px !important;
      margin-right: 15px !important;
    }
    .my-sm-3 {
      margin-top: 15px !important;
      margin-bottom: 15px !important;
    }
    .p-sm-4 {
      padding: 20px !important;
    }
    .pt-sm-4 {
      padding-top: 20px !important;
    }
    .pl-sm-4 {
      padding-left: 20px !important;
    }
    .pr-sm-4 {
      padding-right: 20px !important;
    }
    .pb-sm-4 {
      padding-bottom: 20px !important;
    }
    .px-sm-4 {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
    .py-sm-4 {
      padding-top: 20px !important;
      padding-bottom: 20px !important;
    }
    .m-sm-4 {
      margin: 20px !important;
    }
    .mt-sm-4 {
      margin-top: 20px !important;
    }
    .ml-sm-4 {
      margin-left: 20px !important;
    }
    .mr-sm-4 {
      margin-right: 20px !important;
    }
    .mb-sm-4 {
      margin-bottom: 20px !important;
    }
    .mx-sm-4 {
      margin-left: 20px !important;
      margin-right: 20px !important;
    }
    .my-sm-4 {
      margin-top: 20px !important;
      margin-bottom: 20px !important;
    }
    .p-sm-5 {
      padding: 25px !important;
    }
    .pt-sm-5 {
      padding-top: 25px !important;
    }
    .pl-sm-5 {
      padding-left: 25px !important;
    }
    .pr-sm-5 {
      padding-right: 25px !important;
    }
    .pb-sm-5 {
      padding-bottom: 25px !important;
    }
    .px-sm-5 {
      padding-left: 25px !important;
      padding-right: 25px !important;
    }
    .py-sm-5 {
      padding-top: 25px !important;
      padding-bottom: 25px !important;
    }
    .m-sm-5 {
      margin: 25px !important;
    }
    .mt-sm-5 {
      margin-top: 25px !important;
    }
    .ml-sm-5 {
      margin-left: 25px !important;
    }
    .mr-sm-5 {
      margin-right: 25px !important;
    }
    .mb-sm-5 {
      margin-bottom: 25px !important;
    }
    .mx-sm-5 {
      margin-left: 25px !important;
      margin-right: 25px !important;
    }
    .my-sm-5 {
      margin-top: 25px !important;
      margin-bottom: 25px !important;
    }
    .p-sm-6 {
      padding: 30px !important;
    }
    .pt-sm-6 {
      padding-top: 30px !important;
    }
    .pl-sm-6 {
      padding-left: 30px !important;
    }
    .pr-sm-6 {
      padding-right: 30px !important;
    }
    .pb-sm-6 {
      padding-bottom: 30px !important;
    }
    .px-sm-6 {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
    .py-sm-6 {
      padding-top: 30px !important;
      padding-bottom: 30px !important;
    }
    .m-sm-6 {
      margin: 30px !important;
    }
    .mt-sm-6 {
      margin-top: 30px !important;
    }
    .ml-sm-6 {
      margin-left: 30px !important;
    }
    .mr-sm-6 {
      margin-right: 30px !important;
    }
    .mb-sm-6 {
      margin-bottom: 30px !important;
    }
    .mx-sm-6 {
      margin-left: 30px !important;
      margin-right: 30px !important;
    }
    .my-sm-6 {
      margin-top: 30px !important;
      margin-bottom: 30px !important;
    }
    .p-sm-7 {
      padding: 35px !important;
    }
    .pt-sm-7 {
      padding-top: 35px !important;
    }
    .pl-sm-7 {
      padding-left: 35px !important;
    }
    .pr-sm-7 {
      padding-right: 35px !important;
    }
    .pb-sm-7 {
      padding-bottom: 35px !important;
    }
    .px-sm-7 {
      padding-left: 35px !important;
      padding-right: 35px !important;
    }
    .py-sm-7 {
      padding-top: 35px !important;
      padding-bottom: 35px !important;
    }
    .m-sm-7 {
      margin: 35px !important;
    }
    .mt-sm-7 {
      margin-top: 35px !important;
    }
    .ml-sm-7 {
      margin-left: 35px !important;
    }
    .mr-sm-7 {
      margin-right: 35px !important;
    }
    .mb-sm-7 {
      margin-bottom: 35px !important;
    }
    .mx-sm-7 {
      margin-left: 35px !important;
      margin-right: 35px !important;
    }
    .my-sm-7 {
      margin-top: 35px !important;
      margin-bottom: 35px !important;
    }
    .p-sm-8 {
      padding: 40px !important;
    }
    .pt-sm-8 {
      padding-top: 40px !important;
    }
    .pl-sm-8 {
      padding-left: 40px !important;
    }
    .pr-sm-8 {
      padding-right: 40px !important;
    }
    .pb-sm-8 {
      padding-bottom: 40px !important;
    }
    .px-sm-8 {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }
    .py-sm-8 {
      padding-top: 40px !important;
      padding-bottom: 40px !important;
    }
    .m-sm-8 {
      margin: 40px !important;
    }
    .mt-sm-8 {
      margin-top: 40px !important;
    }
    .ml-sm-8 {
      margin-left: 40px !important;
    }
    .mr-sm-8 {
      margin-right: 40px !important;
    }
    .mb-sm-8 {
      margin-bottom: 40px !important;
    }
    .mx-sm-8 {
      margin-left: 40px !important;
      margin-right: 40px !important;
    }
    .my-sm-8 {
      margin-top: 40px !important;
      margin-bottom: 40px !important;
    }
    .p-sm-9 {
      padding: 45px !important;
    }
    .pt-sm-9 {
      padding-top: 45px !important;
    }
    .pl-sm-9 {
      padding-left: 45px !important;
    }
    .pr-sm-9 {
      padding-right: 45px !important;
    }
    .pb-sm-9 {
      padding-bottom: 45px !important;
    }
    .px-sm-9 {
      padding-left: 45px !important;
      padding-right: 45px !important;
    }
    .py-sm-9 {
      padding-top: 45px !important;
      padding-bottom: 45px !important;
    }
    .m-sm-9 {
      margin: 45px !important;
    }
    .mt-sm-9 {
      margin-top: 45px !important;
    }
    .ml-sm-9 {
      margin-left: 45px !important;
    }
    .mr-sm-9 {
      margin-right: 45px !important;
    }
    .mb-sm-9 {
      margin-bottom: 45px !important;
    }
    .mx-sm-9 {
      margin-left: 45px !important;
      margin-right: 45px !important;
    }
    .my-sm-9 {
      margin-top: 45px !important;
      margin-bottom: 45px !important;
    }
    .p-sm-10 {
      padding: 50px !important;
    }
    .pt-sm-10 {
      padding-top: 50px !important;
    }
    .pl-sm-10 {
      padding-left: 50px !important;
    }
    .pr-sm-10 {
      padding-right: 50px !important;
    }
    .pb-sm-10 {
      padding-bottom: 50px !important;
    }
    .px-sm-10 {
      padding-left: 50px !important;
      padding-right: 50px !important;
    }
    .py-sm-10 {
      padding-top: 50px !important;
      padding-bottom: 50px !important;
    }
    .m-sm-10 {
      margin: 50px !important;
    }
    .mt-sm-10 {
      margin-top: 50px !important;
    }
    .ml-sm-10 {
      margin-left: 50px !important;
    }
    .mr-sm-10 {
      margin-right: 50px !important;
    }
    .mb-sm-10 {
      margin-bottom: 50px !important;
    }
    .mx-sm-10 {
      margin-left: 50px !important;
      margin-right: 50px !important;
    }
    .my-sm-10 {
      margin-top: 50px !important;
      margin-bottom: 50px !important;
    }
    .p-sm-11 {
      padding: 55px !important;
    }
    .pt-sm-11 {
      padding-top: 55px !important;
    }
    .pl-sm-11 {
      padding-left: 55px !important;
    }
    .pr-sm-11 {
      padding-right: 55px !important;
    }
    .pb-sm-11 {
      padding-bottom: 55px !important;
    }
    .px-sm-11 {
      padding-left: 55px !important;
      padding-right: 55px !important;
    }
    .py-sm-11 {
      padding-top: 55px !important;
      padding-bottom: 55px !important;
    }
    .m-sm-11 {
      margin: 55px !important;
    }
    .mt-sm-11 {
      margin-top: 55px !important;
    }
    .ml-sm-11 {
      margin-left: 55px !important;
    }
    .mr-sm-11 {
      margin-right: 55px !important;
    }
    .mb-sm-11 {
      margin-bottom: 55px !important;
    }
    .mx-sm-11 {
      margin-left: 55px !important;
      margin-right: 55px !important;
    }
    .my-sm-11 {
      margin-top: 55px !important;
      margin-bottom: 55px !important;
    }
    .p-sm-12 {
      padding: 60px !important;
    }
    .pt-sm-12 {
      padding-top: 60px !important;
    }
    .pl-sm-12 {
      padding-left: 60px !important;
    }
    .pr-sm-12 {
      padding-right: 60px !important;
    }
    .pb-sm-12 {
      padding-bottom: 60px !important;
    }
    .px-sm-12 {
      padding-left: 60px !important;
      padding-right: 60px !important;
    }
    .py-sm-12 {
      padding-top: 60px !important;
      padding-bottom: 60px !important;
    }
    .m-sm-12 {
      margin: 60px !important;
    }
    .mt-sm-12 {
      margin-top: 60px !important;
    }
    .ml-sm-12 {
      margin-left: 60px !important;
    }
    .mr-sm-12 {
      margin-right: 60px !important;
    }
    .mb-sm-12 {
      margin-bottom: 60px !important;
    }
    .mx-sm-12 {
      margin-left: 60px !important;
      margin-right: 60px !important;
    }
    .my-sm-12 {
      margin-top: 60px !important;
      margin-bottom: 60px !important;
    }
    .m-sm-auto {
      margin: auto !important;
    }
    .mt-sm-auto,
    .my-sm-auto {
      margin-top: auto !important;
    }
    .mr-sm-auto,
    .mx-sm-auto {
      margin-right: auto !important;
    }
    .mb-sm-auto,
    .my-sm-auto {
      margin-bottom: auto !important;
    }
    .ml-sm-auto,
    .mx-sm-auto {
      margin-left: auto !important;
    }
  }
  @media (min-width: 992px) {
    .p-md-0 {
      padding: 0px !important;
    }
    .pt-md-0 {
      padding-top: 0px !important;
    }
    .pl-md-0 {
      padding-left: 0px !important;
    }
    .pr-md-0 {
      padding-right: 0px !important;
    }
    .pb-md-0 {
      padding-bottom: 0px !important;
    }
    .px-md-0 {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
    .py-md-0 {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
    .m-md-0 {
      margin: 0px !important;
    }
    .mt-md-0 {
      margin-top: 0px !important;
    }
    .ml-md-0 {
      margin-left: 0px !important;
    }
    .mr-md-0 {
      margin-right: 0px !important;
    }
    .mb-md-0 {
      margin-bottom: 0px !important;
    }
    .mx-md-0 {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
    .my-md-0 {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
    .p-md-1 {
      padding: 5px !important;
    }
    .pt-md-1 {
      padding-top: 5px !important;
    }
    .pl-md-1 {
      padding-left: 5px !important;
    }
    .pr-md-1 {
      padding-right: 5px !important;
    }
    .pb-md-1 {
      padding-bottom: 5px !important;
    }
    .px-md-1 {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
    .py-md-1 {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }
    .m-md-1 {
      margin: 5px !important;
    }
    .mt-md-1 {
      margin-top: 5px !important;
    }
    .ml-md-1 {
      margin-left: 5px !important;
    }
    .mr-md-1 {
      margin-right: 5px !important;
    }
    .mb-md-1 {
      margin-bottom: 5px !important;
    }
    .mx-md-1 {
      margin-left: 5px !important;
      margin-right: 5px !important;
    }
    .my-md-1 {
      margin-top: 5px !important;
      margin-bottom: 5px !important;
    }
    .p-md-2 {
      padding: 10px !important;
    }
    .pt-md-2 {
      padding-top: 10px !important;
    }
    .pl-md-2 {
      padding-left: 10px !important;
    }
    .pr-md-2 {
      padding-right: 10px !important;
    }
    .pb-md-2 {
      padding-bottom: 10px !important;
    }
    .px-md-2 {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
    .py-md-2 {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
    .m-md-2 {
      margin: 10px !important;
    }
    .mt-md-2 {
      margin-top: 10px !important;
    }
    .ml-md-2 {
      margin-left: 10px !important;
    }
    .mr-md-2 {
      margin-right: 10px !important;
    }
    .mb-md-2 {
      margin-bottom: 10px !important;
    }
    .mx-md-2 {
      margin-left: 10px !important;
      margin-right: 10px !important;
    }
    .my-md-2 {
      margin-top: 10px !important;
      margin-bottom: 10px !important;
    }
    .p-md-3 {
      padding: 15px !important;
    }
    .pt-md-3 {
      padding-top: 15px !important;
    }
    .pl-md-3 {
      padding-left: 15px !important;
    }
    .pr-md-3 {
      padding-right: 15px !important;
    }
    .pb-md-3 {
      padding-bottom: 15px !important;
    }
    .px-md-3 {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
    .py-md-3 {
      padding-top: 15px !important;
      padding-bottom: 15px !important;
    }
    .m-md-3 {
      margin: 15px !important;
    }
    .mt-md-3 {
      margin-top: 15px !important;
    }
    .ml-md-3 {
      margin-left: 15px !important;
    }
    .mr-md-3 {
      margin-right: 15px !important;
    }
    .mb-md-3 {
      margin-bottom: 15px !important;
    }
    .mx-md-3 {
      margin-left: 15px !important;
      margin-right: 15px !important;
    }
    .my-md-3 {
      margin-top: 15px !important;
      margin-bottom: 15px !important;
    }
    .p-md-4 {
      padding: 20px !important;
    }
    .pt-md-4 {
      padding-top: 20px !important;
    }
    .pl-md-4 {
      padding-left: 20px !important;
    }
    .pr-md-4 {
      padding-right: 20px !important;
    }
    .pb-md-4 {
      padding-bottom: 20px !important;
    }
    .px-md-4 {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
    .py-md-4 {
      padding-top: 20px !important;
      padding-bottom: 20px !important;
    }
    .m-md-4 {
      margin: 20px !important;
    }
    .mt-md-4 {
      margin-top: 20px !important;
    }
    .ml-md-4 {
      margin-left: 20px !important;
    }
    .mr-md-4 {
      margin-right: 20px !important;
    }
    .mb-md-4 {
      margin-bottom: 20px !important;
    }
    .mx-md-4 {
      margin-left: 20px !important;
      margin-right: 20px !important;
    }
    .my-md-4 {
      margin-top: 20px !important;
      margin-bottom: 20px !important;
    }
    .p-md-5 {
      padding: 25px !important;
    }
    .pt-md-5 {
      padding-top: 25px !important;
    }
    .pl-md-5 {
      padding-left: 25px !important;
    }
    .pr-md-5 {
      padding-right: 25px !important;
    }
    .pb-md-5 {
      padding-bottom: 25px !important;
    }
    .px-md-5 {
      padding-left: 25px !important;
      padding-right: 25px !important;
    }
    .py-md-5 {
      padding-top: 25px !important;
      padding-bottom: 25px !important;
    }
    .m-md-5 {
      margin: 25px !important;
    }
    .mt-md-5 {
      margin-top: 25px !important;
    }
    .ml-md-5 {
      margin-left: 25px !important;
    }
    .mr-md-5 {
      margin-right: 25px !important;
    }
    .mb-md-5 {
      margin-bottom: 25px !important;
    }
    .mx-md-5 {
      margin-left: 25px !important;
      margin-right: 25px !important;
    }
    .my-md-5 {
      margin-top: 25px !important;
      margin-bottom: 25px !important;
    }
    .p-md-6 {
      padding: 30px !important;
    }
    .pt-md-6 {
      padding-top: 30px !important;
    }
    .pl-md-6 {
      padding-left: 30px !important;
    }
    .pr-md-6 {
      padding-right: 30px !important;
    }
    .pb-md-6 {
      padding-bottom: 30px !important;
    }
    .px-md-6 {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
    .py-md-6 {
      padding-top: 30px !important;
      padding-bottom: 30px !important;
    }
    .m-md-6 {
      margin: 30px !important;
    }
    .mt-md-6 {
      margin-top: 30px !important;
    }
    .ml-md-6 {
      margin-left: 30px !important;
    }
    .mr-md-6 {
      margin-right: 30px !important;
    }
    .mb-md-6 {
      margin-bottom: 30px !important;
    }
    .mx-md-6 {
      margin-left: 30px !important;
      margin-right: 30px !important;
    }
    .my-md-6 {
      margin-top: 30px !important;
      margin-bottom: 30px !important;
    }
    .p-md-7 {
      padding: 35px !important;
    }
    .pt-md-7 {
      padding-top: 35px !important;
    }
    .pl-md-7 {
      padding-left: 35px !important;
    }
    .pr-md-7 {
      padding-right: 35px !important;
    }
    .pb-md-7 {
      padding-bottom: 35px !important;
    }
    .px-md-7 {
      padding-left: 35px !important;
      padding-right: 35px !important;
    }
    .py-md-7 {
      padding-top: 35px !important;
      padding-bottom: 35px !important;
    }
    .m-md-7 {
      margin: 35px !important;
    }
    .mt-md-7 {
      margin-top: 35px !important;
    }
    .ml-md-7 {
      margin-left: 35px !important;
    }
    .mr-md-7 {
      margin-right: 35px !important;
    }
    .mb-md-7 {
      margin-bottom: 35px !important;
    }
    .mx-md-7 {
      margin-left: 35px !important;
      margin-right: 35px !important;
    }
    .my-md-7 {
      margin-top: 35px !important;
      margin-bottom: 35px !important;
    }
    .p-md-8 {
      padding: 40px !important;
    }
    .pt-md-8 {
      padding-top: 40px !important;
    }
    .pl-md-8 {
      padding-left: 40px !important;
    }
    .pr-md-8 {
      padding-right: 40px !important;
    }
    .pb-md-8 {
      padding-bottom: 40px !important;
    }
    .px-md-8 {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }
    .py-md-8 {
      padding-top: 40px !important;
      padding-bottom: 40px !important;
    }
    .m-md-8 {
      margin: 40px !important;
    }
    .mt-md-8 {
      margin-top: 40px !important;
    }
    .ml-md-8 {
      margin-left: 40px !important;
    }
    .mr-md-8 {
      margin-right: 40px !important;
    }
    .mb-md-8 {
      margin-bottom: 40px !important;
    }
    .mx-md-8 {
      margin-left: 40px !important;
      margin-right: 40px !important;
    }
    .my-md-8 {
      margin-top: 40px !important;
      margin-bottom: 40px !important;
    }
    .p-md-9 {
      padding: 45px !important;
    }
    .pt-md-9 {
      padding-top: 45px !important;
    }
    .pl-md-9 {
      padding-left: 45px !important;
    }
    .pr-md-9 {
      padding-right: 45px !important;
    }
    .pb-md-9 {
      padding-bottom: 45px !important;
    }
    .px-md-9 {
      padding-left: 45px !important;
      padding-right: 45px !important;
    }
    .py-md-9 {
      padding-top: 45px !important;
      padding-bottom: 45px !important;
    }
    .m-md-9 {
      margin: 45px !important;
    }
    .mt-md-9 {
      margin-top: 45px !important;
    }
    .ml-md-9 {
      margin-left: 45px !important;
    }
    .mr-md-9 {
      margin-right: 45px !important;
    }
    .mb-md-9 {
      margin-bottom: 45px !important;
    }
    .mx-md-9 {
      margin-left: 45px !important;
      margin-right: 45px !important;
    }
    .my-md-9 {
      margin-top: 45px !important;
      margin-bottom: 45px !important;
    }
    .p-md-10 {
      padding: 50px !important;
    }
    .pt-md-10 {
      padding-top: 50px !important;
    }
    .pl-md-10 {
      padding-left: 50px !important;
    }
    .pr-md-10 {
      padding-right: 50px !important;
    }
    .pb-md-10 {
      padding-bottom: 50px !important;
    }
    .px-md-10 {
      padding-left: 50px !important;
      padding-right: 50px !important;
    }
    .py-md-10 {
      padding-top: 50px !important;
      padding-bottom: 50px !important;
    }
    .m-md-10 {
      margin: 50px !important;
    }
    .mt-md-10 {
      margin-top: 50px !important;
    }
    .ml-md-10 {
      margin-left: 50px !important;
    }
    .mr-md-10 {
      margin-right: 50px !important;
    }
    .mb-md-10 {
      margin-bottom: 50px !important;
    }
    .mx-md-10 {
      margin-left: 50px !important;
      margin-right: 50px !important;
    }
    .my-md-10 {
      margin-top: 50px !important;
      margin-bottom: 50px !important;
    }
    .p-md-11 {
      padding: 55px !important;
    }
    .pt-md-11 {
      padding-top: 55px !important;
    }
    .pl-md-11 {
      padding-left: 55px !important;
    }
    .pr-md-11 {
      padding-right: 55px !important;
    }
    .pb-md-11 {
      padding-bottom: 55px !important;
    }
    .px-md-11 {
      padding-left: 55px !important;
      padding-right: 55px !important;
    }
    .py-md-11 {
      padding-top: 55px !important;
      padding-bottom: 55px !important;
    }
    .m-md-11 {
      margin: 55px !important;
    }
    .mt-md-11 {
      margin-top: 55px !important;
    }
    .ml-md-11 {
      margin-left: 55px !important;
    }
    .mr-md-11 {
      margin-right: 55px !important;
    }
    .mb-md-11 {
      margin-bottom: 55px !important;
    }
    .mx-md-11 {
      margin-left: 55px !important;
      margin-right: 55px !important;
    }
    .my-md-11 {
      margin-top: 55px !important;
      margin-bottom: 55px !important;
    }
    .p-md-12 {
      padding: 60px !important;
    }
    .pt-md-12 {
      padding-top: 60px !important;
    }
    .pl-md-12 {
      padding-left: 60px !important;
    }
    .pr-md-12 {
      padding-right: 60px !important;
    }
    .pb-md-12 {
      padding-bottom: 60px !important;
    }
    .px-md-12 {
      padding-left: 60px !important;
      padding-right: 60px !important;
    }
    .py-md-12 {
      padding-top: 60px !important;
      padding-bottom: 60px !important;
    }
    .m-md-12 {
      margin: 60px !important;
    }
    .mt-md-12 {
      margin-top: 60px !important;
    }
    .ml-md-12 {
      margin-left: 60px !important;
    }
    .mr-md-12 {
      margin-right: 60px !important;
    }
    .mb-md-12 {
      margin-bottom: 60px !important;
    }
    .mx-md-12 {
      margin-left: 60px !important;
      margin-right: 60px !important;
    }
    .my-md-12 {
      margin-top: 60px !important;
      margin-bottom: 60px !important;
    }
    .m-md-auto {
      margin: auto !important;
    }
    .mt-md-auto,
    .my-md-auto {
      margin-top: auto !important;
    }
    .mr-md-auto,
    .mx-md-auto {
      margin-right: auto !important;
    }
    .mb-md-auto,
    .my-md-auto {
      margin-bottom: auto !important;
    }
    .ml-md-auto,
    .mx-md-auto {
      margin-left: auto !important;
    }
  }
  @media (min-width: 1200px) {
    .p-lg-0 {
      padding: 0px !important;
    }
    .pt-lg-0 {
      padding-top: 0px !important;
    }
    .pl-lg-0 {
      padding-left: 0px !important;
    }
    .pr-lg-0 {
      padding-right: 0px !important;
    }
    .pb-lg-0 {
      padding-bottom: 0px !important;
    }
    .px-lg-0 {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
    .py-lg-0 {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
    .m-lg-0 {
      margin: 0px !important;
    }
    .mt-lg-0 {
      margin-top: 0px !important;
    }
    .ml-lg-0 {
      margin-left: 0px !important;
    }
    .mr-lg-0 {
      margin-right: 0px !important;
    }
    .mb-lg-0 {
      margin-bottom: 0px !important;
    }
    .mx-lg-0 {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
    .my-lg-0 {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
    .p-lg-1 {
      padding: 5px !important;
    }
    .pt-lg-1 {
      padding-top: 5px !important;
    }
    .pl-lg-1 {
      padding-left: 5px !important;
    }
    .pr-lg-1 {
      padding-right: 5px !important;
    }
    .pb-lg-1 {
      padding-bottom: 5px !important;
    }
    .px-lg-1 {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
    .py-lg-1 {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }
    .m-lg-1 {
      margin: 5px !important;
    }
    .mt-lg-1 {
      margin-top: 5px !important;
    }
    .ml-lg-1 {
      margin-left: 5px !important;
    }
    .mr-lg-1 {
      margin-right: 5px !important;
    }
    .mb-lg-1 {
      margin-bottom: 5px !important;
    }
    .mx-lg-1 {
      margin-left: 5px !important;
      margin-right: 5px !important;
    }
    .my-lg-1 {
      margin-top: 5px !important;
      margin-bottom: 5px !important;
    }
    .p-lg-2 {
      padding: 10px !important;
    }
    .pt-lg-2 {
      padding-top: 10px !important;
    }
    .pl-lg-2 {
      padding-left: 10px !important;
    }
    .pr-lg-2 {
      padding-right: 10px !important;
    }
    .pb-lg-2 {
      padding-bottom: 10px !important;
    }
    .px-lg-2 {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
    .py-lg-2 {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
    .m-lg-2 {
      margin: 10px !important;
    }
    .mt-lg-2 {
      margin-top: 10px !important;
    }
    .ml-lg-2 {
      margin-left: 10px !important;
    }
    .mr-lg-2 {
      margin-right: 10px !important;
    }
    .mb-lg-2 {
      margin-bottom: 10px !important;
    }
    .mx-lg-2 {
      margin-left: 10px !important;
      margin-right: 10px !important;
    }
    .my-lg-2 {
      margin-top: 10px !important;
      margin-bottom: 10px !important;
    }
    .p-lg-3 {
      padding: 15px !important;
    }
    .pt-lg-3 {
      padding-top: 15px !important;
    }
    .pl-lg-3 {
      padding-left: 15px !important;
    }
    .pr-lg-3 {
      padding-right: 15px !important;
    }
    .pb-lg-3 {
      padding-bottom: 15px !important;
    }
    .px-lg-3 {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
    .py-lg-3 {
      padding-top: 15px !important;
      padding-bottom: 15px !important;
    }
    .m-lg-3 {
      margin: 15px !important;
    }
    .mt-lg-3 {
      margin-top: 15px !important;
    }
    .ml-lg-3 {
      margin-left: 15px !important;
    }
    .mr-lg-3 {
      margin-right: 15px !important;
    }
    .mb-lg-3 {
      margin-bottom: 15px !important;
    }
    .mx-lg-3 {
      margin-left: 15px !important;
      margin-right: 15px !important;
    }
    .my-lg-3 {
      margin-top: 15px !important;
      margin-bottom: 15px !important;
    }
    .p-lg-4 {
      padding: 20px !important;
    }
    .pt-lg-4 {
      padding-top: 20px !important;
    }
    .pl-lg-4 {
      padding-left: 20px !important;
    }
    .pr-lg-4 {
      padding-right: 20px !important;
    }
    .pb-lg-4 {
      padding-bottom: 20px !important;
    }
    .px-lg-4 {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
    .py-lg-4 {
      padding-top: 20px !important;
      padding-bottom: 20px !important;
    }
    .m-lg-4 {
      margin: 20px !important;
    }
    .mt-lg-4 {
      margin-top: 20px !important;
    }
    .ml-lg-4 {
      margin-left: 20px !important;
    }
    .mr-lg-4 {
      margin-right: 20px !important;
    }
    .mb-lg-4 {
      margin-bottom: 20px !important;
    }
    .mx-lg-4 {
      margin-left: 20px !important;
      margin-right: 20px !important;
    }
    .my-lg-4 {
      margin-top: 20px !important;
      margin-bottom: 20px !important;
    }
    .p-lg-5 {
      padding: 25px !important;
    }
    .pt-lg-5 {
      padding-top: 25px !important;
    }
    .pl-lg-5 {
      padding-left: 25px !important;
    }
    .pr-lg-5 {
      padding-right: 25px !important;
    }
    .pb-lg-5 {
      padding-bottom: 25px !important;
    }
    .px-lg-5 {
      padding-left: 25px !important;
      padding-right: 25px !important;
    }
    .py-lg-5 {
      padding-top: 25px !important;
      padding-bottom: 25px !important;
    }
    .m-lg-5 {
      margin: 25px !important;
    }
    .mt-lg-5 {
      margin-top: 25px !important;
    }
    .ml-lg-5 {
      margin-left: 25px !important;
    }
    .mr-lg-5 {
      margin-right: 25px !important;
    }
    .mb-lg-5 {
      margin-bottom: 25px !important;
    }
    .mx-lg-5 {
      margin-left: 25px !important;
      margin-right: 25px !important;
    }
    .my-lg-5 {
      margin-top: 25px !important;
      margin-bottom: 25px !important;
    }
    .p-lg-6 {
      padding: 30px !important;
    }
    .pt-lg-6 {
      padding-top: 30px !important;
    }
    .pl-lg-6 {
      padding-left: 30px !important;
    }
    .pr-lg-6 {
      padding-right: 30px !important;
    }
    .pb-lg-6 {
      padding-bottom: 30px !important;
    }
    .px-lg-6 {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
    .py-lg-6 {
      padding-top: 30px !important;
      padding-bottom: 30px !important;
    }
    .m-lg-6 {
      margin: 30px !important;
    }
    .mt-lg-6 {
      margin-top: 30px !important;
    }
    .ml-lg-6 {
      margin-left: 30px !important;
    }
    .mr-lg-6 {
      margin-right: 30px !important;
    }
    .mb-lg-6 {
      margin-bottom: 30px !important;
    }
    .mx-lg-6 {
      margin-left: 30px !important;
      margin-right: 30px !important;
    }
    .my-lg-6 {
      margin-top: 30px !important;
      margin-bottom: 30px !important;
    }
    .p-lg-7 {
      padding: 35px !important;
    }
    .pt-lg-7 {
      padding-top: 35px !important;
    }
    .pl-lg-7 {
      padding-left: 35px !important;
    }
    .pr-lg-7 {
      padding-right: 35px !important;
    }
    .pb-lg-7 {
      padding-bottom: 35px !important;
    }
    .px-lg-7 {
      padding-left: 35px !important;
      padding-right: 35px !important;
    }
    .py-lg-7 {
      padding-top: 35px !important;
      padding-bottom: 35px !important;
    }
    .m-lg-7 {
      margin: 35px !important;
    }
    .mt-lg-7 {
      margin-top: 35px !important;
    }
    .ml-lg-7 {
      margin-left: 35px !important;
    }
    .mr-lg-7 {
      margin-right: 35px !important;
    }
    .mb-lg-7 {
      margin-bottom: 35px !important;
    }
    .mx-lg-7 {
      margin-left: 35px !important;
      margin-right: 35px !important;
    }
    .my-lg-7 {
      margin-top: 35px !important;
      margin-bottom: 35px !important;
    }
    .p-lg-8 {
      padding: 40px !important;
    }
    .pt-lg-8 {
      padding-top: 40px !important;
    }
    .pl-lg-8 {
      padding-left: 40px !important;
    }
    .pr-lg-8 {
      padding-right: 40px !important;
    }
    .pb-lg-8 {
      padding-bottom: 40px !important;
    }
    .px-lg-8 {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }
    .py-lg-8 {
      padding-top: 40px !important;
      padding-bottom: 40px !important;
    }
    .m-lg-8 {
      margin: 40px !important;
    }
    .mt-lg-8 {
      margin-top: 40px !important;
    }
    .ml-lg-8 {
      margin-left: 40px !important;
    }
    .mr-lg-8 {
      margin-right: 40px !important;
    }
    .mb-lg-8 {
      margin-bottom: 40px !important;
    }
    .mx-lg-8 {
      margin-left: 40px !important;
      margin-right: 40px !important;
    }
    .my-lg-8 {
      margin-top: 40px !important;
      margin-bottom: 40px !important;
    }
    .p-lg-9 {
      padding: 45px !important;
    }
    .pt-lg-9 {
      padding-top: 45px !important;
    }
    .pl-lg-9 {
      padding-left: 45px !important;
    }
    .pr-lg-9 {
      padding-right: 45px !important;
    }
    .pb-lg-9 {
      padding-bottom: 45px !important;
    }
    .px-lg-9 {
      padding-left: 45px !important;
      padding-right: 45px !important;
    }
    .py-lg-9 {
      padding-top: 45px !important;
      padding-bottom: 45px !important;
    }
    .m-lg-9 {
      margin: 45px !important;
    }
    .mt-lg-9 {
      margin-top: 45px !important;
    }
    .ml-lg-9 {
      margin-left: 45px !important;
    }
    .mr-lg-9 {
      margin-right: 45px !important;
    }
    .mb-lg-9 {
      margin-bottom: 45px !important;
    }
    .mx-lg-9 {
      margin-left: 45px !important;
      margin-right: 45px !important;
    }
    .my-lg-9 {
      margin-top: 45px !important;
      margin-bottom: 45px !important;
    }
    .p-lg-10 {
      padding: 50px !important;
    }
    .pt-lg-10 {
      padding-top: 50px !important;
    }
    .pl-lg-10 {
      padding-left: 50px !important;
    }
    .pr-lg-10 {
      padding-right: 50px !important;
    }
    .pb-lg-10 {
      padding-bottom: 50px !important;
    }
    .px-lg-10 {
      padding-left: 50px !important;
      padding-right: 50px !important;
    }
    .py-lg-10 {
      padding-top: 50px !important;
      padding-bottom: 50px !important;
    }
    .m-lg-10 {
      margin: 50px !important;
    }
    .mt-lg-10 {
      margin-top: 50px !important;
    }
    .ml-lg-10 {
      margin-left: 50px !important;
    }
    .mr-lg-10 {
      margin-right: 50px !important;
    }
    .mb-lg-10 {
      margin-bottom: 50px !important;
    }
    .mx-lg-10 {
      margin-left: 50px !important;
      margin-right: 50px !important;
    }
    .my-lg-10 {
      margin-top: 50px !important;
      margin-bottom: 50px !important;
    }
    .p-lg-11 {
      padding: 55px !important;
    }
    .pt-lg-11 {
      padding-top: 55px !important;
    }
    .pl-lg-11 {
      padding-left: 55px !important;
    }
    .pr-lg-11 {
      padding-right: 55px !important;
    }
    .pb-lg-11 {
      padding-bottom: 55px !important;
    }
    .px-lg-11 {
      padding-left: 55px !important;
      padding-right: 55px !important;
    }
    .py-lg-11 {
      padding-top: 55px !important;
      padding-bottom: 55px !important;
    }
    .m-lg-11 {
      margin: 55px !important;
    }
    .mt-lg-11 {
      margin-top: 55px !important;
    }
    .ml-lg-11 {
      margin-left: 55px !important;
    }
    .mr-lg-11 {
      margin-right: 55px !important;
    }
    .mb-lg-11 {
      margin-bottom: 55px !important;
    }
    .mx-lg-11 {
      margin-left: 55px !important;
      margin-right: 55px !important;
    }
    .my-lg-11 {
      margin-top: 55px !important;
      margin-bottom: 55px !important;
    }
    .p-lg-12 {
      padding: 60px !important;
    }
    .pt-lg-12 {
      padding-top: 60px !important;
    }
    .pl-lg-12 {
      padding-left: 60px !important;
    }
    .pr-lg-12 {
      padding-right: 60px !important;
    }
    .pb-lg-12 {
      padding-bottom: 60px !important;
    }
    .px-lg-12 {
      padding-left: 60px !important;
      padding-right: 60px !important;
    }
    .py-lg-12 {
      padding-top: 60px !important;
      padding-bottom: 60px !important;
    }
    .m-lg-12 {
      margin: 60px !important;
    }
    .mt-lg-12 {
      margin-top: 60px !important;
    }
    .ml-lg-12 {
      margin-left: 60px !important;
    }
    .mr-lg-12 {
      margin-right: 60px !important;
    }
    .mb-lg-12 {
      margin-bottom: 60px !important;
    }
    .mx-lg-12 {
      margin-left: 60px !important;
      margin-right: 60px !important;
    }
    .my-lg-12 {
      margin-top: 60px !important;
      margin-bottom: 60px !important;
    }
    .m-lg-auto {
      margin: auto !important;
    }
    .mt-lg-auto,
    .my-lg-auto {
      margin-top: auto !important;
    }
    .mr-lg-auto,
    .mx-lg-auto {
      margin-right: auto !important;
    }
    .mb-lg-auto,
    .my-lg-auto {
      margin-bottom: auto !important;
    }
    .ml-lg-auto,
    .mx-lg-auto {
      margin-left: auto !important;
    }
  }
  @media (min-width: 1920px) {
    .p-xl-0 {
      padding: 0px !important;
    }
    .pt-xl-0 {
      padding-top: 0px !important;
    }
    .pl-xl-0 {
      padding-left: 0px !important;
    }
    .pr-xl-0 {
      padding-right: 0px !important;
    }
    .pb-xl-0 {
      padding-bottom: 0px !important;
    }
    .px-xl-0 {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
    .py-xl-0 {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
    .m-xl-0 {
      margin: 0px !important;
    }
    .mt-xl-0 {
      margin-top: 0px !important;
    }
    .ml-xl-0 {
      margin-left: 0px !important;
    }
    .mr-xl-0 {
      margin-right: 0px !important;
    }
    .mb-xl-0 {
      margin-bottom: 0px !important;
    }
    .mx-xl-0 {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
    .my-xl-0 {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
    .p-xl-1 {
      padding: 5px !important;
    }
    .pt-xl-1 {
      padding-top: 5px !important;
    }
    .pl-xl-1 {
      padding-left: 5px !important;
    }
    .pr-xl-1 {
      padding-right: 5px !important;
    }
    .pb-xl-1 {
      padding-bottom: 5px !important;
    }
    .px-xl-1 {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
    .py-xl-1 {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }
    .m-xl-1 {
      margin: 5px !important;
    }
    .mt-xl-1 {
      margin-top: 5px !important;
    }
    .ml-xl-1 {
      margin-left: 5px !important;
    }
    .mr-xl-1 {
      margin-right: 5px !important;
    }
    .mb-xl-1 {
      margin-bottom: 5px !important;
    }
    .mx-xl-1 {
      margin-left: 5px !important;
      margin-right: 5px !important;
    }
    .my-xl-1 {
      margin-top: 5px !important;
      margin-bottom: 5px !important;
    }
    .p-xl-2 {
      padding: 10px !important;
    }
    .pt-xl-2 {
      padding-top: 10px !important;
    }
    .pl-xl-2 {
      padding-left: 10px !important;
    }
    .pr-xl-2 {
      padding-right: 10px !important;
    }
    .pb-xl-2 {
      padding-bottom: 10px !important;
    }
    .px-xl-2 {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
    .py-xl-2 {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
    .m-xl-2 {
      margin: 10px !important;
    }
    .mt-xl-2 {
      margin-top: 10px !important;
    }
    .ml-xl-2 {
      margin-left: 10px !important;
    }
    .mr-xl-2 {
      margin-right: 10px !important;
    }
    .mb-xl-2 {
      margin-bottom: 10px !important;
    }
    .mx-xl-2 {
      margin-left: 10px !important;
      margin-right: 10px !important;
    }
    .my-xl-2 {
      margin-top: 10px !important;
      margin-bottom: 10px !important;
    }
    .p-xl-3 {
      padding: 15px !important;
    }
    .pt-xl-3 {
      padding-top: 15px !important;
    }
    .pl-xl-3 {
      padding-left: 15px !important;
    }
    .pr-xl-3 {
      padding-right: 15px !important;
    }
    .pb-xl-3 {
      padding-bottom: 15px !important;
    }
    .px-xl-3 {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
    .py-xl-3 {
      padding-top: 15px !important;
      padding-bottom: 15px !important;
    }
    .m-xl-3 {
      margin: 15px !important;
    }
    .mt-xl-3 {
      margin-top: 15px !important;
    }
    .ml-xl-3 {
      margin-left: 15px !important;
    }
    .mr-xl-3 {
      margin-right: 15px !important;
    }
    .mb-xl-3 {
      margin-bottom: 15px !important;
    }
    .mx-xl-3 {
      margin-left: 15px !important;
      margin-right: 15px !important;
    }
    .my-xl-3 {
      margin-top: 15px !important;
      margin-bottom: 15px !important;
    }
    .p-xl-4 {
      padding: 20px !important;
    }
    .pt-xl-4 {
      padding-top: 20px !important;
    }
    .pl-xl-4 {
      padding-left: 20px !important;
    }
    .pr-xl-4 {
      padding-right: 20px !important;
    }
    .pb-xl-4 {
      padding-bottom: 20px !important;
    }
    .px-xl-4 {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
    .py-xl-4 {
      padding-top: 20px !important;
      padding-bottom: 20px !important;
    }
    .m-xl-4 {
      margin: 20px !important;
    }
    .mt-xl-4 {
      margin-top: 20px !important;
    }
    .ml-xl-4 {
      margin-left: 20px !important;
    }
    .mr-xl-4 {
      margin-right: 20px !important;
    }
    .mb-xl-4 {
      margin-bottom: 20px !important;
    }
    .mx-xl-4 {
      margin-left: 20px !important;
      margin-right: 20px !important;
    }
    .my-xl-4 {
      margin-top: 20px !important;
      margin-bottom: 20px !important;
    }
    .p-xl-5 {
      padding: 25px !important;
    }
    .pt-xl-5 {
      padding-top: 25px !important;
    }
    .pl-xl-5 {
      padding-left: 25px !important;
    }
    .pr-xl-5 {
      padding-right: 25px !important;
    }
    .pb-xl-5 {
      padding-bottom: 25px !important;
    }
    .px-xl-5 {
      padding-left: 25px !important;
      padding-right: 25px !important;
    }
    .py-xl-5 {
      padding-top: 25px !important;
      padding-bottom: 25px !important;
    }
    .m-xl-5 {
      margin: 25px !important;
    }
    .mt-xl-5 {
      margin-top: 25px !important;
    }
    .ml-xl-5 {
      margin-left: 25px !important;
    }
    .mr-xl-5 {
      margin-right: 25px !important;
    }
    .mb-xl-5 {
      margin-bottom: 25px !important;
    }
    .mx-xl-5 {
      margin-left: 25px !important;
      margin-right: 25px !important;
    }
    .my-xl-5 {
      margin-top: 25px !important;
      margin-bottom: 25px !important;
    }
    .p-xl-6 {
      padding: 30px !important;
    }
    .pt-xl-6 {
      padding-top: 30px !important;
    }
    .pl-xl-6 {
      padding-left: 30px !important;
    }
    .pr-xl-6 {
      padding-right: 30px !important;
    }
    .pb-xl-6 {
      padding-bottom: 30px !important;
    }
    .px-xl-6 {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
    .py-xl-6 {
      padding-top: 30px !important;
      padding-bottom: 30px !important;
    }
    .m-xl-6 {
      margin: 30px !important;
    }
    .mt-xl-6 {
      margin-top: 30px !important;
    }
    .ml-xl-6 {
      margin-left: 30px !important;
    }
    .mr-xl-6 {
      margin-right: 30px !important;
    }
    .mb-xl-6 {
      margin-bottom: 30px !important;
    }
    .mx-xl-6 {
      margin-left: 30px !important;
      margin-right: 30px !important;
    }
    .my-xl-6 {
      margin-top: 30px !important;
      margin-bottom: 30px !important;
    }
    .p-xl-7 {
      padding: 35px !important;
    }
    .pt-xl-7 {
      padding-top: 35px !important;
    }
    .pl-xl-7 {
      padding-left: 35px !important;
    }
    .pr-xl-7 {
      padding-right: 35px !important;
    }
    .pb-xl-7 {
      padding-bottom: 35px !important;
    }
    .px-xl-7 {
      padding-left: 35px !important;
      padding-right: 35px !important;
    }
    .py-xl-7 {
      padding-top: 35px !important;
      padding-bottom: 35px !important;
    }
    .m-xl-7 {
      margin: 35px !important;
    }
    .mt-xl-7 {
      margin-top: 35px !important;
    }
    .ml-xl-7 {
      margin-left: 35px !important;
    }
    .mr-xl-7 {
      margin-right: 35px !important;
    }
    .mb-xl-7 {
      margin-bottom: 35px !important;
    }
    .mx-xl-7 {
      margin-left: 35px !important;
      margin-right: 35px !important;
    }
    .my-xl-7 {
      margin-top: 35px !important;
      margin-bottom: 35px !important;
    }
    .p-xl-8 {
      padding: 40px !important;
    }
    .pt-xl-8 {
      padding-top: 40px !important;
    }
    .pl-xl-8 {
      padding-left: 40px !important;
    }
    .pr-xl-8 {
      padding-right: 40px !important;
    }
    .pb-xl-8 {
      padding-bottom: 40px !important;
    }
    .px-xl-8 {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }
    .py-xl-8 {
      padding-top: 40px !important;
      padding-bottom: 40px !important;
    }
    .m-xl-8 {
      margin: 40px !important;
    }
    .mt-xl-8 {
      margin-top: 40px !important;
    }
    .ml-xl-8 {
      margin-left: 40px !important;
    }
    .mr-xl-8 {
      margin-right: 40px !important;
    }
    .mb-xl-8 {
      margin-bottom: 40px !important;
    }
    .mx-xl-8 {
      margin-left: 40px !important;
      margin-right: 40px !important;
    }
    .my-xl-8 {
      margin-top: 40px !important;
      margin-bottom: 40px !important;
    }
    .p-xl-9 {
      padding: 45px !important;
    }
    .pt-xl-9 {
      padding-top: 45px !important;
    }
    .pl-xl-9 {
      padding-left: 45px !important;
    }
    .pr-xl-9 {
      padding-right: 45px !important;
    }
    .pb-xl-9 {
      padding-bottom: 45px !important;
    }
    .px-xl-9 {
      padding-left: 45px !important;
      padding-right: 45px !important;
    }
    .py-xl-9 {
      padding-top: 45px !important;
      padding-bottom: 45px !important;
    }
    .m-xl-9 {
      margin: 45px !important;
    }
    .mt-xl-9 {
      margin-top: 45px !important;
    }
    .ml-xl-9 {
      margin-left: 45px !important;
    }
    .mr-xl-9 {
      margin-right: 45px !important;
    }
    .mb-xl-9 {
      margin-bottom: 45px !important;
    }
    .mx-xl-9 {
      margin-left: 45px !important;
      margin-right: 45px !important;
    }
    .my-xl-9 {
      margin-top: 45px !important;
      margin-bottom: 45px !important;
    }
    .p-xl-10 {
      padding: 50px !important;
    }
    .pt-xl-10 {
      padding-top: 50px !important;
    }
    .pl-xl-10 {
      padding-left: 50px !important;
    }
    .pr-xl-10 {
      padding-right: 50px !important;
    }
    .pb-xl-10 {
      padding-bottom: 50px !important;
    }
    .px-xl-10 {
      padding-left: 50px !important;
      padding-right: 50px !important;
    }
    .py-xl-10 {
      padding-top: 50px !important;
      padding-bottom: 50px !important;
    }
    .m-xl-10 {
      margin: 50px !important;
    }
    .mt-xl-10 {
      margin-top: 50px !important;
    }
    .ml-xl-10 {
      margin-left: 50px !important;
    }
    .mr-xl-10 {
      margin-right: 50px !important;
    }
    .mb-xl-10 {
      margin-bottom: 50px !important;
    }
    .mx-xl-10 {
      margin-left: 50px !important;
      margin-right: 50px !important;
    }
    .my-xl-10 {
      margin-top: 50px !important;
      margin-bottom: 50px !important;
    }
    .p-xl-11 {
      padding: 55px !important;
    }
    .pt-xl-11 {
      padding-top: 55px !important;
    }
    .pl-xl-11 {
      padding-left: 55px !important;
    }
    .pr-xl-11 {
      padding-right: 55px !important;
    }
    .pb-xl-11 {
      padding-bottom: 55px !important;
    }
    .px-xl-11 {
      padding-left: 55px !important;
      padding-right: 55px !important;
    }
    .py-xl-11 {
      padding-top: 55px !important;
      padding-bottom: 55px !important;
    }
    .m-xl-11 {
      margin: 55px !important;
    }
    .mt-xl-11 {
      margin-top: 55px !important;
    }
    .ml-xl-11 {
      margin-left: 55px !important;
    }
    .mr-xl-11 {
      margin-right: 55px !important;
    }
    .mb-xl-11 {
      margin-bottom: 55px !important;
    }
    .mx-xl-11 {
      margin-left: 55px !important;
      margin-right: 55px !important;
    }
    .my-xl-11 {
      margin-top: 55px !important;
      margin-bottom: 55px !important;
    }
    .p-xl-12 {
      padding: 60px !important;
    }
    .pt-xl-12 {
      padding-top: 60px !important;
    }
    .pl-xl-12 {
      padding-left: 60px !important;
    }
    .pr-xl-12 {
      padding-right: 60px !important;
    }
    .pb-xl-12 {
      padding-bottom: 60px !important;
    }
    .px-xl-12 {
      padding-left: 60px !important;
      padding-right: 60px !important;
    }
    .py-xl-12 {
      padding-top: 60px !important;
      padding-bottom: 60px !important;
    }
    .m-xl-12 {
      margin: 60px !important;
    }
    .mt-xl-12 {
      margin-top: 60px !important;
    }
    .ml-xl-12 {
      margin-left: 60px !important;
    }
    .mr-xl-12 {
      margin-right: 60px !important;
    }
    .mb-xl-12 {
      margin-bottom: 60px !important;
    }
    .mx-xl-12 {
      margin-left: 60px !important;
      margin-right: 60px !important;
    }
    .my-xl-12 {
      margin-top: 60px !important;
      margin-bottom: 60px !important;
    }
    .m-xl-auto {
      margin: auto !important;
    }
    .mt-xl-auto,
    .my-xl-auto {
      margin-top: auto !important;
    }
    .mr-xl-auto,
    .mx-xl-auto {
      margin-right: auto !important;
    }
    .mb-xl-auto,
    .my-xl-auto {
      margin-bottom: auto !important;
    }
    .ml-xl-auto,
    .mx-xl-auto {
      margin-left: auto !important;
    }
  }