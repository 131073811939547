@import "@monsido/colors/dist/colors.scss";
html,
body {
    text-rendering: optimizeLegibility;
}

h2 {
    color: $secondary-4;
    font-size: 18px;
    line-height: 14px;

    span.icon {
        font-size: 16px;
    }
    span.smaller {
        font-size: 12px;
    }
}

.text-bold {
    font-weight: bold;
}

.text-italic {
    font-style: italic;
}

.mon-text-left {
    text-align: left !important;
}

.mon-disabled {
    color: $text-grey !important;
    &:hover {
        cursor: not-allowed !important;
    }
    * {
        color: $text-grey !important;
    }
}

.text-underline {
    text-decoration: underline !important;
}

.text-wrap {
    white-space: normal !important;
}

.text-v-top {
    vertical-align: top !important;
}

.text-v-text-top {
    vertical-align: text-top !important;
}

.text-size-h3 {
    font-size: 21px;
}

.error-message {
    color: $secondary-9;
}

.text-secondary-4 {
    color: $secondary-4;
}

.text-secondary-5 {
    color: $secondary-5;
}

.text-left           { text-align: left; }
.text-right          { text-align: right; }
.text-center         { text-align: center; }
.text-justify        { text-align: justify; }
.text-nowrap         { white-space: nowrap; }
