.changes-history-dashboard-summary-performance-container {
    .score-doughnut-container {
        .doughnut {
            max-width: 195px;
            max-height: 195px;
        }
    }
    .checkpoint-container {
        min-width: 215px;
        position: relative;
        padding-left: 15px;

        .ruler-fmp {
            background-color: $fmp-color;
        }

        .ruler-fcp {
            background-color: $fcp-color;
        }

        .ruler-lcp {
            background-color: $secondary-12;
        }
        
        .ruler-tti {
            background-color: $tti-color;
        }

        .ruler-si {
            background-color: $branding-6;
        }

        .ruler-tbt {
            background-color: $secondary-15;
        }

        .ruler-cls {
            background-color: $secondary-11;
        }

        .vertical-ruler {
            bottom: 10px;
        }

        .notification {
            padding: 0 !important;
            ul {
                li:last-child {
                    margin-top: 5px !important;
                }
            }
        }

        .empty-metric-notification {
            ul {
                li:nth-child(2) {
                    & > span:first-child {
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}