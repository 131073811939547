.cookie-statistics-distribution-bar {
    width: 100%;

    .progress {
        margin-bottom: 0;
        box-shadow: none;
        margin-right: 0 !important;
    }

    .progress-xxs .progress,
    .progress-xxs.progress {
        background-color: $secondary-1;
        height: 10px;
    }

    .progress.progress-square,
    .progress-square .progress {
        border-radius: 0;
    }

    .progress-bar {
        height: 100%;
        background-color: $branding-6;

        &.progress-bar-basic {
            background-color: $branding-6;
        }

        &.progress-bar-preferences {
            background-color: $branding-4;
        }

        &.progress-bar-marketing {
            background-color: #fdc50d;
        }

        &.progress-bar-statistics {
            background-color: #5e3ea8;
        }
    }
}
