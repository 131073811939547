.table-filters {
    padding: 0 20px 10px 20px;

    .selector-input {
        border: none;
        box-shadow: none !important;
    }

    & > p {
        margin: 0 15px 0 0;
    }

    .table-filters-selector-container {
        .selector-dropdown {
            border-top: 1px solid #ccc;
        }
    }

    .table-filters-clear {
        cursor: pointer;
    }

    &.with-border-b {
        border-bottom: 1px solid $panel-default-border;
    }
}
