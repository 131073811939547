#page-details-dashboard {
    margin-top: 15px;

    @media (min-width: $screen-lg-min) {
        .dashboard-grid-container {
            & > .col-lg-24:nth-child(2n) {
                padding-left: 0 !important;
            }
            & > .col-lg-24:nth-child(2n + 1) {
                padding-right: 0 !important;
            }
        }
    }

    .readability-score {
        border: 1px solid #e3e3e3;
        border-radius: 5px;
        padding: 0 8px;
        text-align: center;
        display: inline-block;
        margin-right: 3px;
    }

    .page-dashboard-doughnut-container {
        .doughnut {
            max-width: 130px;
            max-height: 130px;
        }
    }

    .language-editable {
        border-bottom-color: #0088cc;
        border-bottom-style: dashed;
        border-bottom-width: 1px;
    }
}
