@import 'variables.scss';

.note-popover.popover.in.note-table-popover.bottom {
  + .modal-backdrop { //Since summernote is captured inside another fixed container, it, for some reason, won't set it's fixed position relative to the body container.
    z-index: 10000 !important;
  }
}

$mon-dialog-header-height: 116px;
$mon-dialog-footer-height: 70px;

.mon-dialog-header {
    background-color: #fff;
    position: absolute;
    top: 0;
    height: $mon-dialog-header-height;
    width: 100%;
    padding: 0 20px 8px 63px;
    border-bottom: 1px solid $secondary-6 !important;
}

.mon-dialog-body {
    position: absolute;
    top: $mon-dialog-header-height;
    bottom: 0;
    background: $secondary-1;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
}

.mon-dialog-body-with-footer {
    position: absolute;
    top: $mon-dialog-header-height;
    background: $secondary-1;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    bottom: $mon-dialog-footer-height;
}

.mon-dialog-footer {
    background-color: #fff;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: $mon-dialog-footer-height;
    border-top: 3px solid $secondary-6;
    padding-top: 23px;
    padding-right: 23px;
}

.mon-dialog-body,
.mon-dialog-body-with-footer {
  &.auto-scroll {
      overflow-y: auto;
  }
}
