.domain-groups-match-label-fields {
    input[type=text] {
        height: 34px;
    }

    .row {
        align-items: center;
    }

    .ngx-select_multiple .ngx-select__search {
        height: 26px !important;
    }

    .btn, .btn-sm, .btn-group-sm > .btn {
        font-size: 12px;
        padding: 6px 12px;
    }
}
