//Set the same amount of order columns as there are grid columns
$mon-flex-grid-columns: $grid-columns;

.flex-fill-column-width {
    flex: 1;
}

.flex-basis-pixel-100 {
    flex-basis: 100px;
}
.flex-basis-pixel-200 {
    flex-basis: 200px;
}
.flex-basis-pixel-300 {
    flex-basis: 300px;
}
.flex-basis-pixel-400 {
    flex-basis: 400px;
}
.flex-basis-pixel-500 {
    flex-basis: 500px;
}
.flex-basis-pixel-600 {
    flex-basis: 600px;
}
.flex-basis-pixel-700 {
    flex-basis: 700px;
}
.flex-basis-auto {
    flex-basis: auto;
}