.page-details-page-performance-dashboard-container {
    .performance-score-doughnut-chart-container {
        max-width: 25%;
    }
    .performance-score-doughnut-indicators {
        padding-right: 0px;
    }

    .performance-score-line-chart {
        padding-left: 0px;
        padding-right: 30px;
    }
}
