@import "@monsido/colors/dist/colors";
$accessibility-link-color: $secondary-11;
$accessibility-grey-color: #595959;
$accessibility-dark-grey-color: $accessibility-grey-color;
$accessibility-menu-search-background: $branding-4;

$accessibility-theme-base-color: #162237;

$accessibility-light-green: #006633;

$accessibility-btn-orange-color: #f49956;
$accessibility-btn-secondary-border: #e4e4e4;
