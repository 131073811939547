.compliance-graph {
  .circle {
    border: solid;
    @include set-border-radius(50%);
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    width: 100vw;
    height: 100vw;
  }
}
