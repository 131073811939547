$label-primary-text-color: #fff;
$label-success-text-color: #3c763d;
$label-info-text-color: #31708f;
$label-warning-text-color: #8a6d3b;
$label-danger-text-color: #a94442;

.label-fixed {
  width: 50px;
  display: block;
  padding: 6px 0;
}

.label-primary {
  color: $label-primary-text-color;
}

.label-success {
  color: $label-success-text-color;
}

.label-info {
  color: $label-info-text-color;
}

.label-warning {
  color: $label-warning-text-color;
}

.label-danger {
  color: $label-danger-text-color;
}