ul.page-details-tab {
    margin-bottom: 3px;
    margin-top: 15px;

    > li:not(.divider) {
        line-height: 6px;
        padding: 11px;
        border-bottom: 2px solid transparent;

        &.active {
            border-bottom: 2px solid $link-color;
        }
        
    }

}
