.document-details-view {
    height: 100%;
    overflow-x: hidden;

    .document-details-heading {
        .document-details-title {
            max-width: calc(100% - 170px);
            .label-container {
                min-width: 70px;
            }
        }
    }

    .inverse-shadow {
        -webkit-box-shadow: inset 5px 5px 5px -5px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: inset 5px 5px 5px -5px rgba(0, 0, 0, 0.3);
        box-shadow: inset 5px 5px 5px -5px rgba(0, 0, 0, 0.3);
    }

    .table {
        tr {
            &.active,
            &.active:hover {
                border-right: 0;
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;

                td {
                    background-color: #f6f9fb;
                }
            }
        }
    }
}
