.statistics-summary-general {
    .width-auto {
        width: auto !important;
    }

    &.visitor-summary {
        statistics-information {
            & > div {
                padding-bottom: 15px;
                border-top: 5px solid transparent;
                padding-top: 10px;
                padding-left: 0;
                text-align: center;
            }
        }
        & > div:hover {
            background-color: #eee;
        }
        & > .active {
            statistics-information > div {
                border-top-color: #50a6fb;
            }
        }
    }
}
