$border-radius: 5px;
.mon-spotlight {
    & > a {
        padding-top: 17px;
        padding-bottom: 17px;
        text-decoration: none;
    }

    &.tooltip {
        .tooltip-inner {
            max-width: 300px;
        }
    }

    #spotlight.ng-spotlight-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: fade(#000, 45%);
        display: block;
        overflow: hidden;
        color: #000;
        cursor: default;
        font-family: "Roboto", "Arial Narrow", sans-serif;
        z-index: 10000;
        font-size: 12px;

        a {
            color: $link-color !important;
            &:hover {
                color: $link-hover-color !important;
            }
        }

        .ng-spotlight-container {
            position: fixed;
            left: 20%;
            top: 35%;
            width: 60%;
            clear: both;

            .ng-spotlight-main-theme {
                background-color: #fff;
                height: 100%;
                width: 100%;
                overflow: hidden;
                clear: both;
                padding: 10px 10px 0 10px;
                @include set-border-radius($border-radius);
                box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
                    0 17px 50px 0 rgba(0, 0, 0, 0.19);

                .ng-spotlight-searchbar {
                    padding-bottom: 10px;
                    .input-group:first-child {
                        .input-group-prepend .input-group-text {
                            border: none;
                            border-color: transparent;
                            background-color: transparent;
                            &:first-child {
                                color: #000;
                            }
                        }

                        input[type="search"].form-control {
                            border: none;
                            border-color: transparent;
                            padding-top: 8px;
                            font-size: 1.2em;
                            color: $dark-grey;
                            box-shadow: none;

                            &::-webkit-input-placeholder {
                                color: $dark-grey;
                            }

                            &:-moz-placeholder {
                                /* Firefox 18- */
                                color: $dark-grey;
                            }

                            &::-moz-placeholder {
                                /* Firefox 19+ */
                                color: $dark-grey;
                            }

                            &:-ms-input-placeholder {
                                color: $dark-grey;
                            }
                        }
                    }
                }

                .ng-spotlight-result-panel {
                    height: 400px;
                    border-top: 1px solid $text-grey;
                    @include set-border-radius(0 0 $border-radius $border-radius);

                    &.ng-hide {
                        display: none !important;
                        overflow: hidden;
                    }

                    .ng-spotlight-search-results {
                        height: 100%;
                        min-height: 100%;
                        overflow-y: scroll;
                        padding-bottom: 10px;
                        padding-left: 0;
                        padding-right: 0;

                        table > tbody > tr {
                            &.active > td {
                                background-color: #f5f9fc;
                            }

                            & > td {
                                padding-left: 15px;
                                padding-right: 15px;
                                &:first-child {
                                    border-top: none;
                                    border-color: transparent;
                                }
                            }
                        }
                    }

                    .ng-spotlight-result-view {
                        height: 100%;
                        min-height: 100%;
                        padding-bottom: 10px;
                        background-color: #f5f9fc;
                        border-radius: 0 0 5px 0;
                    }
                }
            }
        }

        .full-width {
            width: 100%;
            max-width: 100%;
        }

        .inner-b-t-1 {
            padding-top: 1px;
            padding-bottom: 1px;
        }
    }
}
