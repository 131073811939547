.table {

    --bs-table-hover-bg: #f5f5f5;

    &.table-hover > tbody > tr:hover > td {
      background-color: #f5f5f5;
      --bs-table-accent-bg: transparent;
      /* color: var(--bs-table-hover-color); */
    }

    & > tbody > tr > td {
      border-color: $gray-lighter;
    }

    &.remove-border-on-first-row {
      & > tbody > tr:first-child > td {
        border-top: none;
      }
    }

    &.remove-table-header-bottom-border {
      th {
        border-bottom: 0;
      }
    }

    &.table-borderless {
      td,
      th {
        border: none;
      }
    }

    tr.selected {
        background-color: #e4edf3 !important;
    }

    .label-table .btn-label-edit:not(:hover) {
        color: #647288;
        background-color: #eaebef;
        border-color: #eaebef;
    }
}

.table-condensed {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: 5px;
      }
    }
  }
}

.table-hover>tbody>tr:hover, .table>tbody>tr.active>td, .table>tbody>tr.active>th, .table>tbody>tr>td.active, .table>tbody>tr>th.active, .table>tfoot>tr.active>td, .table>tfoot>tr.active>th, .table>tfoot>tr>td.active, .table>tfoot>tr>th.active, .table>thead>tr.active>td, .table>thead>tr.active>th, .table>thead>tr>td.active, .table>thead>tr>th.active {
  background-color: #f5f5f5;
}
