.accessibility-pdf {
  .icon-circle {
    width: 36px;
    height: 36px;
  }

  mon-tabs {
    ng-transclude {
      width: 100%;
    }
  }
}
