.statistics {
    chart-pie {
        display: block;
        min-width: 250px;
        min-height: 250px;
    }

    .chart-center {
        .chart {
            margin: auto;
        }
    }

    .table-comparison-cell {
        background-color: transparentize($main-1, 0.9);
    }
}

.table-pager-without-page-size {
    .table-container-paginate {
        > div:last-child {
            > div:only-child {
                margin-left: auto;
            }
        }
    }
}

.daterangepicker-date-range-container {
    .daterangepicker-calendar-btn-color {
        background-color: inherit;
    }
}

.daterangepicker-enable-compare-container {
    .form-group {
        padding: 0;
    }

    input[type=checkbox] {
        margin-right: 5px;
    }

    label {
        display: flex;
        padding: 0;
    }
}
