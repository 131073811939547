.policy-boxes-element {
    .icon-btn {
        color: $main-1;
        position: relative;
        background: $color-white;
        box-shadow: 0px 0px 3px 1px $shadow-color;
        border: 1px solid var(--secondary-6);
        border-radius: 3px;
        opacity: 1;

        &:hover {
            box-shadow: 0px 1px 5px 3px $shadow-color;
            color: $secondary-10;
        }
    }

    .desc-tooltip {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 15px;
    }

    .tooltip-inner {
        max-width: none;
        white-space: nowrap;
    }
    div.rule-wrapper {
        padding: 0;
    }

    button {
        background: none;
        border: none;
        display: flex;
        width: 100%;

        &:focus-visible {
            outline: none;
            box-shadow: 0 0 0 1px $secondary-14;
        }

        &[aria-expanded="true"] {
            opacity: 0.5;
        }
    }

    .accessibleDNDRuleMenu {
        display: none;

        &.open {
            display: block;
        }
    }

    .keyboard-dnd-tooltip {
        width: 200px;
        max-width: 100%;

        .tooltip-inner {
            white-space: normal;
        }
    }
}
