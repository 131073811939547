.page-fix-form-marquee-options {
    .setting-container {
        padding: 20px 15px 20px 10px;
        background-color: $grey-color-4;
        border-radius: 3px;
        cursor: pointer;
        border-left-width: 5px;
        border-left-style: solid;
        border-left-color: transparent;

        &.active {
            border-left-color: $blue-color-1;
            cursor: default;
        }
    }

    .preview {
        background-color: $blue-color-3;
    }
}
