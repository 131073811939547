.data-privacy,
.privacy-violation-pages {
    .border-l {
        border-left-width: 1px;
        border-left-color: $border-grey-color;
        border-left-style: solid;
    }

    .border-r {
        border-right-width: 1px;
        border-right-color: $border-grey-color;
        border-right-style: solid;
        &-md {
            border-right: 0;
            @media (min-width: 1200px) {
                border-right: 1px solid $border-grey-color;
            }
        }
    }

    .border-b {
        border-bottom-width: 1px;
        border-bottom-color: $border-grey-color;
        border-bottom-style: solid;
        &-md {
            border-bottom: 1px solid $border-grey-color;
            @media (min-width: 1200px) {
                border-bottom: 0;
            }
        }
    }
    .data-privacy-compliance-progress-bar {
        box-shadow: none;
        border-radius: 0;
        height: 20px;
        margin-bottom: 0;
        .progress-bar {
            background-color: #57b2b7;
            box-shadow: none;
        }
    }
}
