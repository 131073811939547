.policy-exchange-center-labels {
    min-height: 43px;

    & > ul {
        display: inline-block;
        margin-left: 0;

        & > li.label-item {
            background-color: $blue-color-1;
            color: $color-white;
            border-radius: 20px;
            padding: 5px 10px;
        }
    }
}
