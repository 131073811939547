.visitory-summary-doughnut__first {
  background-color: #3da3e9;
}

.visitory-summary-doughnut__second {
  background-color: #89cdfd;
}

.visitory-summary-doughnut__third {
  background-color: #3669d3;
}

.stats_doughnut {
  max-height: 120px;
  max-width: 120px;
}
