@use "sass:math";
@import "../variants/slice";
@import "../variants/dougnut";

@mixin doughnut-chart-content($perc, $line-color, $base-color) { 
    @if ($perc < 50) {
    $deg1: ($perc / 100*360+90);
    $deg2: 0deg;
  
    @include doughnut-variant($line-color);
    .slices {
      &.mon-doughnut-slice-one {
        -webkit-transform: rotate('#{$deg1}deg'); //Concatenate degree value
        transform: rotate('#{$deg1}deg'); //Concatenate degree value
        @include doughnut-slice-variant($base-color);
      }
  
      &.mon-doughnut-slice-two {
        -webkit-transform: rotate($deg2);
        transform: rotate($deg2);
        @include doughnut-slice-variant($base-color);
      }
   } 
  }
}

@mixin doughnut-chart-content($perc, $line-color, $base-color) {
    @if ($perc > 49){
        $deg: (math.div($perc, 100)*360);
        $deg1: 90deg;
      
        @include doughnut-variant($base-color);
        .slices {
          &.mon-doughnut-slice-one {
            -webkit-transform: rotate($deg1);
            transform: rotate($deg1);
            @include doughnut-slice-variant($line-color);
          }
      
          &.mon-doughnut-slice-two {
            -webkit-transform: rotate('#{$deg}deg'); //Concatenate degree value
            transform: rotate('#{$deg}deg'); //Concatenate degree value
            @include doughnut-slice-variant($line-color);
          }
        }
    }
}

@mixin loop-doughnut-charts($index, $line-color, $base-color) {
    @if ($index >= 0){
        &.chart-point-#{$index} {
          @include doughnut-chart-content($index, $line-color, $base-color);
        }
        // next iteration
        @include loop-doughnut-charts(($index - 1), $line-color, $base-color);
    }
}

@mixin make-doughnut-graphs($graphs, $line-color, $base-color){
  @include loop-doughnut-charts($graphs, $line-color, $base-color);
}