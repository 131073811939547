notifications-icon {
    .version-box .bootbox-body {
        text-align: center;
        font-size: 1.1em;
    }

    .dropdown-menu-wide {
        width: 400px;
        padding-bottom: 0;

        ul {
            list-style-type: none;
            max-height: 210px;
            padding: 0;

            .navbar.navbar-fixed-top & {
                font-size: 12px;
                line-height: 14px;

                a {
                    padding: 0;

                    div {
                        height: 14px;
                        width: 18px;

                        &:first-child {
                            text-align: center;
                        }
                    }

                    &:hover {
                        background-color: $grey-color-3;
                        color: $grey-color-1;
                    }
                }

                .active-color {
                    color: $blue-color-1;
                }
            }
        }
        .more-notifications {
            border-top: 1px solid $grey-color-4;

            a {
                display: block;
                padding: 9px 0 8px 23px;
            }
        }
    }

    .notification-info {
        max-width: 400px;
        height: 100%;
    }
    .notification-time {
        flex-basis: 100px;
        text-align: right;
    }

    .no-new-notifications {
        padding: 6px 10px 10px 23px;
    }

    .count-wrapper {
        mon-spinner-snake {
            fill: $dark-grey;

            .accessibility-mode & {
                fill: $color-white;
            }
        }
    }

    .count-wrapper:hover {
        mon-spinner-snake {
            fill: $blue-color-1;

            .accessibility-mode & {
                fill: $turqoise-color;
            }
        }
    }

    mon-spinner-snake {
        position: absolute;
        width: 40px;
        height: 40px;
        left: -3px;
        top: -4px;
    }
}
