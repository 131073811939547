.load-times-trend-container {
    .load-times-trend-header {
        .load-times-trend-title {
            .load-times-trend-title-text {
                font-weight: bold;
                font-size: 1.3em;
            }           
        }
        .rulers-section-container {
            padding: 20px 0;
            .ruler-container {
                position: relative;
                margin-bottom: 20px;
                min-width: 190px;
                .ruler-text-container {
                    padding-left: 15px;
                    .ruler-text-value {
                        font-size: 1.3em;
                    }
                    .ruler-text-sub {
                        color: $secondary-5;
                    }
                }
                .ruler-fmp {
                    background-color: $fmp-color;
                }
                .ruler-fcp {
                    background-color: $fcp-color;
                }
                .ruler-lcp {
                    background-color: $secondary-12;
                }
                .ruler-tti {
                    background-color: $tti-color;
                }
                .ruler-si {
                    background-color: $branding-6;
                }
                .ruler-tbt {
                    background-color: $secondary-15;
                }
                .ruler-cls {
                    background-color: $secondary-11;
                }
            }
        }
    }
}
