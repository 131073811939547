.border-l {
    border-left-style: solid;
}

.border-r {
    border-right-style: solid;
}

.border-t {
    border-top-style: solid;
}

.border-b {
    border-bottom-style: solid;
}

.border-size-1 {
    border-width: 1px;
}

.border-t-size-2 {
    border-top-width: 2px;
}

.border-size-3 {
    border-width: 3px;
}

.border-light-grey {
    border-color: $light-grey;
}

.border-grey {
    border-color: #ddd;
}

.border-link {
    border-color: $link-color;
}

.flat {
    @include set-border-radius(0);

    &.btn {
        @include set-border-radius(0);
    }

    &.panel {
        @include set-border-radius(0);
    }

    &.label {
        @include set-border-radius(0);
    }

    &.progress {
        @include set-border-radius(0);
    }
}

.border-dashed {
    border-style: dashed;
}

.border-transparent {
    border-color: transparent;
}
