$heatmapRuleCloseColor: #3f3f3f;

.heatmap-dialogs-pages {
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: fade(#000, 45%);
        overflow: hidden;
        cursor: default;
        z-index: 10000;
    }

    .heatmap-rule-container {
        position: fixed;
        left: 20%;
        top: 35%;
        width: 60%;
        overflow: hidden;
        z-index: 10000;
        @include set-border-radius(6px);
        background-color: #fff;

        .input-group {
            .input-group-text,
            input.form-control {
                @include set-border-radius(0);
            }

            input {
                &::-webkit-input-placeholder {
                    color: $dark-grey;
                }

                &:-moz-placeholder {
                    /* Firefox 18- */
                    color: $dark-grey;
                }

                &::-moz-placeholder {
                    /* Firefox 19+ */
                    color: $dark-grey;
                }

                &:-ms-input-placeholder {
                    color: $dark-grey;
                }
            }
        }

        .pages-container {
            height: 300px;
            overflow-y: scroll;
            overflow-x: hidden;

            table {
                margin-top: -18px;

                thead {
                    width: 0;
                    height: 0;
                    visibility: hidden;
                    padding: 0;
                }
            }
        }
    }
    .d-flex-ellipsis {
        url-title {
            min-width: 0;
        }
    }
}
