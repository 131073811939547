$label-width: 28px;
$label-line-height: 11px;
$label-font-size: 11px;
$label-py: 2px;

.label-table {
  .label-btn {
    border: 0;
    border-color: transparent;
    background-color: transparent;
    &:focus-visible {
      outline: none;
      box-shadow: 0 0 0 1px $secondary-14;
    }
    .label {
      display: inline-block;
      min-width: $label-width;
      min-height: 8px;
      padding: $label-py 7px;
      font-size: $label-font-size;
      line-height: $label-font-size;
      margin-top: 3px;
      margin-bottom: 3px;

      &:not(:last-child) {
        margin-right: 5px;
      }

      span {
        overflow: hidden;

        &.ng-enter, &.ng-leave.ng-leave-active {
          animation: 0.5s label_text_disappear linear;
        }

        &.ng-leave, &.ng-enter.ng-enter-active {
          animation: 0.5s label_text_appear linear;
        }
      }
    }
  }

  .btn-label-edit {
    opacity: 0;
    padding: $label-py 5px;
    line-height: $label-line-height;
    font-size: $label-font-size;
    position: relative;
    @include button-variant(#647288, #EAEBEF, #EAEBEF);

    &.no-labels {
      margin-top: -20px;
    }

    &:focus, &:hover, &:active, &:focus-within {
      opacity: 1;
    }
    &:focus-visible {
      opacity: 1;
      outline: 0;
      box-shadow: 0 0 0 1px $secondary-14;
    }
  }
}

tr:hover .btn-label-edit {
  opacity: 1;
}

@keyframes label_text_appear {
  0% {
    width: $label-width;
    height: 8px;
    color: transparent;
  }
  25% {
    width: fit-content;
    height: 8px;
  }
  45% {
    height: 8px;
  }
  53% {
    height: 8px;
  }
  75% {
    height: 12px;
    color: transparent;
  }
}

@keyframes label_text_disappear {
  0% {
    height: 12px;
    width: fit-content;
  }
  33% {
    color: transparent;
    height: 12px;
    width: fit-content;
  }
  66% {
    height: 8px;
    width: fit-content;
    color: transparent;
  }
  100% {
    color: transparent;
    height: 8px;
    width: $label-width;
  }
}
