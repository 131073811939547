html {
    @media only screen and (max-height: 600px) {
        overflow-y: auto !important;
    }
}

body {
    overflow-y: hidden;
}

legend {
    width: auto;
}

.cfp-hotkeys-container.fade.in {
    z-index: 20000;
}

.remove-float {
    float: none;
}

.max-width, .w-100 {
    width: 100%;
}

.max-height {
    min-height: 100%;
    height: 100%;
}

.min-height {
    height: auto;
    min-height: 100%;
}

.section-img-responsive img {
    display: inline-block;
    max-width: 100%;
    height: auto;
}

.scroll-y {
    overflow-y: auto;
}

.highlight-span {
    display: inline-block;
    margin-top: 7px;
    width: calc(100% - 50px);
}

.chosen-container-single .chosen-single div b {
    background: url(/images/chosen-sprite.png) no-repeat 0 2px;
}

.keyboard-shortcut {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.1em 0.5em;
    margin: 0 0.2em;
    box-shadow: 0 1px 0px rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
    background-color: #f7f7f7;
    color: #000;
}

.tooltip {
    z-index: 20000;
}

[ui-sref] {
    cursor: pointer;
}

.position-static {
    position: static;
}

input[type="search"] {
    box-sizing: initial;
}

mon-form-field-dynamic.field-with-label {
    --mon-form-field-dynamic-input-display: inline-block;
    --mon-form-field-dynamic-input-width: calc(100% - 80px);
}

.center-block {
    margin: 0 auto;
}