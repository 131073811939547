.mon-accessibility-distribution-container {
    height: auto;
    margin: auto;

    .check-fixed,
    .check-a,
    .check-aa,
    .check-aaa {
        display: inline-block;
        height: 20px;
        text-align: center;
        padding-top: 3px;
        color: #fff;
        float: left;
        font-size: 0.8em;
    }

    .check-fixed {
        background-color: #57b2b7;
    }

    .check-a {
        background-color: $wcag-a;
    }

    .check-aa {
        background-color: $wcag-aa;
    }

    .check-aaa {
        background-color: $wcag-aaa;
    }
}
