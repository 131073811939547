@import "mixins.scss";

.card {

    margin-bottom: 17px;

    monsido-panel {
        @include panel-variant(transparent, $gray-dark, #FFFFFF, $panel-default-border);
        @include set-border-radius(2px);

        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);

        .card-header {
            h1, h2, h3, h4, h5 {
                margin-top: 7px;
            }
        }

        .card-body {
            padding-top: 30px;
            padding-bottom: 30px;
        }
  }
}

.panel-group {
    .card{
        .card-header {
            h4.card-title {
                font-size: 21px;
            }
        }
    }
}

.monsido-panel .card-header h1,
.monsido-panel .card-header h2,
.monsido-panel .card-header h3,
.monsido-panel .card-header h4,
.monsido-panel .card-header h5 {
    margin-top: 7px;
}

.card-footer {
    padding: 10px 15px;
    border-top: 1px solid #ddd;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}