.label-new {
    @include label-variant(#1e91f8);
    &.label {
        padding-top: 3.2px;
        padding-bottom: 3.2px;
        font-weight: 600;
    }
}

.label-monsido-dark-green {
    @include label-variant(darken($monsido-green, 15%));
    @include set-border-radius(0);
    font-weight: 500;
    color: #fff;
}

.label-monsido-green {
    @include label-variant($monsido-green);
}

.label-magenta {
    @include label-variant($magenta);
}

.label-dodger-blue {
    @include label-variant($link-color);
}

.label-orange {
    @include label-variant(#ffba00);
    color: #000000;
}

.label-policy-new {
    @include label-variant($color-purple);
    color: $color-white;
}




.label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #FFFFFF;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;

    // [converter] extracted a& to a.label

    // Empty labels collapse automatically (not available in IE8)
    &:empty {
      display: none;
    }

    // Quick fix for labels in buttons
    .btn & {
      position: relative;
      top: -1px;
    }
}

  // Add hover effects, but only for links
a.label {
   &:hover,
   &:focus {
     color: #ffffff;
     text-decoration: none;
     cursor: pointer;
   }
}
//** Default label background color
$label-default-bg:            $gray-lighter !default;
//** Primary label background color
$label-primary-bg:            $brand-primary !default;
//** Success label background color
$label-success-bg:            $brand-success !default;
//** Info label background color
$label-info-bg:               $brand-info !default;
//** Warning label background color
$label-warning-bg:            $brand-warning !default;
//** Danger label background color
$label-danger-bg:             $brand-danger !default;
// Colors
// Contextual variations (linked labels get darker on :hover)
.label-default {
    @include label-variant($label-default-bg);
}
.label-primary {
    @include label-variant($label-primary-bg);
    color: $label-primary-text-color;
}
.label-success {
    @include label-variant($label-success-bg);
    color: $label-success-text-color;
}
.label-info {
    @include label-variant($label-info-bg);
    color: $label-info-text-color;
}
.label-warning {
    @include label-variant($label-warning-bg);
    color: $label-warning-text-color;
}
.label-danger {
    @include label-variant($label-danger-bg);
    color: $label-danger-text-color;
}
.label-fixed {
    width: 50px;
    display: block;
    padding: 6px 0;
}