.link-tooltip {
    font-size: 12px;
    color: $dark-grey;
}
a.url-title-link {
    font-size: 13px;

    .icon {
        margin-left: 2px;
        margin-right: 2px;
    }
}
