.panel-monsido-card {
    border-width: 0;
    @include panel-variant(transparent, $gray-dark, #FFFFFF, $panel-default-border);
    @include set-border-radius(2px);

    -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

    .card-header {
        h1,
        h2,
        h3,
        h4,
        h5 {
            margin-top: 7px;
        }
    }

    .card-body {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.card {
    .card-header {
        h2 {
            font-size: 18px;
        }
    }
    .card-title {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 14px;
    }
}
